define('symbolsofpeace/routes/nominate/tag', ['exports', 'ember', 'symbolsofpeace/routes/nominate/base-nominate-route'], function (exports, _ember, _baseNominateRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseNominateRoute.default.extend({

		docTitle: 'Nominate Tag',

		afterModel: function afterModel(candidate) {
			this._super.apply(this, arguments);
			var nominateController = this.controllerFor('nominate');

			// set default color
			if (!candidate.get('color') || !candidate.get('color').length) {
				candidate.set('color', 'blue');
			}

			nominateController.set('activeStep', 2);
		},


		hideErrors: _ember.default.on('deactivate', function () {
			this._super.apply(this, arguments);
			var controller = this.controllerFor('nominate.tag');
			controller.setProperties({
				submitFailed: false,
				hashtagFailed: false,
				hashtagLengthFailed: false
			});
		})

	});
});