define('symbolsofpeace/components/plyr-element', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Component.extend({

		tagName: 'div',
		classNames: 'plyr',
		// Formats
		mp4: "",
		webm: "",
		captions: "",
		poster: "",
		mp3: "",
		ogg: "",
		video_id: "",
		options: null,
		plyrObject: null,
		evented: true,

		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super.apply(this, arguments);
			var a,
			    me = this;

			// this.$().attr('contenteditable', true);
			var options = this.get('options');
			// if(typeof console === 'object') { console.log('options',options); }


			if (options !== null) {
				a = plyr.setup(this, options);
			} else {
				a = plyr.setup(this);
			}

			if (!a.length) {
				return false;
			}

			var instance = a[0];

			// if(typeof console === 'object') { console.log('player instance',instance,instance.getEmbed()); }
			if (UTIL.user.isIPhone) {
				UTIL.colorize.insertRule('.plyr .content__video .plyr__video-embed iframe', 'pointer-events: auto;');
			}

			// plyr.setup();
			this.set('plyrObject', instance);

			// if(typeof console === 'object') { console.log('plyrObject',this.get('plyrObject')); }

			if (this.get('evented') && this.get('plyrObject')) {
				// const media = this.get('plyrObject').getMedia();
				// if(typeof console === 'object') { console.log('MEDIA?',media,$(media)); }

				instance.on('abort', function (e) {
					_this.sendAction('abort', e);
				}).on('loadstart', function (e) {
					_this.sendAction('loadstart', e);
				}).on('progess', function (e) {
					_this.sendAction('progress', e);
				}).on('suspend', function (e) {
					_this.sendAction('suspend', e);
				}).on('error', function (e) {
					_this.sendAction('error', e);
				}).on('emptied', function (e) {
					_this.sendAction('emptied', e);
				}).on('stalled', function (e) {
					_this.sendAction('stalled', e);
				}).on('loadedmetadata', function (e) {
					_this.sendAction('loadedmetadata', e);
				}).on('loadeddata', function (e) {
					_this.sendAction('loadeddata', e);
				}).on('canplay', function (e) {
					_this.sendAction('canplay', e);
				}).on('canplaythrough', function (e) {
					_this.sendAction('canplaythrough', e);
				}).on('playing', function (e) {
					_this.sendAction('playing', e);
				}).on('waiting', function (e) {
					_this.sendAction('waiting', e);
				}).on('seeking', function (e) {
					_this.sendAction('seeking', e);
				}).on('seeked', function (e) {
					_this.sendAction('seeked', e);
				}).on('ended', function (e) {
					_this.sendAction('ended', e);
				}).on('durationchange', function (e) {
					_this.sendAction('durationchange', e);
				}).on('timeupdate', function (e) {
					_this.sendAction('timeupdate', e);
				}).on('play', function (e) {

					var action = me.get('onPlay');

					if (action) {
						action(e);
						// if it is a promise
						// action(e).then((resultsObj) => {
						// 	me.set('state', false);
						// });
					}
					_this.sendAction('play', e);
				}).on('pause', function (e) {
					_this.sendAction('pause', e);
				}).on('ratechange', function (e) {
					_this.sendAction('ratechange', e);
				}).on('resize', function (e) {
					_this.sendAction('resize', e);
				}).on('volumechange', function (e) {
					_this.sendAction('volumechange', e);
				});
			}

			this.consolidateSizeToContainer();
		},
		willDestroyElement: function willDestroyElement() {
			var plyrObject = this.get('plyrObject');
			if (plyrObject) {
				var media = plyrObject.media;

				this.$(media).off('abort loadstart progress suspend error emptied stalled ' + 'loadedmetadata loadeddata canplay canplaythrough playing waiting ' + 'seeking seeked ended durationchange timeupdate play pause ' + 'ratechange resize volumechange');

				plyrObject.destroy();
			}
		},


		/**
   * set video size to fint in container...
   *
   */
		consolidateSizeToContainer: function consolidateSizeToContainer() {

			var videoData = this.get('videoData'),
			    me = this,
			    p,
			    container,
			    ch,
			    h;
			// var videoType = this.get('videoType');

			container = this.$().find('.content__video');
			var pbMargin = 50;
			if (container) {
				var ww = container.width();
				if (ww > 500) {
					pbMargin = 20;
				}
			}

			if (videoData) {

				// var cw = container.width();
				ch = container.height();
				// var w = videoData.width;
				h = videoData.height;
				// ch ==> 100, h ==> x%
				// 270 = 100,
				// 1280 = x
				p = 100 - ch * 100 / h;
				if (p) {
					var plyr = this.$().find('.plyr__video-embed > div');
					plyr.css('cssText', 'padding-bottom: ' + (p + 5) + '%; padding-bottom: calc(' + p + '% - ' + pbMargin + 'px);');
				}
			} else {

				var fn = function fn() {
					var frame = me.$().find('iframe');
					if (frame) {
						ch = container.height();
						h = $('iframe').get(0).height;
						if (h) {
							p = 100 - ch * 100 / h;
							if (p) {
								var plyr = me.$().find('.plyr__video-embed > div');
								plyr.css('cssText', 'padding-bottom: ' + (p + 5) + '%; padding-bottom: calc(' + p + '% - ' + pbMargin + 'px);');
							}
						}
					}
				};

				UTIL.waitForElement('iframe', function () {
					fn();
				}, 0);
			}
		},

		actions: {

			test: function test() {
				this.consolidateSizeToContainer();
			}

		}

	});
});