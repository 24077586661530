define('symbolsofpeace/components/media-listing-admin-actions', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		classNames: ['media-ranking'],
		tagName: 'div',
		isProcessing: false,
		enabled: true,

		actions: {

			save: function save() {
				var me = this;

				this.setProperties({
					isProcessing: true
				});

				var obj = this.get('obj');

				return obj.save().then(function (o) {

					me.setProperties({
						isProcessing: false
					});

					return o;
				}, function () {
					me.setProperties({
						isProcessing: false
					});
					return null;
				});
			}
		}

	});
});