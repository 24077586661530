define('symbolsofpeace/components/powerselect-icon-placeholder', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Component.extend({

		placeholderIcon: _ember.default.computed('placeholder', function () {
			var p = this.get('placeholder'),
			    i = 'icon-home';
			switch (p) {
				case 'user.origin':
					i = 'icon-home';
			}
			return i;
		})

	});
});