define('symbolsofpeace/services/lazy-video-providers/instagram', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var RSVP = _ember.default.RSVP;
	exports.default = {
		embedUrl: function embedUrl(videoId) {
			return 'http://instagram.com/p/' + videoId + '/embed';
		},
		thumbnailUrl: function thumbnailUrl(videoId) {
			return RSVP.resolve('http://instagram.com/p/' + videoId + '/media/?size=l');
		}
	};
});