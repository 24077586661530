define('symbolsofpeace/routes/catchall', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _ember.default.Route.extend({

		model: function model(params, transition) {
			if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
				console.log('CATCHALL', params, transition);
			}
			var wildcard = null,
			    me = this;
			if (params && params.wildcard) {
				wildcard = params.wildcard;
			}

			switch (wildcard) {
				case 'nominate/':
				case 'nominate':
					me.transitionTo('nominate', 'new');
					break;
				default:
					return this.get('store').findRecord('symbol', wildcard).then(function (symbol) {
						// if(typeof console === 'object') { console.log('symbol',symbol); }

						var _candidates = symbol.get('counters._candidates');
						if (_candidates && _candidates === 1) {
							var candidate = symbol.get('top_candidate');
							if (candidate) {
								me.transitionTo('symbol.candidate', symbol, candidate.id);
							} else {
								me.transitionTo('symbol', symbol);
							}
						} else {
							me.transitionTo('symbol', symbol);
						}

						return symbol;
					}, function () {
						// transition.abort();
						// me.transitionTo( 'symbols' );
						me.transitionTo('index');
						return null;
					});

			}
		}
	});
});