define('symbolsofpeace/controllers/index', ['exports', 'symbolsofpeace/controllers/base-controller', 'ember'], function (exports, _baseController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseController.default.extend({
		queryParams: ['cg'],
		cg: null,
		category: null,

		lastResult: null,
		lastParam: { c: 'interesting' },
		limit: 50,
		offset: 0,
		page: 1,
		stopperVisible: false,

		selectedCategory: _ember.default.computed('cg', function () {
			var categoryTitle = this.get('cg');
			var category = this.get('category');

			var appController = this.get('appController');
			if (categoryTitle && categoryTitle.length > 0) {
				category = appController.getCategoryByTitle(categoryTitle);
				category = appController.getModeldata(category);
			}

			// if(typeof console === 'object') { console.log('selectedCategory.category',category); }

			return category;
		}),

		setCategory: function setCategory(sendParams) {
			if (!sendParams.cg) {
				// let category = this.get('category');
				var selectedCategory = this.get('selectedCategory');
				// if(typeof console === 'object') { console.log('setCategory',selectedCategory); }
				if (selectedCategory) {
					sendParams.cg = selectedCategory.id;
				}
			}

			return sendParams;
		},


		actions: {

			/**
    *
    * Load (filtered) symbols
    *
    * @param param
    * @param limit
    * @param page
    * @param keepExisting
    * @returns {*|Promise.<TResult>}
    */
			predefinedFilter: function predefinedFilter(param, limit, page, keepExisting) {

				var me = this;
				var lastResults = this.get('lastResults');

				var appController = this.get('appController');
				appController.send('hideSymbolQuickLook', false);

				if (lastResults && keepExisting !== true) {
					me.set('isLoading', true);
				}

				// if(typeof console === 'object') { console.log('predefinedFilter.param,this.lastParam',param,this.lastParam); }

				param = param || this.lastParam;
				limit = limit || this.limit;
				page = page || this.page;

				var sendParams = Object.assign({}, param, {
					limit: limit,
					page: page
				});

				sendParams = this.setCategory(sendParams);

				// if(typeof console === 'object') { console.log('predefinedFilter.param',param,sendParams,keepExisting); }

				return this.get('store').query('symbol', sendParams).then(function (results) {
					me.set('isLoading', false);
					var meta = results.get('meta');

					if (lastResults && keepExisting === true) {
						var r = _ember.default.A();
						r.pushObjects(lastResults.toArray());
						r.pushObjects(results.toArray());
						results = r;
					} else {
						me.set('stopperVisible', false);
					}

					me.set('lastResults', results);
					me.set('lastParam', param);

					return {
						query: param,
						meta: meta,
						results: results
					};
				}, function () {
					me.set('isLoading', false);

					return {
						query: param,
						meta: lastResults ? lastResults.get('meta') : {
							last: true
						},
						results: lastResults
					};
				});
			}
		}

	});
});