define('symbolsofpeace/controllers/symbol', ['exports', 'symbolsofpeace/controllers/base-controller', 'ember'], function (exports, _baseController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseController.default.extend({
		symbolIndexController: _ember.default.inject.controller('symbol.index'),

		isCandidate: false,
		isEditor: null,
		candidate: null,
		resetChild: 0,

		/**
   * Reload result if parent model (symbol!) changed
   * @returns {boolean}
   */
		parentModelChanged: _ember.default.observer('model.tag', function () {
			var symbolIndexController = this.get('symbolIndexController');
			var i = this.get('resetChild') + 1;
			this.set('resetChild', i);
			symbolIndexController.set('resetChild', i);
			return true;
		}),

		storeCandidate: function storeCandidate(c) {
			this.set('candidate', c);
		},

		getCandidate: function getCandidate() {
			return this.get('candidate');
		},

		gotoRoute: function gotoRoute(r) {
			var candidate = this.getCandidate();

			switch (r) {
				case 'parentRemoveSymbol':
					var tag = this.get('model.tag');
					this.get('store').unloadAll('symbol');
					this.get('store').unloadAll('candidate');
					this.transitionToRoute('/symbol/' + tag);
					break;
				case 'parentSymbol':
					var model = this.get('model');
					this.transitionToRoute('symbol', model);
					break;
				case 'parentRemoveSymbolCandidate':
					var main_tag = candidate.get('main_tag');
					var id = candidate.get('id');
					this.get('store').unloadAll('symbol');
					this.get('store').unloadAll('candidate');
					this.transitionToRoute('/symbol/' + main_tag + '/candidate/' + id);
					break;
				case 'symbols':
					this.transitionToRoute('symbols');
					break;
				default:
					this.transitionToRoute('symbol.candidate', candidate);
			}
		}
	});
});