define('symbolsofpeace/adapters/symbol', ['exports', 'symbolsofpeace/mixins/delete-with-payload', 'ember-data'], function (exports, _deleteWithPayload, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_deleteWithPayload.default, {

    namespace: 'api/v1'

  });
});