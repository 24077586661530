define('symbolsofpeace/routes/symbol/upload', ['exports', 'symbolsofpeace/routes/symbol/base-symbol-candidate-route'], function (exports, _baseSymbolCandidateRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseSymbolCandidateRoute.default.extend({

		docTitle: 'Candidate Poster Image',

		afterModel: function afterModel() {
			this._super.apply(this, arguments);
			var symbolController = this.controllerFor('symbol');
			symbolController.set('isEditor', 'upload');
		}
	});
});