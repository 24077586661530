define('symbolsofpeace/controllers/contact', ['exports', 'symbolsofpeace/controllers/base-controller'], function (exports, _baseController) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseController.default.extend({

		name: null,
		email: null,
		message: null,

		isProcessing: false,
		isDisbabled: false,
		submitFailed: false,
		submitSuccess: false,

		checkFields: function checkFields() {

			// var name = this.get('name');
			var email = this.get('email');
			var message = this.get('message');

			var isValid = true;

			if (!message || !message.length) {
				isValid = false;
			}

			if (!email || !email.length) {
				isValid = false;
			}

			return isValid;
		},


		actions: {
			save: function save() {

				var me = this;

				this.setProperties({
					submitFailed: false,
					isDisbabled: false,
					submitSuccess: false,
					isProcessing: false
				});

				if (!this.checkFields()) {
					this.set('submitFailed', true);
					return false;
				}

				this.setProperties({
					isDisbabled: true,
					isProcessing: true
				});

				var data = {
					name: this.get('name'),
					email: this.get('email'),
					message: this.get('message')
				};

				jQuery.ajax({
					type: 'POST',
					method: 'post',
					url: '/api/v1/feedbacks',
					data: data,
					dataType: "json"
				}).done(function () {
					me.setProperties({
						submitFailed: false,
						isDisbabled: true,
						submitSuccess: true,
						isProcessing: false
					});
				}).fail(function (e) {
					if (console && console.log) {
						console.log("contact error", e);
					}

					me.setProperties({
						submitFailed: true,
						isDisbabled: false,
						submitSuccess: false,
						isProcessing: false
					});
				});
			}
		}

	});
});