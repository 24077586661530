define('symbolsofpeace/components/_dep_category-list', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({
		init: function init() {
			this._super.apply(this, arguments);
			var appController = this.get('appController');
			var categories = appController.getCategories();
			this.set('categories', categories);
		}
	});
});