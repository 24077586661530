define('symbolsofpeace/components/votes-list', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		maxVotes: 6,
		votes: ['vote1', 'vote2', 'vote3', 'vote4', 'vote5', 'vote6', 'vote7']

		// init() {
		// 	this._super(...arguments);
		// }

	});
});