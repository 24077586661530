define('symbolsofpeace/components/candidate-actions', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		classNames: ['candidate-actions', 'flex-view', 'flex-row'],
		tagName: 'div',

		modalAction: 0,
		isFacebookProzessing: false,
		isTwitterProzessing: false,
		isGoogleProzessing: false,
		isEMailProzessing: false,
		isLikeProzessing: false,

		isFacebookDisabled: false,
		isTwitterDisabled: false,
		isGoogleDisabled: false,
		isEMailDisabled: false,
		isLikeDisabled: false,

		staticSharingUrl: true,
		sharingUrl: null,
		sharingUrlShort: null,

		emailSubject: null,

		processingActions: ['isFacebookProzessing', 'isTwitterProzessing', 'isGoogleProzessing', 'isEMailProzessing', 'isLikeProzessing', 'isFacebookDisabled', 'isTwitterDisabled', 'isGoogleDisabled', 'isEMailDisabled'],

		disableAll: function disableAll() {
			for (var i = 0; i < this.processingActions; i++) {
				this.set(this.processingActions[i], false);
			}
		},

		checkCandidateLikes: _ember.default.observer('appController._candidateLikes', function () {
			var candidate = this.get('candidate');
			if (this.checkIfLikeisDisabled(candidate.get('id'))) {
				this.set('isLikeDisabled', true);
			}
		}),

		checkIfLikeisDisabled: function checkIfLikeisDisabled(id) {
			var candidateLikes = UTIL.storage.getLocal('candidateLikes');
			if (!candidateLikes) {
				candidateLikes = '[]';
			}
			candidateLikes = JSON.parse(candidateLikes);
			return candidateLikes.indexOf(id) >= 0;
		},

		saveLikes: function saveLikes(id) {
			var appController = this.get('appController');
			var candidateLikes = UTIL.storage.getLocal('candidateLikes');
			if (!candidateLikes) {
				candidateLikes = '[]';
			}
			candidateLikes = JSON.parse(candidateLikes);
			candidateLikes.push(id);
			UTIL.storage.storeLocal('candidateLikes', JSON.stringify(candidateLikes));
			appController.set('_candidateLikes', candidateLikes);
		},

		init: function init() {
			this._super.apply(this, arguments);

			var candidate = this.get('candidate');

			if (this.checkIfLikeisDisabled(candidate.get('id'))) {
				this.set('isLikeDisabled', true);
			}

			if (this.get('trackView') === 'first' && this.get('idx') === 0) {
				this.viewMe();
			} else if (this.get('trackView') === true) {
				this.viewMe();
			}

			this.getSharingUrl('view', null, false);
		},
		didInsertElement: function didInsertElement() {
			var a = location.hash;
			var c = $('#_comments');
			if (a === '#comments' && c && c.length) {
				this.scrollToComment();
			}
		},


		viewMe: function viewMe() {
			var me = this;
			setTimeout(function () {
				me.count('view');
			}, 500);
		},

		/**
   *
   * @param type
   * @param callback
   * @returns {string}
   *
   * /api/v1/candidates/59b6472b23447761931fd0e4/track/twitter
   */
		count: function count(type, callback) {
			var candidate = this.get('candidate');
			var appController = this.get('appController');
			appController.send('trackCandidate', candidate, type, callback);
		},

		/**
   *
   * @param type
   * @param callback
   * @returns {string}
   *
   * http://lp.dev.7sop.yellowspace.net/api/v1/candidates/59b6472b23447761931fd0e4/share/twitter
   */
		getSharingUrl: function getSharingUrl(type, callback, shorten) {
			shorten = false;
			var sharingUrl;
			var candidate = this.get('candidate');

			// STATIC FOR NOW
			if (this.get('staticSharingUrl')) {

				if (!candidate) {
					return false;
				} else {
					sharingUrl = window.location.protocol + '//' + window.location.host + '/s/' + candidate.get('id');
					this.set('sharingUrl', sharingUrl);
					this.set('sharingUrlShort', sharingUrl);
					return sharingUrl;
				}
			}

			// IMPLEMENT THIS, IF WE NEED A DYNAMIC URL


			if (this.get('sharingUrl')) {
				sharingUrl = this.get('sharingUrl');
				if (shorten) {
					sharingUrl = this.get('sharingUrlShort');
				}
				if (callback) {
					return this.send(callback, sharingUrl);
				} else {
					return sharingUrl;
				}
			}

			var me = this;
			var url = '/api/v1/candidates/' + candidate.get('id') + '/share';

			$.ajax({
				method: 'GET',
				url: url,
				dataType: 'json',
				success: function success(data) {
					if (data && data.candidate && data.candidate.sharing && data.candidate.sharing.url) {
						me.set('sharingUrl', data.candidate.sharing.url);
						me.set('sharingUrlShort', data.candidate.sharing.short_url);
						var sharingUrl = data.candidate.sharing.url;
						if (shorten) {
							sharingUrl = data.short_url;
						}
						if (callback) {
							me.send(callback, sharingUrl);
						}
					}
				},
				error: function error(data) {
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('Error.getSharingUrl with ', data);
					}
				}
			});
		},

		/**
   * NOT ACTIVE YET
   * Interactive Button Action... Would be nice for voting,
   * but needs credentials from the user
   */
		googlePlus_interactivepost: function googlePlus_interactivepost() {
			var sharingUrl = this.get('sharingUrl');

			var candidate = this.get('candidate');

			var renderTo = 'gp_' + candidate.get('id');

			var options = {
				contenturl: sharingUrl,
				// contentdeeplinkid: '/pages',
				// eslint-disable-next-line no-undef
				clientid: GOOGLE_APP_ID,
				cookiepolicy: window.location.protocol + '//' + window.location.host,
				prefilltext: this.get('appDescription'),
				calltoactionlabel: 'VOTE',
				calltoactionurl: sharingUrl
				// calltoactiondeeplinkid: '/pages/create',
				// callback: function(result) {
				// 	// $('#'+renderTo).trigger("click");
				// }
			};
			// Call the render method when appropriate within your app to display
			// the button.
			// eslint-disable-next-line no-undef
			gapi.interactivepost.render(renderTo, options);
		},

		scrollToComment: function scrollToComment() {
			var c = $('#_comments');
			if (c && c.length) {
				// eslint-disable-next-line no-unused-vars
				var o = c.offset();

				$('html, body').animate({
					scrollTop: $("#_comments").offset().top - ($('.symbol-headline').outerHeight() + $('.fixedheader').outerHeight())
				}, 500);
			}
		},


		actions: {
			/**
    * trigger toggle modal
    * @param a
    */
			// openAction(a) {
			//
			// 	var $toastContent = $('<span>Coming soon...</span>')
			// 		.add($('<button class="btn-flat toast-action" onclick="UTIL.MDC.closeToasts();">Cancel</button>'));
			//
			// 	Materialize.toast($toastContent, 5000);
			//
			// 	return false;
			// 	var i = this.get(a);
			// 	this.set(a,(i+1));
			// },

			like: function like() {
				var candidate = this.get('candidate');
				var me = this;

				var sharingUrl = window.location.protocol + '//' + window.location.host + '/symbol/' + candidate.get('main_tag') + '/candidate/' + candidate.get('id');
				this.disableAll();
				this.set('isLikeProzessing', true);
				this.set('isLikeDisabled', true);

				this.count('like', function (d) {
					if (d && d.candidate) {
						me.set('isLikeProzessing', false);
						me.set('isLikeDisabled', true);
						candidate.set('counters', d.candidate.counters);
						me.saveLikes(candidate.get('id'));
						UTIL.tracker.track('social', { network: '7SoP', action: 'like', target: sharingUrl });
					} else {
						me.set('isLikeProzessing', false);
						me.set('isLikeDisabled', true);
						if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
							console.log('like.error', d);
						}
					}
				});
			},

			shareFacebook: function shareFacebook(sharingUrl) {

				sharingUrl = this.get('sharingUrl');

				var me = this;
				var candidate = this.get('candidate');

				this.disableAll();
				this.set('isFacebookProzessing', true);
				this.set('isFacebookDisabled', true);

				if (!sharingUrl) {
					return this.getSharingUrl('facebook', 'shareFacebook', false);
				}

				// if(typeof console === 'object') { console.log('sharingUrl',sharingUrl); }
				FB.ui({
					// eslint-disable-next-line no-undef
					app_id: FACEBOOK_APP_ID,
					name: this.get('appName'),
					caption: this.get('appCaption'),
					description: this.get('appDescription'),

					hashtag: this.get('hashtag'),
					mobile_iframe: true,
					href: sharingUrl,
					method: 'share'
				}, function (response) {
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('Facebook Response', response);
					}
					// Donno, we do not get any response... :(
					if (response) {
						// me.get('errorController').raise({
						// 	statusText: me.get('fb'),
						// 	statusCode: 200,
						// 	reason: null,
						// 	message: me.get('fbPublished')
						// });
						//
						// //toast?
						// var appController = me.get('appController');
						// appController.toastMsg(me.get('fbPublished'),true);
						me.count('facebook', function (d) {
							candidate.set('counters', d.candidate.counters);
						});
						UTIL.tracker.track('social', { network: 'Facebook', action: 'share', target: sharingUrl });
						me.set('isFacebookProzessing', false);
						me.set('isFacebookDisabled', false);
					} else {
						console.log('Post was not published.', response);
						me.set('isFacebookProzessing', false);
						me.set('isFacebookDisabled', false);
					}
				});
				//
				// FB.ui({
				// 	// eslint-disable-next-line no-undef
				// 	app_id: FACEBOOK_APP_ID,
				// 	name: this.get('appName'),
				// 	caption: this.get('appCaption'),
				// 	description: this.get('appDescription'),
				// 	link: sharingUrl,
				// 	method:  'feed'
				// }, function(response){
				// 	if(typeof console === 'object') { console.log('Facebook Response',response); }
				// 	// Donno, we do not get any response... :(
				// 	if (1===1 || (response && response.post_id)) {
				// 		// me.get('errorController').raise({
				// 		// 	statusText: me.get('fb'),
				// 		// 	statusCode: 200,
				// 		// 	reason: null,
				// 		// 	message: me.get('fbPublished')
				// 		// });
				// 		//
				// 		// //toast?
				// 		// var appController = me.get('appController');
				// 		// appController.toastMsg(me.get('fbPublished'),true);
				// 		me.count('facebook', function (d) {
				// 			candidate.set('counters', d.candidate.counters);
				// 		});
				// 		UTIL.tracker.track('social',{network:'Facebook',action:'share',target:sharingUrl});
				// 		me.set('isFacebookProzessing',false);
				// 		me.set('isFacebookDisabled',false);
				// 	}
				// 	else {
				// 		console.log('Post was not published.',response);
				// 		me.set('isFacebookProzessing',false);
				// 		me.set('isFacebookDisabled',false);
				// 	}
				// });
			},

			shareTwitter: function shareTwitter(sharingUrl) {

				sharingUrl = this.get('sharingUrl');

				var me = this;
				var candidate = this.get('candidate');

				this.disableAll();
				this.set('isTwitterProzessing', true);
				this.set('isTwitterDisabled', true);

				if (!sharingUrl) {
					return this.getSharingUrl('twitter', 'shareTwitter', false);
				}

				var url = encodeURIComponent(sharingUrl);
				var title = encodeURIComponent(this.get('appDescription'));
				var hashtags = this.get('hashtag') + ',' + candidate.get('main_tag');

				//https://twitter.com/intent/tweet?hashtags=demo&original_referer=https%3A%2F%2Fdev.twitter.com%2Fweb%2Ftweet-button&ref_src=twsrc%5Etfw&related=twitterapi%2Ctwitter&text=Hello%20world&tw_p=tweetbutton&url=https%3A%2F%2Fexample.com%2Ffoo&via=twitterdev
				window.open('https://twitter.com/intent/tweet?text=' + title + '&url=' + url + '&hashtags=' + hashtags, 'Twitter', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');

				this.set('isTwitterProzessing', false);
				this.set('isTwitterDisabled', false);
				me.count('twitter', function (d) {
					candidate.set('counters', d.candidate.counters);
				});
				UTIL.tracker.track('social', { network: 'Twitter', action: 'tweet', target: url });
			},

			shareMail: function shareMail(sharingUrl) {

				var candidate = this.get('candidate');
				sharingUrl = this.get('sharingUrl');

				this.disableAll();

				if (!sharingUrl) {
					this.set('isEMailProzessing', true);
					this.set('isEMailDisabled', true);
					return this.getSharingUrl('email', 'shareMail', false);
				}

				var subject = this.get('emailSubject') || this.get('appCaption') + ' #' + candidate.get('main_tag');
				window.location = 'mailto:?subject=' + subject + '&body=' + sharingUrl;

				this.count('email', function (d) {
					candidate.set('counters', d.candidate.counters);
				});
				this.set('isEMailProzessing', false);
				this.set('isEMailDisabled', false);
			},

			shareGooglePlus: function shareGooglePlus() {

				var me = this,
				    sharingUrl = this.get('sharingUrl'),
				    candidate = this.get('candidate');

				this.disableAll();
				this.set('isGoogleProzessing', true);
				this.set('isGoogleDisabled', true);

				if (!sharingUrl) {
					return this.getSharingUrl('twitter', 'shareTwitter', false);
				}

				var url = encodeURIComponent(sharingUrl),
				    title = encodeURIComponent(this.get('appDescription')),
				    hashtags = this.get('hashtag') + ',' + candidate.get('main_tag');

				//plus.google.com/share?url={URL}
				window.open('//plus.google.com/share?text=' + title + '&url=' + url + '&hashtags=' + hashtags, 'GooglePlus', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
				// window.open('//plus.google.com/share?&url=' + url, 'GooglePlus', 'height=450, width=550, top='+($(window).height()/2 - 225) +', left='+$(window).width()/2 +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');

				if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
					console.log('GOOGLE PLUS OPEN WINDOW', sharingUrl);
				}
				this.set('isGoogleProzessing', false);
				this.set('isGoogleDisabled', false);
				me.count('googleplus', function (d) {
					candidate.set('counters', d.candidate.counters);
				});
				UTIL.tracker.track('social', { network: 'Twitter', action: 'tweet', target: url });
			},

			shareGomment: function shareGomment() {
				var _this = this;

				var c = $('#_comments');
				if (c && c.length) {
					this.scrollToComment();
				} else {
					var candidate = this.get('candidate');
					var appController = this.get('appController');
					appController.transitionToRoute('symbol.candidate', candidate.get('main_tag'), candidate).then(function () {
						setTimeout(function () {
							_this.scrollToComment();
						}, 500);
					});
				}
			},


			shareLink: function shareLink(sharingUrl) {

				if (!sharingUrl) {
					return this.getSharingUrl('link', 'shareLink', false);
				}

				sharingUrl = this.get('sharingUrl');

				var modal = this.get('controllers.share-link-modal');
				modal.show(sharingUrl);
			}
		}
	});
});