define('symbolsofpeace/router', ['exports', 'ember', 'symbolsofpeace/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('index', { path: '/' });
    this.route('about');
    this.route('privacy-policy');
    this.route('voting-phases');
    this.route('contact');
    this.route('newsletter-subscription');
    this.route('newsletter-unsubscription');
    this.route('user-profile');
    this.route('user-signin');
    this.route('user-signup');
    this.route('symbols');
    this.route('my-symbols', { path: '/my-symbols' }, function () {});
    this.route('my-votes');
    this.route('votes-of', { path: '/votes-of/:basket_id' });
    this.route('candidates', { path: '/candidates' });

    this.route('symbol', { path: '/symbol/:tag' }, function () {
      this.route('candidate', { path: '/candidate/:candidate_id' });
      this.route('crop', { path: '/crop/:candidate_id' });
      this.route('notes', { path: '/notes/:candidate_id' });
      this.route('upload', { path: '/upload/:candidate_id' });
      this.route('tag', { path: '/tag/:candidate_id' });
      this.route('choose-thumbnail', { path: '/choose-thumbnail/:candidate_id' });
      this.route('edit-tag');
    });
    this.route('symbol-quick-look', { path: '/symbol-quick-look/:tag' });
    // this.route('candidate', { path: '/candidate/:candidate_id' });
    this.route('nominate', { path: '/nominate/:tag' }, function () {
      this.route('crop', { path: '/crop/:candidate_id' });
      this.route('tag', { path: '/tag/:candidate_id' });
      this.route('notes', { path: '/notes/:candidate_id' });
      this.route('signup', { path: '/signup/:candidate_id' });
      this.route('signin', { path: '/signin/:candidate_id' });
    });

    this.route('catchall', { path: '/*wildcard' });
  });

  exports.default = Router;
});