define('symbolsofpeace/controllers/my-symbols/index', ['exports', 'symbolsofpeace/controllers/symbol/index', 'ember'], function (exports, _index, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _index.default.extend({
		parentController: _ember.default.inject.controller('my-symbols'),
		showStopper: false
	});
});