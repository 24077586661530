define('symbolsofpeace/components/input-search-validate', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		searchKey: 'q',
		isProzessing: false,
		isValid: true,
		query: '',

		keydownTimeout: 0,

		validate: function validate(t) {
			this.set('isValid', t);
			this.sendAction('returnValid', t);
			this.sendAction('setValue', this.get('value'));
		},

		search: function search() {

			var me = this;

			if (this.keydownTimeout) {
				clearTimeout(this.keydownTimeout);
			}

			this.keydownTimeout = setTimeout(function () {
				me.r_search();
			}, 250);
		},

		r_search: function r_search() {

			var input = this.$().find('input');
			var search = input.val();

			var me = this;
			this.set('query', search);
			this.set('isProzessing', true);

			if (!search || search.length === 0) {
				me.set('isProzessing', false);
				return true;
			}

			$.get(this.get('searchUrl'), {
				q: search
			}).then(function (r) {
				if (r && r.success) {
					me.validate(true);
					me.set('isProzessing', false);
				} else {
					me.validate(false);
					me.set('isProzessing', false);
				}
			}, function () {
				me.set('isProzessing', false);
				me.validate(false);
			});
		},

		actions: {
			search: function search() {
				this.search();
			}
		}

	});
});