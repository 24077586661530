define('symbolsofpeace/components/nocaptcha-recaptcha', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Component.extend({
		classNames: ['g-recaptcha'],
		attributeBindings: ['data-sitekey'],
		'data-sitekey': '6LdUwkYUAAAAAFBTx7bPYWPmNwSiOZxV34KTWDsM',

		init: function init() {
			this._super.apply(this, arguments);
			_ember.default.$.getScript('https://www.google.com/recaptcha/api.js');
		}
	});
});