define('symbolsofpeace/components/mdc-select', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		classNames: ['browser-default-select-field'],
		selectClassName: 'browser-default',

		options: [],
		prompt: null,

		didInsertElement: function didInsertElement() {
			var $element = this.$();
			$element.find('select').material_select();
		},

		actions: {

			change: function change() {
				var selectedIndex = this.$('select')[0].selectedIndex;
				var content = this.get('options');
				var controller = this.get('myController');

				// decrement index by 1 if we have a prompt
				var hasPrompt = !!this.get('prompt');
				var contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;
				var _selection = content[contentIndex];

				// if(typeof console === 'object') { console.log('_selection',_selection); }

				controller.updateField(this.get('fieldName'), _selection.value);
				// this.set('selection', _selection.value);
			}
		}

	});
});