define('symbolsofpeace/routes/symbol/edit-tag', ['exports', 'ember', 'symbolsofpeace/routes/base-content-route'], function (exports, _ember, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({
		docTitle: 'Edit Tag of Symbol',

		afterModel: function afterModel() {
			this._super.apply(this, arguments);
			var symbolController = this.controllerFor('symbol');
			symbolController.set('isEditor', 'symbol-tag');
		},

		renderTemplate: function renderTemplate(controller, model) {
			this.render('nominate/tag', {
				model: model,
				controller: controller
			});
		},


		hideMessages: _ember.default.on('deactivate', function () {
			this._super.apply(this, arguments);

			var symbolController = this.controllerFor('symbol');
			symbolController.set('isEditor', null);

			var controller = this.controllerFor('symbol.edit-tag');
			controller.setProperties({
				isProcessing: false,
				submitFailed: false,
				hashtagFailed: false,
				hashtagLengthFailed: false
			});
		})
	});
});