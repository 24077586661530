define('symbolsofpeace/components/voting-button', ['exports', 'symbolsofpeace/components/base-component', 'symbolsofpeace/mixins/voting-button', 'ember'], function (exports, _baseComponent, _votingButton, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend(_votingButton.default, {

		classNames: ['voting__button'],
		tagName: 'div',
		hasCategory: true,
		showModal: false,
		modalAction: 0,

		isSymbolListing: function isSymbolListing() {
			var view = this.get('parent-view');

			if (view === 'symbol-listing') {
				return true;
			}

			return false;
		},
		isSingleCandidate: function isSingleCandidate() {
			var candidates = this.get('symbol.counters._candidates');
			var r = false;

			if (candidates === 1) {
				r = true;
			}

			return r;
		},


		gotoNominationToVote: _ember.default.computed('appController.votingbasketJson', 'symbol', function () {
			var r = false;
			if (this.isSymbolListing() && !this.isSingleCandidate()) {
				r = true;
			}
			return r;
		}),

		votedForCandidate: _ember.default.computed('appController.votingbasketJson', 'symbol', 'candidate', function () {
			var r = this.checkIfVotedForCandidate();
			return r;
		}),

		buttonText: _ember.default.computed('appController.votingbasketJson', 'symbol', 'candidate', function () {
			var r = this.checkIfVotedForCandidate();
			var t = 'Vote';

			if (this.isSymbolListing() && !this.isSingleCandidate()) {
				return t;
			}

			if (r) {
				var parentView = this.get('parent-view'); //parent-view="votingbasket-listing"
				if (parentView === 'votingbasket-listing') {
					t = 'Remove';
				} else {
					t = 'Remove Vote';
				}
			}

			return t;
		}),

		hightlightButton: _ember.default.computed('appController.votingbasketJson', 'symbol', 'candidate', function () {
			var hasVote = this.checkIfVotedForCandidate();
			// let votedForCandidate = this.get('votedForCandidate');

			if (this.isSymbolListing()) {

				if (hasVote && !this.isSingleCandidate()) {
					hasVote = false;
				}
			}

			if (hasVote) {
				return true;
			}

			return false;
		}),

		init: function init() {
			this._super.apply(this, arguments);
			var category = this.get('symbol.category');
			if (!category) {
				this.set('hasCategory', false);
			}
		},
		checkIfVotedForCandidate: function checkIfVotedForCandidate() {
			// var me = this;
			var appController = this.get('appController');
			// let category = this.get('symbol.category');
			// let symbol = this.get('symbol');
			var candidate = this.get('candidate');
			// let check = false;

			var hasCandidateVote = appController.findCandidateVoting(candidate);
			if (hasCandidateVote) {
				return true;
			}

			return false;
		},
		checkIfVotedForSymbol: function checkIfVotedForSymbol() {
			// var me = this;
			var appController = this.get('appController');
			var category = this.get('symbol.category');
			var symbol = this.get('symbol');
			var check = false;

			if (!category) {
				return false;
			}

			var votingbasket = appController.getVotingbasket();

			if (!votingbasket) {
				return false;
			}

			var key = 'vote' + category.key;
			var hasVote = votingbasket.get(key);

			// if(typeof console === 'object') { console.log('hasVote',hasVote,symbol.get('id')); }

			if (hasVote && hasVote.symbol && hasVote.symbol.tag && hasVote.symbol.tag === symbol.get('id')) {
				check = true;
			}

			return check;
		},
		userIsLoggedIn: function userIsLoggedIn() {
			var appController = this.get('appController');
			return appController.get('userIsLoggedIn');
		},
		___checkAlreadyVotedForSymbol: function ___checkAlreadyVotedForSymbol() {
			var _this = this;

			// var me = this;
			var appController = this.get('appController');
			var category = this.get('symbol.category');
			var symbol = this.get('symbol');

			if (!category) {
				return false;
			}

			this.set('showModal', true);
			var modalAction = this.get('modalAction');
			modalAction++;
			this.set('modalAction', modalAction);
			var title = 'Reset your vote for ' + symbol.get('tag');

			var msg = 'You already voted for <i>' + symbol.get('tag') + '</i>. Do you want to reset your voting?';

			var fn = function fn() {
				_this.voteFor(true);
			};

			appController.showConfirmDialog(title, msg, fn);
		},
		_dep_checkAlreadyVotedForCategory: function _dep_checkAlreadyVotedForCategory() {
			var _this2 = this;

			// var me = this;
			var appController = this.get('appController');
			var category = this.get('symbol.category');
			// let symbol = this.get('symbol');
			// let candidate = this.get('candidate');

			// if(typeof console === 'object') { console.log('checkAlreadyVotedFor',category); }

			if (!this.userIsLoggedIn()) {
				appController.openVotingBasket();
				return false;
			}

			if (!category) {
				return false;
			}

			var votingbasket = appController.getVotingbasket();

			if (!votingbasket) {
				return false;
			}

			var key = 'vote' + category.key;
			var hasVote = votingbasket.get(key);
			// let voteCategory = votingbasket.get(key+'.category');
			// if(typeof console === 'object') { console.log('voteCategory',voteCategory,category); }

			if (hasVote /* && voteCategory.id === category.id*/) {
					this.set('showModal', true);
					var modalAction = this.get('modalAction');
					modalAction++;
					this.set('modalAction', modalAction);
					var title = 'Vote for ' + category.title; //t('votingbasket.title');

					// let msg = this.t("votingbasket.you_allready_voted_for") +' '+ this.get('symbol.category.title') + ' ' + this.t("votingbasket.override_your_voting");
					// let msg = 'You already voted for <i>' + category.title + '</i>. Do you want to override your voting?';
					var msg = 'You already voted for this candidate. Do you want to override your voting?';

					var fn = function fn() {
						_this2.voteFor();
					};

					appController.showConfirmDialog(title, msg, fn);

					return false;
				}

			// if(typeof console === 'object') { console.log('checkAlreadyVotedFor',true); }

			this.voteFor();
		},
		setVote: function setVote(data) {

			var me = this;

			if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
				console.log('setVote with data', data);
			}

			jQuery.ajax({
				type: 'POST',
				method: 'post',
				url: '/api/v1/votes',
				data: JSON.stringify(data),
				dataType: "json",
				contentType: "application/json"
			}).done(function () {
				me.setProperties({
					isProcessing: false,
					submitFailed: false
				});
				var appController = this.get('appController');
				// eslint-disable-next-line no-unused-vars
				var votingbasket = appController.getVotingbasket();
			}).fail(function (e) {
				if (console && console.log) {
					console.log("setVote error", e);
				}

				me.setProperties({
					isProcessing: false,
					submitFailed: true
				});
			});
		},


		// updateVote(data) {},


		actions: {
			vote: function vote() {
				this.voteFor();
			},
			checkAlreadyVotedFor: function checkAlreadyVotedFor(event) {
				// if(typeof console === 'object') { console.log('event',event); }
				event.preventDefault();
				event.stopPropagation();

				// let view = this.get('parent-view');
				var votedForCandidate = this.get('votedForCandidate');

				if (votedForCandidate && this.isSymbolListing() && this.isSingleCandidate()) {
					this.checkAlreadyVotedForCandidate();
				} else if (this.isSymbolListing() && !this.isSingleCandidate()) {
					var tag = this.get('symbol.tag');
					var appController = this.get('appController');
					appController.transitionToRoute('symbol', tag);
				} else {
					if (votedForCandidate) {
						this.checkAlreadyVotedForCandidate();
					} else {
						this.voteFor();
					}
				}
			}
		}

	});
});