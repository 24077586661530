define('symbolsofpeace/transforms/object', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Transform.extend({
		deserialize: function deserialize(value) {
			if (!_ember.default.$.isPlainObject(value)) {
				if (value === null) {
					return null;
				}
				return {};
			} else {
				return value;
			}
		},
		serialize: function serialize(value) {
			if (!_ember.default.$.isPlainObject(value)) {
				if (value === null) {
					return null;
				}
				return {};
			} else {
				return value;
			}
		},
		//deserialize: function(serialized) {
		//	//return serialized;
		//	return Ember.none(serialized) ? {} : serialized;
		//},
		//
		//serialize: function(deserialized) {
		//	//return deserialized;
		//	return Ember.none(deserialized) ? {} : deserialized;
		//},
		from: function from(serialized) {
			return _ember.default.none(serialized) ? {} : serialized;
		},
		to: function to(deserialized) {
			return _ember.default.none(deserialized) ? {} : deserialized;
		}
	});
});