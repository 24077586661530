define('symbolsofpeace/components/category-list-nav', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		colorizeHashtag: true,
		colors: ['purple', 'blue', 'lightblue', 'green', 'yellow', 'orange', 'red'],
		colorPrefix: 'pace_',
		selectedColor: null,
		selectedCategory: null,
		isOpen: false,
		transformTitle: false,

		init: function init() {
			this._super.apply(this, arguments);
			var appController = this.get('appController');
			var categories = appController.getCategories();
			this.set('categories', categories);

			// if(typeof console === 'object') { console.log('CategoryListNav',this.get('selectedCategory')); }

			if (this.get('selectedCategory')) {
				this.selectCategory(this.get('selectedCategory'));
			}
		},
		didUpdate: function didUpdate() {
			var s = this.get('selectedCategory');
			// let slug = null;
			//
			// if(s && typeof s.get === 'function') {
			// 	slug = s.get('slug');
			// } else if(s && typeof s === 'object') {
			// 	slug = s.slug;
			// }
			//
			// if(typeof console === 'object') { console.log('didUpdatedidUpdatedidUpdate',s,slug); }

			this.selectCategory(s);
		},
		didInsertElement: function didInsertElement() {
			var _this = this;

			this.doOpen();
			window.addEventListener("resize", function () {
				_this.doOpen();
			});
		},
		doOpen: function doOpen() {
			var w = $(window).width();
			var isOpen = this.get('isOpen');

			if (!isOpen && w > 770) {
				this.toggle(true);
			} else if (isOpen && w <= 770) {
				this.toggle(false);
			}
		},
		selectCategory: function selectCategory(category) {

			if (category && (typeof category === 'undefined' ? 'undefined' : _typeof(category)) === 'object') {
				if (typeof category.get === 'function') {
					this.set('selectedColor', category.get('color'));
					this.set('selectedCategory', category.toJSON());
				} else {
					this.set('selectedColor', category.color);
					this.set('selectedCategory', category);
				}
			} else {
				this.set('selectedColor', null);
				this.set('selectedCategory', null);
			}
		},
		toggle: function toggle(isOpen) {
			var _this2 = this;

			this.set('isOpen', isOpen);

			setTimeout(function () {
				_this2.set('transformTitle', isOpen);
			}, 100);
		},


		actions: {
			openColorList: function openColorList() {
				var isOpen = this.get('isOpen');
				// let transformTitle = this.get('transformTitle');
				this.toggle(!isOpen);
			},
			selectColor: function selectColor(category, event) {

				var foundCategory = null;

				if (category) {

					var selectedCategory = this.get('selectedCategory');
					var categoryJson = category.toJSON();
					categoryJson.id = category.get('id');

					if (selectedCategory && categoryJson && selectedCategory.id === categoryJson.id) {
						// this.selectCategory(null);
						foundCategory = null;
					} else {
						// this.selectCategory(categoryJson);
						foundCategory = categoryJson;
					}
				}

				var filterAction = this.get('filter');

				filterAction(foundCategory, event);
			}
		}

	});
});