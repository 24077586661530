define('symbolsofpeace/services/lazy-video-providers/youtube', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var RSVP = _ember.default.RSVP;
	exports.default = {
		apiUrl: function apiUrl(videoId) {
			return '//gdata.youtube.com/feeds/api/videos/' + videoId;
		},
		embedUrl: function embedUrl(videoId) {

			var host = window.location.protocol + '//' + window.location.hostname;
			// var yhtml = '&amp;html5=True';
			// var src = `?origin=${host}&amp;showinfo=0&amp;fs=1&amp;autohide=1&amp;version=3&amp;enablejsapi=1${yhtml}&amp;wmode=transparent`; //opaque
			var src = '?autoplay=o&origin=' + host + '&showinfo=0&rel=0&playsinline=1&html5=True&modestbranding=1';
			return '//www.youtube.com/embed/' + videoId + src;
		},
		thumbnailUrl: function thumbnailUrl(videoId) {
			return RSVP.resolve('//img.youtube.com/vi/' + videoId + '/maxresdefault.jpg');
		}
	};
});