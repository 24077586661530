define('symbolsofpeace/routes/index', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		// docTitle: 'Home',

		model: function model() {//params

			// if(typeof console === 'object') { console.log('IndexRoute params',params); }

			// tests
			// this.get('store').findRecord('symbol', "1sss");
			// this.get('store').query('symbol', {
			// 	filter: {
			// 		tag: 'Peter'
			// 	}
			// }).then(function(tags) {
			// 	// Do something with `peters`
			// });
		},

		// model_DEP: function(params, transition) { // --> moved to symbol-listing-filter
		//
		// 	var me = this,
		// 		// ApplicationController = me.controllerFor('application'),
		// 		controller = me.controllerFor('index');
		//
		// 	return this.get('store').query('symbol',{
		// 		limit: 10,
		// 		offset: 0
		// 	}).then(function(result) {
		//
		// 		let meta = result.get('meta');
		// 		// if(typeof console === 'object') { console.log('Index Route Query SYMBOL meta-data',meta); }
		//
		// 		controller.set('isLoading',false);
		// 		// controller.set('skipSplashRedirectDone',true);
		// 		// var mosaic = setMosaic(mosaics);
		// 		// if(!mosaic) {
		// 		// 	transition.abort();
		// 		// 	me.transitionTo('start');
		// 		// }
		// 		return result;
		//
		// 	}, function ( reason ) {
		//
		// 		transition.abort();
		// 		controller.set('isLoading',false);
		//
		// 		me.controllerFor('error-modal').raise({
		// 			statusText: 'Sorry',
		// 			statusCode: 404,
		// 			reason: reason,
		// 			message: 'No symbols found'
		// 		});
		//
		// 		return null;
		// 	});
		//
		// },

		actions: {

			/**
    * test error
    */
			didTransition: function didTransition() {
				this._super.apply(this, arguments);

				// this.controllerFor('error-modal').raise({
				// 	statusText: 'Sorry',
				// 	statusCode: 404,
				// 	reason: 'Fehler',
				// 	message: 'Tja, ein dicker Fehler halt'
				// });

				return true; // Bubble the didTransition event
			}
		}

	});
});