define('symbolsofpeace/transforms/integer', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Transform.extend({
		serialize: function serialize(value) {
			return parseInt(value);
		},
		deserialize: function deserialize(value) {
			return parseInt(value);
		}
	});
});