define('symbolsofpeace/components/swiper-slide', ['exports', 'ember', 'symbolsofpeace/templates/components/swiper-slide'], function (exports, _ember, _swiperSlide) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = _ember.default.Component,
	    computed = _ember.default.computed;
	exports.default = Component.extend({
		layout: _swiperSlide.default,
		classNameBindings: ['mergedClasses'],

		mergedClasses: computed('class', function () {
			return this.get('class') ? this.get('class') + ' swiper-slide' : 'swiper-slide';
		})

	});
});