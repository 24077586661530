define('symbolsofpeace/controllers/application', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _ember.default.Controller.extend({

		// needs:['error-modal'], // add error handling

		/**
   * "global" application settings
   */
		_quickLookLoading: false,
		_maskMediaTile: false,
		_createdNewCandidate: false,
		_candidateLikes: [],
		currentlyLoading: false,

		/**
   * initialize global UTIL (helper) function
   * globals: this.get('globals').get('name')
   */
		init: function init() {
			UTIL.init();
		},

		test: function test() {
			if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
				console.log('ApplicationController.test', true);
			}
		},

		hideStartSplash: function hideStartSplash() {

			var startSplash = $('.start-loading');
			startSplash.remove();

			// if(startSplash && startSplash.get(0)) {
			// 	UTIL.transit(startSplash,{x: ($(window).width() * -1),y:0},{
			// 		duration: 500,
			// 		easing: 'cubic-bezier(.41,.05,.81,.63)',
			// 		callback: function() {
			// 			startSplash.remove();
			// 		}
			// 	});
			// }
		},

		languages: [],

		/**
   * Registered user model
   */
		userIsLoggedIn: false,
		user: null,
		setCurrentUser: function setCurrentUser(user) {
			if (user) {
				var userId = user.get('id');
				if (userId) {
					this.set('userIsLoggedIn', true);
				}
			} else {
				this.set('userIsLoggedIn', false);
			}
			this.testIsAdmin(user);
			this.set('user', user);
		},

		getCurrentUser: function getCurrentUser() {
			return this.get('user');
		},

		testUserLoggedIn: _ember.default.computed('userIsLoggedIn', function () {
			return this.get('userIsLoggedIn');
		}),

		setUserInteraction: function setUserInteraction(i, t) {
			var u = this.get('user');
			var int = u.get('interactions');
			var a = int[i];

			a.push(t);
			int[i] = a;
			u.set('interactions', int);

			return a;
		},
		hasUserInteraction: function hasUserInteraction(i, t) {
			var r = false;
			var u = this.get('user');

			if (u) {
				var int = u.get('interactions');
				var d = int[i];
				if ((typeof d === 'undefined' ? 'undefined' : _typeof(d)) === 'object' && d.indexOf(t) >= 0) {
					return true;
				}
			}

			return r;
		},


		userIsAdmin: false,
		testIsAdmin: function testIsAdmin(user) {
			if (user && user.get('admin')) {
				this.set('userIsAdmin', true);
				return true;
			}

			this.set('userIsAdmin', false);
			return false;
		},

		canEditCandidate: function canEditCandidate(candidate) {
			var user = this.getCurrentUser();
			if (this.testIsAdmin(user)) {
				return true;
			} else if (this.isMyCandidate(candidate)) {
				return true;
			}

			return false;
		},

		/**
   * Check if "this" is my candidate
   * @param candidate
   * @returns {boolean}
   */
		isMyCandidate: function isMyCandidate(candidate) {
			var isMy = false,
			    userId,
			    ownerId;
			var user = this.getCurrentUser();

			if (user && candidate) {
				userId = user.get('id');
				ownerId = candidate.get('owner.id');
				if (userId === ownerId) {
					isMy = true;
					this.set('hasCandidates', true);
				}
			}

			return isMy;
		},

		hasCandidates: false,
		checkUserCandidates: function checkUserCandidates() {
			var me = this;
			if (this.get('userIsLoggedIn')) {
				_ember.default.$.getJSON("/api/v1/candidates?user=me").then(function (data) {
					if (data && data.candidates && data.candidates.length) {
						me.set('hasCandidates', true);
					} else {
						me.set('hasCandidates', false);
					}
				});
			} else {
				me.set('hasCandidates', false);
			}
		},
		userHasCandidates: _ember.default.observer('userIsLoggedIn', function () {
			this.checkUserCandidates();
		}),

		/**** votes ****/
		votingPhase: srvParams && typeof srvParams.votingPhase !== 'undefined' ? srvParams.votingPhase : false, // en/disable voting and votingbasket
		votingbasket: null,
		votingbasketJson: null,
		userHasVoted: false,
		userWantsToVoteAfterLogin: false,

		checkIfUserWantsToVote: function checkIfUserWantsToVote() {
			var userWantsToVoteAfterLogin = this.get('userWantsToVoteAfterLogin');
			if (userWantsToVoteAfterLogin === true) {
				this.openVotingBasket();
				this.set('userWantsToVoteAfterLogin', false);
			}
		},
		setUserHasVoted: function setUserHasVoted() {
			this.set('userHasVoted', true);
		},
		setVotingbasket: function setVotingbasket(votingbasket) {
			// if(typeof console === 'object') { console.log('setVotingbasket',votingbasket.toJSON()); }
			if (votingbasket) {
				votingbasket.set('updated', new Date());
				this.set('votingbasket', votingbasket);
				this.set('votingbasketJson', votingbasket.toJSON());
				var submitted = votingbasket.get('submitted');
				var inRequest = votingbasket.get('voting_doi_requested');
				var confirmed = votingbasket.get('voting_doi_confirmed');
				// if(typeof console === 'object') { console.log('submitted,votingbasket',submitted,votingbasket); }
				if (submitted === true || inRequest === true || confirmed === true) {
					this.setUserHasVoted();
				}
			} else {
				this.set('votingbasket', null);
				this.set('votingbasketJson', null);
			}

			this.countVotes();
		},
		getVotingbasket: function getVotingbasket() {
			return this.get('votingbasket');
		},
		findNextFreeVotingKey: function findNextFreeVotingKey(votingbasket) {
			if (!votingbasket) {
				votingbasket = this.get('votingbasket');
			}
			var votingBasketJson = votingbasket.toJSON();
			var key = 1;

			for (var i = 1; i <= 7; i++) {
				var vote = votingBasketJson['vote' + i];
				// if(typeof console === 'object') { console.log('vote',vote); }
				if (vote && vote.candidate) {
					key++;
				}
			}

			if (key === 8) {
				key = 7;
			}

			return key;
		},
		findCandidateVoting: function findCandidateVoting(candidate) {

			var votingbasket = this.get('votingbasket');

			if (!votingbasket) {
				return false;
			}

			var votingBasketJson = votingbasket.toJSON();

			if (candidate) {
				for (var i = 1; i <= 7; i++) {
					var vote = votingBasketJson['vote' + i];
					if (vote && vote.candidate) {
						var candidateId = null;
						if (typeof candidate.get === 'function') {
							candidateId = candidate.get('id');
						} else {
							candidateId = candidate.id;
						}
						if (candidateId === vote.candidate.id) {
							return i;
						}
					}
				}
			}

			return false;
		},


		votesCount: 0,
		votingBasketOpener: 0,

		openVotingBasket: function openVotingBasket() {
			var votingBasketOpener = this.get('votingBasketOpener');
			votingBasketOpener++;
			this.set('votingBasketOpener', votingBasketOpener);
		},
		countVotes: function countVotes() {
			var _this = this;

			var categories = this.getCategories();
			var votingbasket = this.get('votingbasket');
			var c = 0;
			// if(typeof console === 'object') { console.log('categories',votingbasket,categories); }

			if (categories && votingbasket) {
				// let cats = this.getModeldata(categories);
				categories.forEach(function (item) {
					var e = _this.getModeldata(item);
					var hasVote = votingbasket.get('vote' + e.key);
					if (hasVote && hasVote.candidate) {
						c++;
					}
				});
			}

			this.set('votesCount', c);
			return c;
		},
		refreshVotes: function refreshVotes(basket) {
			var _this2 = this;

			return basket.store.queryRecord('votingbasket', {}).then(function (vt) {
				_this2.setVotingbasket(vt);
				if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
					console.log('QUERY!!!', vt, vt.toJSON());
				}
				// appController.openVotingBasket();
				return vt;
			});
		},


		/**** categories ****/
		useCategories: true, // enable / disable categories
		selectedCategory: null,
		setCategories: function setCategories(categories) {
			this.set('categories', categories);
		},
		getCategories: function getCategories() {
			var categories = this.get('categories');
			return categories;
		},
		getCategoryByTitle: function getCategoryByTitle(title) {
			var _this3 = this;

			var categories = this.getCategories();
			var category = null;

			categories.forEach(function (item) {
				var e = _this3.getModeldata(item);
				if (e.title.toLowerCase() === title.toLowerCase()) {
					category = item;
				}
			});

			return category;
		},
		gotoCategory: function gotoCategory(category) {
			// if(typeof console === 'object') { console.log('gotoCategory',category); }
			// if(typeof console === 'object') { console.log('location',location); }
			var title = null;

			if (category && category.title) {
				title = category.title.toLowerCase();
			}

			if (location.pathname === '/' || location.pathname === '/symbols') {
				this.transitionToRoute({ queryParams: { cg: title } });
			} else {
				this.transitionToRoute('symbols', { queryParams: { cg: title } });
			}
		},
		saveSymbolCategory: function saveSymbolCategory(symbol, categoryJson) {
			delete categoryJson.key; // donno getting an error when submitting key
			symbol.set('category', categoryJson);
			this.saveSymbol(symbol);
		},
		saveSymbol: function saveSymbol(symbol) {
			symbol.save({ adapterOptions: { modelSave: true } }).then(function (c) {
				// if(typeof console === 'object') { console.log('SYMBOL SAVED',c); }
				return c;
			}, function () {
				return null;
			});
		},


		/***** HELPERS ****/
		getModeldata: function getModeldata(model) {
			var id = model.get('id');
			var data = model.toJSON();
			data.id = id;
			return data;
		},


		/**
   * --> target, nominate or any other
   * --> source nominate or any other source?
   * --> check if we have crop data
   * --> save, "dialog-seen"
   * --> check if this is "my" candidate 1. candidate has no owner/is_online=false or candidate.owner == user
   */
		cropImageDialog: function cropImageDialog(candidate, target) {

			return;
			// check if this is my candidate!
			// eslint-disable-next-line no-unreachable
			var route = location.href;
			var patt = new RegExp("/crop/");
			var res = patt.test(route);

			// if(typeof console === 'object') { console.log('cropImageDialog',candidate.get('media.poster'),res,target,route); }

			var id = candidate.get('id');
			var isOnline = candidate.get('online');
			var main_tag = candidate.get('main_tag');
			var poster = candidate.get('media.poster');
			var url_base = candidate.get('media.poster.url_base');
			var crop = candidate.get('media.poster.crops');
			var isVisible = $('.toast-crop-action');

			if (!poster) {
				return false;
			}

			if (!url_base) {
				return false;
			}

			if (isVisible && isVisible.length) {
				return true;
			}

			if (res) {
				return false;
			}

			if (isOnline) {
				if (!this.isMyCandidate(candidate)) {
					return false;
				}
			}

			if (!id || !main_tag) {
				return false;
			}

			if (crop && (typeof crop === 'undefined' ? 'undefined' : _typeof(crop)) === 'object') {
				return true;
			}

			var seen = UTIL.storage.getLocal('candidate_crop_dialog_' + id);
			if (seen) seen = parseInt(seen);
			if (seen >= 2) {
				return false;
			}
			if (!seen) seen = 1;else seen = seen + 1;
			UTIL.storage.storeLocal('candidate_crop_dialog_' + id, seen);

			var path = target + '/crop/' + id;

			var $toastContent = $('<span>The poster image of your candidate is now ready to crop.</span>').add($('<button class="btn-flat toast-action toast-crop-action" onclick="UTIL.MDC.closeToasts();location.href=\'' + path + '\'">Ok</button>')).add($('<button class="btn-flat toast-action" onclick="UTIL.MDC.closeToasts();">Cancel</button>'));

			Materialize.toast($toastContent, 100000);
		},

		getErrors: function getErrors(e, firstOnly) {
			var errorMsg = '';
			if ((typeof e === 'undefined' ? 'undefined' : _typeof(e)) === 'object') {
				if (e.errors) {
					var errors = e.errors;
					for (var i = 0; i < errors.length; i++) {
						errorMsg += errors[i].detail + '<br />';
						if (firstOnly) {
							break;
						}
					}
				}
			}
			return errorMsg;
		},


		showConfirmModalAction: 0,
		showConfirmModal: 1,
		callbackConfirm: null,
		gotoLoginCallback: null,
		showConfirmDialog: function showConfirmDialog(title, message, callback, o) {
			o = o || {};
			this.set('showConfirmModal', 1);
			this.set('confirmModalTitle', title);
			this.set('confirmModalMessage', message);
			this.set('callbackConfirm', callback);
			this.set('options', o);
			if (o.gotoLoginCallback) {
				this.set('gotoLoginCallback', o.gotoLoginCallback);
			}
			if (o.gotoRegisterCallback) {
				this.set('gotoRegisterCallback', o.gotoRegisterCallback);
			}
			$('#confirmModal').modal('open');
		},
		confirmCallback: function confirmCallback() {
			var callbackConfirm = this.get('callbackConfirm');
			if (typeof callbackConfirm === 'function') {
				callbackConfirm();
				this.set('callbackConfirm', null);
			}
		},
		confirmLoginCallback: function confirmLoginCallback() {
			var gotoLoginCallback = this.get('gotoLoginCallback');
			if (typeof gotoLoginCallback === 'function') {
				gotoLoginCallback();
				this.set('gotoLoginCallback', null);
			}
		},
		confirmRegisterCallback: function confirmRegisterCallback() {
			var gotoRegisterCallback = this.get('gotoRegisterCallback');
			if (typeof gotoRegisterCallback === 'function') {
				gotoRegisterCallback();
				this.set('gotoRegisterCallback', null);
			}
		},


		actions: {
			gotoSymbols: function gotoSymbols() {
				this.transitionToRoute('symbols');
			},
			gotoLogin: function gotoLogin() {
				this.transitionToRoute('user-signin');
				this.confirmLoginCallback();
			},
			gotoRegister: function gotoRegister() {
				this.transitionToRoute('user-signup');
				this.confirmRegisterCallback();
			},
			confirmCallback: function confirmCallback() {
				this.confirmCallback();
			},
			toastMsg: function toastMsg(msg, cancel) {

				var $toastContent = $('<span>' + msg + '</span>');
				if (cancel) {
					$toastContent.add($('<button class="btn-flat toast-action" onclick="UTIL.MDC.closeToasts();">Cancel</button>'));
				}
				Materialize.toast($toastContent, 5000);
			},
			trackCandidate: function trackCandidate(candidate, type, callback) {

				type = type || 'view';

				var candidateId;
				if (!candidate) {
					return false;
				}

				if ((typeof candidate === 'undefined' ? 'undefined' : _typeof(candidate)) === 'object') {
					candidateId = candidate.get('id');
				} else {
					candidateId = candidate;
				}

				// var me = this;
				var url = '/api/v1/candidates/' + candidateId + '/track/' + type;

				$.ajax({
					method: 'GET',
					url: url,
					dataType: 'json',
					success: function success(data) {
						if (data && callback) {
							callback(data);
						}
					},
					error: function error(data) {
						if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
							console.log('Error.count with ', data);
						}
					}
				});
			},
			show_cropImageDialog: function show_cropImageDialog(candidate, target) {
				this.cropImageDialog(candidate, target);
			}
		}

	});
});