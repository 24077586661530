define('symbolsofpeace/controllers/nominate/base-nominate-controller', ['exports', 'symbolsofpeace/controllers/base-controller', 'ember'], function (exports, _baseController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseController.default.extend({
		parentController: _ember.default.inject.controller('nominate'),
		isProcessing: false,

		actions: {
			saveCandidate: function saveCandidate(afterSave, onError) {

				var parentController = this.get('parentController');
				var candidate = this.get('model');

				if (!candidate && parentController) {
					candidate = parentController.get('candidate');
				}

				if (candidate) {
					if (typeof afterSave !== 'function') {
						afterSave = function afterSave() {};
					}
					if (typeof onError !== 'function') {
						onError = function onError() {};
					}
					candidate.save().then(afterSave, onError);
				}
			},


			completeCandidate: function completeCandidate(afterSave) {

				var parentController = this.get('parentController');
				var candidate = this.get('model');

				if (!candidate && parentController) {
					candidate = parentController.get('candidate');
				}

				if (candidate) {
					// return Ember.$.getJSON('/api/v1/candidates/'+candidate.get('id')+'/finish');
					// $.getJSON('/api/v1/candidates/' + candidate.get('id') + '/finish').then(afterSave,onError);
					$.getJSON('/api/v1/candidates/' + candidate.get('id') + '/finish', afterSave);
				}
			}
		}
	});
});