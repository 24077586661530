define('symbolsofpeace/models/candidate', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		main_tag: _emberData.default.attr(),
		color: _emberData.default.attr(),
		rank: _emberData.default.attr(),
		change_reason: _emberData.default.attr(),
		creation_date: _emberData.default.attr(),
		modification_date: _emberData.default.attr(),
		note: _emberData.default.attr('object'),
		client_stage: _emberData.default.attr(),
		counters: _emberData.default.attr('object'),
		media: _emberData.default.attr('object'),
		additional_tags: _emberData.default.attr('array'),
		owner: _emberData.default.attr('object'),

		comments: _emberData.default.hasMany('comment', {
			async: true,
			inverse: 'candidateComment'
		}),

		/**
   * reset changeReason after every update
   */
		didUpdate: function didUpdate() {
			this.set('change_reason', '');
		}

		// symbol: DS.belongsTo('symbol', { async: false })
	});
});