define('symbolsofpeace/components/image-cropper', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		classNames: ['image-cropper'],

		cropperInstance: null,
		initialized: null,
		isProcessing: false,
		isPProzessing: _ember.default.observer('controller.isProcessing', function () {
			var parent = this.get('controller.isProcessing');
			this.set('isProcessing', parent);
		}),

		/**
   * lazyObserver: observe activateTab in your controller
   * this must be the tab body element
   */
		lazyObserver: _ember.default.observer('controller.activateTab', function () {
			var initialized = this.get('initialized');
			if (!initialized) {
				var parent = this.get('controller.activateTab');
				if (parent) {
					var $element = this.$();
					var a = parent.find($element);
					if (a && a.length) {
						this._initCropper();
					}
				}
			}
			return this.get('controller.activateTab');
		}),

		didInsertElement: function didInsertElement() {
			var lazyInit = this.get('lazyInit');
			if (lazyInit !== true) {
				this._initCropper();
			}
		},


		_getCropperImage: function _getCropperImage() {

			var me = this,
			    $element = me.$();

			if (!$element) {
				return false;
			}

			return $element.find('.image-cropper__img');
		},

		_initCropper: function _initCropper() {

			var me = this;
			// controller  = me.get('controller'),
			// canvasData = null,
			// cropBoxData = null;

			var $image = this._getCropperImage();

			// if(typeof console === 'object') { console.log('_initCropper! typeof $image.cropper',$image,typeof $image.cropper, typeof this.cropperInstance,this.cropperInstance); }

			var media = null;
			var mediaType = null;
			if (me.get('media')) {
				media = me.get('media');
				mediaType = me.get('mediaType');
			}

			this.setMaxHeight($image);

			var cropper = function cropper() {
				me.cropperInstance = $image.cropper({
					viewMode: 1,
					autoCropArea: 1,
					aspectRatio: me.get('aspectRatio') || null,
					strict: true,
					responsive: true,
					restore: true,
					guides: true,
					highlight: true,
					dragCrop: false,
					movable: false,
					resizable: true,
					zoomable: true,
					mouseWheelZoom: false,
					touchDragZoom: true,
					built: function built() {

						if (media && mediaType && media.poster && media.poster.crops && media.poster.crops[mediaType]) {
							var imageData = $(this).cropper('getImageData');
							var cropData = media.poster.crops[mediaType];
							var cropArr = cropData.split('-');
							// if(typeof console === 'object') { console.log('cropArr',cropArr,imageData,cropArr[0] * imageData.naturalWidth); }

							$(this).cropper('setData', {
								x: cropArr[0] * imageData.naturalWidth,
								y: cropArr[1] * imageData.naturalHeight,
								width: cropArr[2] * imageData.naturalWidth,
								height: cropArr[3] * imageData.naturalHeight
							});
						}
					}
				});

				$image.attr('data-media-type', mediaType);
			};

			if (this.cropperInstance) {
				this.destroyCropper();
				setTimeout(function () {
					cropper();
				}, 500);
			} else {
				cropper();
			}
			this.set('initialized', true);
		},

		setMaxHeight: function setMaxHeight() {

			var $el = this.$();
			var h = 300; // iphone 6 = 350
			if ($(window).width() < 320) {
				h = 330;
			}
			var max = $(window).height() - h;
			if (max > 500) {
				max = 500;
			}
			$el.css('max-height', max + 'px');
		},

		getCropData: function getCropData(canvasData, cropBoxData, imageData) {

			var m = {
				cropX: (cropBoxData.left - canvasData.left) / imageData.width,
				cropY: (cropBoxData.top - canvasData.top) / imageData.height,
				cropWidth: cropBoxData.width / imageData.width,
				cropHeight: cropBoxData.height / imageData.height
			};

			if (m.cropX < 0) {
				m.cropX = 0;
			}
			if (m.cropY < 0) {
				m.cropY = 0;
			}
			if (m.cropWidth > 1) {
				m.cropWidth = 1;
			}
			if (m.cropHeight > 1) {
				m.cropHeight = 1;
			}

			return m;
		},

		destroyCropper: function destroyCropper() {

			var me = this,
			    $element = me.$();

			if (!$element) {
				return false;
			}

			var $image = this._getCropperImage();

			//canvasData = $image.cropper('getCanvasData');
			//cropBoxData = $image.cropper('getCropBoxData');
			$image.cropper('destroy');
			delete this.cropperInstance;
		},

		/**
   * Called when the element of the view is going to be destroyed.
   * Override this function to do any teardown that requires an element, like removing event listeners.
   */
		willDestroyElement: function willDestroyElement() {
			this.destroyCropper();
		},

		actions: {
			save: function save() {
				var $image = this._getCropperImage(),
				    canvasData,
				    cropBoxData,
				    imageData;

				canvasData = $image.cropper('getCanvasData');
				cropBoxData = $image.cropper('getCropBoxData');
				imageData = $image.cropper('getImageData');
				this.sendAction('save', canvasData, cropBoxData, imageData);
			},
			lazyInit: function lazyInit() {
				this._initCropper();
			}
		}

	});
});