define('symbolsofpeace/controllers/symbol/base-symbol-controller', ['exports', 'symbolsofpeace/controllers/nominate/base-nominate-controller', 'ember'], function (exports, _baseNominateController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseNominateController.default.extend({
		parentController: _ember.default.inject.controller('symbol'),

		actions: {

			deleteCandidate: function deleteCandidate() {

				var model = this.get('model'),
				    parentController = this.get('parentController');

				model.destroyRecord().then(function () {
					parentController.storeCandidate(null);
					parentController.gotoRoute('symbols');
				}, function (e) {
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('candidate NOT DESTROYED', e);
					}
				});
			},

			deleteSymbol: function deleteSymbol() {

				var model = this.get('model'),
				    parentController = this.get('parentController');

				model.destroyRecord().then(function () {
					parentController.gotoRoute('symbols');
				}, function (e) {
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('symbol NOT DESTROYED', e);
					}
				});
			}

		}

	});
});