define('symbolsofpeace/helpers/debug-log', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.debugLog = debugLog;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function debugLog(params /*, hash*/) {
		if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
			console.log('HELPER LOG', params);
		}
	}

	exports.default = _ember.default.Helper.helper(debugLog);
});