define('symbolsofpeace/components/symbol-search', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		value: null,
		query: '',
		hashtags: [],
		keydownTimeout: 0,

		didInsertElement: function didInsertElement() {
			Materialize.updateTextFields();
		},

		hashtagSearch: _ember.default.computed('hashtags', function () {
			var data = this.get('hashtags');
			if (data && data.length) {
				return true;
			}
			return false;
		}),

		searchHashtag: function searchHashtag() {

			var me = this;

			if (this.keydownTimeout) {
				clearTimeout(this.keydownTimeout);
			}

			this.keydownTimeout = setTimeout(function () {
				me.r_searchHashtag();
			}, 250);
		},

		r_searchHashtag: function r_searchHashtag() {
			var input = this.$().find('input');
			var hashtag = input.get(0).value;
			var me = this;
			this.set('query', hashtag);

			if (!hashtag || hashtag.length === 0) {
				me.set('hashtags', []);
				return true;
			}

			$.get(this.get('search'), {
				q: hashtag
			}).then(function (r) {

				if (r && r.data && r.data.length) {
					me.set('hashtags', r.data);
				} else {
					me.set('hashtags', []);
				}
			}, function () {
				me.set('hashtags', []);
			});
		},

		actions: {
			search: function search() {
				this.searchHashtag();
			},
			chooseTag: function chooseTag(tag) {
				var controller = this.get('controller');
				controller.transitionToRoute('symbol', tag);
				$('.nav-slide-out-collapse').sideNav('hide');
				this.set('hashtags', []);
				this.set('value', '');
				return false;
			}
		}

	});
});