define('symbolsofpeace/controllers/symbol/upload', ['exports', 'symbolsofpeace/controllers/nominate/base-nominate-controller', 'ember'], function (exports, _baseNominateController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseNominateController.default.extend({

		submitFailed: false,
		isProcessing: false,
		showUploadProgress: false,
		uploadProgress: 0,

		setProgress: function setProgress(progress) {
			var s = _ember.default.String.htmlSafe('width:' + progress + '%');
			if (!progress) {
				this.set('showUploadProgress', false);
				this.set('uploadProgress', s);
			} else {
				this.set('showUploadProgress', true);
				this.set('uploadProgress', s);
			}
		},

		clearValidations: function clearValidations() {

			this.setProperties({
				submitFailed: false,
				showUploadProgress: false
			});
		},

		actions: {

			dz_UploadSuccess: function dz_UploadSuccess(r) {
				this.clearValidations();
				this.setProgress(0);
				if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
					console.log('dz_UploadSuccess', r);
				}
				// this.set('model.pic',r.user.pic);
				UTIL.tracker.track('event', { category: 'User', action: 'picture-upload-complete', label: 'Picture upload completed', value: 1 });
			},

			uploadProgress: function uploadProgress(file, progress) {
				this.setProgress(progress);
			},

			returnPictureUploadToken: function returnPictureUploadToken() {
				return this.get('model');
			},

			save: function save() {

				this.clearValidations();

				if (!this.checkFields()) {
					return false;
				}

				this.setProperties({
					isProcessing: true
				});

				this.get('myController').send('save');
			}

		}

	});
});