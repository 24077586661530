define('symbolsofpeace/components/comments-filter', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		classNames: ['media-comments'],
		filterValue: '',
		searchvalue: '',
		meta: {},
		isLoading: false,

		lastResult: null,
		lastParam: 'all',
		limit: 50,
		offset: 0,
		page: 1,

		/**
   * Last loaded item of query
   */
		isLast: _ember.default.computed('meta.last', function () {
			return this.get('meta.last');
		}),

		doReload: _ember.default.observer('reload', function () {
			var reload = this.get('reload');
			this.reloadResult(reload);
			return reload;
		}),

		init: function init() {

			this._super.apply(this, arguments);

			if (this.get('autoload')) {
				this.initialLoad();
			}
		},


		didInsertElement: function didInsertElement() {
			Materialize.updateTextFields();
			// we want to make sure 'this' inside `didScroll` refers
			// to the IndexView, so we use jquery's `proxy` method to bind it
			if (this.get('infiniteScroll')) {
				$(window).on('scroll', $.proxy(this.didScroll, this));
			}
		},

		willDestroyElement: function willDestroyElement() {
			// have to use the same argument to `off` that we did to `on`
			if (this.get('infiniteScroll')) {
				$(window).off('scroll', $.proxy(this.didScroll, this));
			}
		},

		didScroll: function didScroll() {
			if (this.isScrolledToBottom()) {
				this.loadMore(this.limit);
			}
		},

		// we check if we are at the bottom of the page
		isScrolledToBottom: function isScrolledToBottom() {
			var bottomDistance = 10;
			var distanceToViewportTop = $(document).height() - $(window).height();
			var viewPortTop = $(document).scrollTop();
			// if(typeof console === 'object') { console.log('viewPortTop',viewPortTop,distanceToViewportTop,(viewPortTop - (distanceToViewportTop - bottomDistance)),$(document).height(),$(window).height()); }

			if (viewPortTop === 0) {
				// if we are at the top of the page, don't do
				// the infinite scroll thing
				return false;
			}

			return viewPortTop - (distanceToViewportTop - bottomDistance) >= 0;
		},

		initialLoad: function initialLoad() {
			var _this = this;

			this.set('isLoading', true);
			this.commentFilter().then(function (allResults) {
				_this.set('results', allResults.results);
				_this.set('meta', allResults.meta);
				_this.afterResultsLoaded();
			});

			this.limit = this.get('limit') || 10;
			this.page = this.get('page') || 1;

			// if(this.get('resetAndReload') >= 0) {
			// 	this.addObserver('resetAndReload', this, 'parentModelChanged');
			// }
		},


		loadMore: function loadMore(limit) {
			var _this2 = this;

			if (this.get('isLast')) {
				return true;
			}

			if (this.get('isLoading')) {
				return false;
			}

			this.set('isLoading', true);
			this.page = this.page + 1;
			this.deactivateLoadMoreBtn();

			limit = limit || this.limit;

			this.commentFilter(this.filterValue, limit, this.page, true).then(function (resultsObj) {
				_this2.set('results', resultsObj.results);
				_this2.set('meta', resultsObj.meta);
				_this2.activateLoadMoreBtn();
				_this2.afterResultsLoaded();
			});
		},

		reloadResult: function reloadResult(reload) {
			var _this3 = this;

			if ((typeof reload === 'undefined' ? 'undefined' : _typeof(reload)) === 'object') {
				this.addComment(this.get('appendPosition'), reload);
			} else {
				this.set('isLoading', true);
				this.page = 1;
				var limit = this.limit * this.page;

				this.commentFilter(this.filterValue, limit, this.page, false).then(function (resultsObj) {
					_this3.set('results', resultsObj.results);
					_this3.set('meta', resultsObj.meta);
					_this3.afterResultsLoaded();
				});
			}
		},

		activateLoadMoreBtn: function activateLoadMoreBtn() {
			var lm = $('.media-comment-filter__loadmore');
			lm.removeClass('disabled').removeClass('spin').removeAttr('disabled', 'disabled');
		},

		deactivateLoadMoreBtn: function deactivateLoadMoreBtn() {
			var lm = $('.media-comment-filter__loadmore');
			lm.addClass('disabled').addClass('spin').attr('disabled', 'disabled');
		},

		afterResultsLoaded: function afterResultsLoaded() {
			var meta = this.get('meta');
			this.set('isLoading', false);

			if (!this.get('forceHideFilter')) {
				if (meta && meta.last === true && !meta.total) {
					this.set('hideFilter', true);
					// this.set('noResult', true);
					this.get('noResult')(true);
				} else {
					this.set('hideFilter', false);
					// this.set('noResult', false);
					this.get('noResult')(false);
				}
			}
		},

		addComment: function addComment(pos, comment) {

			var results = this.get('lastResults');

			if (!results) {
				return this.reloadResult();
			}

			var r = _ember.default.A();
			var c = [comment];

			if (pos === 'append') {
				if (results) {
					r.pushObjects(results.toArray());
				}
				r.pushObjects(c);
			} else {
				r.pushObjects(c);
				if (results) {
					r.pushObjects(results.toArray());
				}
			}

			results = r;

			this.set('results', results);
			this.set('meta', this.get('lastMeta'));
			this.set('lastResults', results);
			var model = this.get('model');
			model.set('comments', results.toArray());
			this.afterResultsLoaded();

			return {
				query: this.get('lastParam'),
				meta: this.get('lastMeta'),
				results: results
			};
		},


		// newComment: function(comment) {
		// 	var record = this.get('controller').store.createRecord('comment', comment);
		// 	this.get('results').pushObject(record);
		// },


		/**
   *
   * Load (filtered) symbols
   *
   * @param param
   * @param limit
   * @param page
   * @param keepExisting
   * @returns {*|Promise.<TResult>}
   */
		commentFilter: function commentFilter(param, limit, page, keepExisting) {
			var _this4 = this;

			var me = this;
			var lastResults = this.get('lastResults');

			// var appController = this.get('appController');
			// appController.send('hideSymbolQuickLook',false);

			if (lastResults && keepExisting !== true) {
				me.set('isLoading', true);
			}

			param = param || this.lastParam;
			limit = limit || this.limit;
			page = page || this.page;

			var model = this.get('model');

			// if(typeof console === 'object') { console.log('commentFilter.model',model); }

			if (!model) {
				return false;
			}

			// let candidate = this.get('store').peekRecord('candidate', model.get('id'));

			return this.get('controller').get('store').query('comment', {
				parent_type: this.get('parent_type'),
				parent_id: model.get('id'),
				c: param,
				limit: limit,
				page: page }).then(function (results) {

				// if(typeof console === 'object') { console.log('filter.results',results); }
				var meta = results.get('meta');

				model.set('comments', results.toArray());
				_this4.set('isLoading', false);

				if (lastResults && keepExisting === true) {
					var r = _ember.default.A();
					r.pushObjects(lastResults.toArray());
					r.pushObjects(results.toArray());
					results = r;
				}

				_this4.set('lastMeta', meta);
				_this4.set('lastResults', results);
				_this4.set('lastParam', param);
				return {
					query: param,
					meta: meta,
					results: results
				};
			}, function () {
				_this4.set('isLoading', false);
				return {
					query: param,
					meta: {
						last: true
					},
					results: lastResults
				};
			});
		},


		actions: {

			_loadMore: function _loadMore(limit, event) {
				event.preventDefault();
				this.loadMore(limit);
			},

			handleFilterEntry: function handleFilterEntry(p, event) {
				var _this5 = this;

				event.preventDefault();
				this.isLoading = true;
				this.filterValue = p;
				this.page = 1;

				this.commentFilter(this.filterValue, this.limit, this.page).then(function (resultsObj) {
					if (resultsObj.query === p) {
						_this5.set('results', resultsObj.results);
						_this5.set('meta', resultsObj.meta);
					}
					_this5.afterResultsLoaded();
				});

				return false;
			}
		}
	});
});