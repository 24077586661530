define('symbolsofpeace/routes/nominate/index', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		/**
   * get "Current"-Candidate
   *
   * @param params
   * @param transition
   */
		model: function model() {

			return this.get('store').queryRecord('candidate', {}).then(function (candidate) {
				return candidate;
			}, function () {
				return null;
			});
		},

		afterModel: function afterModel(candidate) {
			if (candidate) {
				var nominateController = this.controllerFor('nominate');
				nominateController.storeCandidate(candidate);
				var nominateIndexController = this.controllerFor('nominate.index');
				nominateIndexController.set('hasCandidate', true);
			}
		}
	});
});