define('symbolsofpeace/routes/nominate/notes', ['exports', 'symbolsofpeace/routes/nominate/base-nominate-route'], function (exports, _baseNominateRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseNominateRoute.default.extend({

		docTitle: 'Nominate Notes',

		afterModel: function afterModel(candidate) {
			this._super.apply(this, arguments);
			var nominateController = this.controllerFor('nominate');

			if (!candidate.get('note') || _typeof(candidate.get('note')) !== 'object') {
				candidate.set('note', {});
			}

			nominateController.set('activeStep', 3);
		}
	});
});