define('symbolsofpeace/components/candidate-media', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		classNames: ['symbol-candidate-media'],
		classNameBindings: ['isVideo:has-video'],
		fitSquareImage: true,
		fitSquareImageAndBlur: true,

		// init() {
		// 	this._super(...arguments);
		//
		// 	let votingbasket = this.get('votingbasket');
		// 	let votingbasketKey = this.get('votingbasketKey');
		//
		// 	if(votingbasket && votingbasketKey) {
		// 		let vote = votingbasket.get('vote'+votingbasketKey);
		// 		let candidate = null;
		//
		// 		if(vote && vote.candidate) {
		// 			candidate = vote.candidate;
		// 		}
		// 		else if(vote && vote.symbol && vote.symbol.top_conadidate) {
		// 			candidate = vote.candidate;
		// 		}
		//
		//
		// 		if(candidate) {
		// 			this.set('symbol',vote.symbol);
		// 			this.set('candidate',candidate);
		// 			if(typeof console === 'object') { console.log('candidate',candidate); }
		//
		//
		// 		}
		// 	}
		//
		// 	if(typeof console === 'object') { console.log('candidate',this.get('candidate')); }
		// },


		vimeoOptions: _ember.default.computed(function () {

			// var media = this.get('candidate.media');

			var o = {
				title: this.get('candidate.main_tag')
				// loop: true,
				// width: 100,
				// height: 320,
				// maxheight: 320,
				// maxWidth: 0,
				// portrait: true,
			};

			return o;
		}),

		youtubeOptions: {},

		// videoUrl: null,
		// tagName: 'a',

		isVideo: _ember.default.computed('candidate.media.type', function () {
			var media = this.get('candidate.media');
			// if(typeof console === 'object') { console.log('CandidateMediaComponent.media',media,typeof media); }
			if ((typeof media === 'undefined' ? 'undefined' : _typeof(media)) === 'object') {
				var t = media.type;

				switch (t) {
					case 'youtube':
					case 'youtube-suggest':
						if (media.video && media.video.id) {
							this.set('videoUrl', media.video.id);
						}
						return true;
					case 'vimeo':
						if (media.video && media.video.id) {
							this.set('videoUrl', media.video.id);
						}
						return true;
					case 'vimeo-upload':
						if (media.video && media.video.id) {
							this.set('videoUrl', media.video.id);
						}
						return true;
				}
			}
			return false;
		}),

		isPic: _ember.default.computed('candidate.media.type', function () {
			var t = this.get('candidate.media.type');
			switch (t) {
				case 'youtube':
				case 'youtube-suggest':
				case 'vimeo':
				case 'vimeo-upload':
					return false;
			}

			return true;
		}),

		poster: _ember.default.computed('candidate.media.type', function () {
			// var t = this.get('candidate.media.type');
			// switch(t) {
			// 	case'youtube':
			// 	case'vimeo':
			// 		return this.get('candidate.media.poster');
			// }

			return this.get('candidate.media.poster');
		}),

		actions: {

			onPlay: function onPlay() {
				if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
					console.log('PLAY!!!', true);
				}
				return true;
			}

		}

	});
});