define('symbolsofpeace/models/symbol', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		tag: _emberData.default.attr(),
		color: _emberData.default.attr(),
		rank: _emberData.default.attr(),
		change_reason: _emberData.default.attr(),
		creation_date: _emberData.default.attr(),
		counters: _emberData.default.attr('object'),
		category: _emberData.default.attr('object'),
		// category:  DS.belongsTo('category'),
		top_candidate: _emberData.default.attr('object'),
		// candidates: DS.hasMany('candidate',{async:true}),
		comments: _emberData.default.hasMany('comment', {
			async: true,
			inverse: 'symbolComment'
		}),

		hasMultipleCandidates: function () {
			var l = this.get('counters._candidates');
			return l > 1;
		}.property('counters._candidates'),

		countCandidates: function () {
			return this.get('counters._candidates');
		}.property('counters._candidates'),

		/**
   * reset changeReason after every update
   */
		didUpdate: function didUpdate() {
			this.set('change_reason', '');
		}

	});
});