define('symbolsofpeace/components/symbol-quick-look-pushover', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({
		//class="pushover pushover--has-arrow" id="pushover-{{symbol.tag}}" data-grab-content data-grab-trigger="tile-{{symbol.tag}}

		// classNames: ['pushover','pushover--has-arrow'],
		// attributeBindings: ['data-grab-content', 'data-grab-trigger'],
		// elementId:'pushover-',

		containerSelector: '.media-listing',
		grabId: null,
		pushoverId: null,
		useFastdom: false,
		afterScrollTimeout: 0,
		scrollToContent: true,

		init: function init() {
			this._super.apply(this, arguments);
			// this.set('elementId','pushover-'+this.get('symbol.tag'));
			// this.set('data-grab-trigger','tile-'+this.get('symbol.tag'));
		},

		didInsertElement: function didInsertElement() {
			this.positionAtParentTile();
			$(window).on('resize', $.proxy(this.onResize, this));
			$(document).on('click touch', $.proxy(this.clickOutside, this));
		},

		willDestroyElement: function willDestroyElement() {
			$(window).off('resize', $.proxy(this.onResize, this));
			$(document).off('click touch', $.proxy(this.clickOutside, this));
		},

		positionAtParentTile: function positionAtParentTile() {

			var me = this;
			// var appController = this.get('appController');

			var $element = this.$();
			var $content = $element.find('.pushover');

			this.grabId = $content.data('grab-trigger');
			this.pushoverId = 'pushover-' + this.get('symbol.tag');
			var $triggerElement = $(document).find('#' + this.grabId);

			// var $container = $(this.containerSelector);
			// var $arrow = $content.find('[data-grab-content-arrow]');

			if ($triggerElement.length) {

				if (this.useFastdom) {
					fastdom.write(function () {

						me.onResize();

						fastdom.write(function () {
							// if(typeof console === 'object') { console.log('$content.height 1',$content.height()); }
							$content.detach();

							fastdom.write(function () {
								fastdom.read(function () {
									var $insertAfterElement = $triggerElement;
									var offsetTop = $insertAfterElement.offset().top;

									$triggerElement.nextAll().each(function () {
										if (offsetTop === $(this).offset().top) {
											$insertAfterElement = $(this);
										} else {
											return false;
										}
									});

									fastdom.write(function () {
										$content.insertAfter($insertAfterElement);

										fastdom.write(function () {
											if (me.scrollToContent) {
												me.scrollTo($content, 'before');
											} else {
												var appController = this.get('appController');
												appController.set('_quickLookLoading', false);
											}
										});
									});
								});
							});
						});
					});
				} else {
					this.onResize();

					$content.detach();
					var $insertAfterElement = $triggerElement;
					var offsetTop = $insertAfterElement.offset().top;

					$triggerElement.nextAll().each(function () {
						if (offsetTop === $(this).offset().top) {
							$insertAfterElement = $(this);
						} else {
							return false;
						}
					});

					$content.insertAfter($insertAfterElement);

					if (this.scrollToContent) {
						me.scrollTo($content);
					} else {
						var appController = this.get('appController');
						appController.set('_quickLookLoading', false);
					}
				}
			}
		},

		scrollTo: function scrollTo($el, type) {
			var parentOffset = -100;
			var h = 0;
			var f = $('.fixedheader');
			if (f.length) {
				h = h + f.height();
			}

			var position = $el.offset();

			if (type === 'after') {
				position.top = position.top + $el.outerHeight();
			}

			var scroll = {
				scrollTop: position.top - h + parentOffset
			};

			// if(typeof console === 'object') { console.log('$content.height 1',$el.height()); }
			// if(typeof console === 'object') { console.log('position',$el.outerHeight(),scroll,position,h); }

			// $('html, body').animate(scroll, 500);
			// $(window).scrollTop(scroll.scrollTop);
			this.scrollToY(false, $el, type, scroll.scrollTop, 1500, 'easeInExpo');
		},

		//scrollToY(0, 1500, 'easeInOutQuint');
		scrollToY: function scrollToY(afterScrollDone, $el, type, scrollTargetY, speed, easing) {

			var me = this;

			// scrollTargetY: the target scrollY property of the window
			// speed: time in pixels per second
			// easing: easing equation to use

			window.requestAnimFrame = function () {
				return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
					window.setTimeout(callback, 1000 / 60);
				};
			}();

			var scrollY = window.scrollY || document.documentElement.scrollTop,
			    currentTime = 0;

			scrollTargetY = scrollTargetY || 0;
			speed = speed || 2000;
			easing = easing || 'easeInExpo';

			// min time .1, max time .8 seconds
			var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

			// easing equations from https://github.com/danro/easing-js/blob/master/easing.js
			var easingEquations = {
				easeInExpo: function easeInExpo(pos) {
					return pos === 0 ? 0 : Math.pow(2, 10 * (pos - 1));
				}
			};

			// add animation loop
			function tick() {
				currentTime += 1 / 60;

				var p = currentTime / time;
				var t = easingEquations[easing](p);

				if (p < 1) {
					requestAnimFrame(tick);
					window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
				} else {
					// console.log('scroll done');
					window.scrollTo(0, scrollTargetY);
					me.afterScroll(afterScrollDone, $el, type, scrollTargetY, speed, easing);
				}
			}
			// call it once to get started
			tick();
		},

		afterScroll: function afterScroll(afterScrollDone, $el, type, scrollTargetY, speed, easing) {
			// if(typeof console === 'object') { console.log('afterScroll.scrollTargetY',scrollTargetY,$(window).scrollTop()); }

			var wST = $(window).scrollTop(),
			    me = this;
			// try to correct the scroll offset, if something in content has been loaded
			if (!afterScrollDone && wST !== scrollTargetY) {
				if (this.afterScrollTimeout) {
					clearTimeout(this.afterScrollTimeout);
				}
				this.afterScrollTimeout = setTimeout(function () {
					me.scrollToY(true, $el, type, scrollTargetY, speed, easing);
					// var dh = $(document).height();
					// var eh = $el.height();
					// if(typeof console === 'object') { console.log('dh,eh,scrollTargetY,wST',dh,eh,scrollTargetY,wST); }
				}, 150);
			}

			var appController = this.get('appController');
			appController.set('_quickLookLoading', false);
		},

		clickOutside: function clickOutside(event) {

			var remove = false;
			var $content = $('.pushover');
			var appController = this.get('appController');

			// if(typeof console === 'object') { console.log('SymbolQuickLookPushover.ClickOutside.$content.is(event.target),$content.has(event.target)',$content.is(event.target),$content.has(event.target)); }

			// eslint-disable-next-line no-unused-vars
			var t = $(event.target);

			// if(typeof console === 'object') { console.log('t',t,t.closest('.toast')); }

			if ( /*$content.is(':visible') && */!$content.is(event.target) && !$content.has(event.target).length) {
				remove = true;
			}

			return true;

			// eslint-disable-next-line no-unreachable
			if (remove) {
				appController.send('hideSymbolQuickLook', false);
			}
		},

		onResize: function onResize() {
			var $container = $(this.containerSelector);
			var $content = $('#' + this.pushoverId);
			if ($container.length && $content.length) {
				var w = $container.width();
				$content.width(w);
			}
		}

	});
});