define('symbolsofpeace/routes/nominate/crop', ['exports', 'symbolsofpeace/routes/nominate/base-nominate-route'], function (exports, _baseNominateRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseNominateRoute.default.extend({

    docTitle: 'Nominate Crop'

  });
});