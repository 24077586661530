define('symbolsofpeace/components/dropzone-upload', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		previewImgUrl: _ember.default.computed('previewImg', function () {
			var previewImg = this.get('previewImg');

			if (previewImg) {
				return previewImg;
			}
			return '/app/_imgs/icons/profile-picture.svg';
		}),

		didInsertElement: function didInsertElement() {
			var me = this;

			setTimeout(function () {
				me.initDropzone(me.$());
			}, 500);
		},


		setError: function setError(message, title, data) {
			var controller = this.get('controller');
			controller.set('isProcessing', false);
			controller.send('showModal', 'error-modal', {
				statusText: title || 'Sorry',
				statusCode: 404,
				reason: data,
				message: message || 'An error occured, please try again'
			});
		},

		vimeoUpload: function vimeoUpload(file, ticket) {

			var me = this,
			    controller = me.get('controller');
			var name = 'test mich';
			// var api_url = 'https://api.vimeo.com';

			if ((typeof ticket === 'undefined' ? 'undefined' : _typeof(ticket)) !== 'object' || !ticket.ticket_id) {
				if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
					console.log('Trying to upload to vimeo without ticket:', ticket);
				}
				return false;
			}

			new VimeoUpload({
				name: name,
				description: '',
				private: true,
				file: file,
				token: '',
				// upgrade_to_1080: true,
				url: ticket.upload_link_secure,
				user: ticket.user,
				ticket_id: ticket.ticket_id,
				// complete_url: api_url + ticket.complete_uri,
				onError: function onError(data) {
					me.setError(JSON.parse(data).error, 'Sorry', data);
				},
				onProgress: function onProgress(data) {
					if (data) {
						var progress = data.loaded / data.total;
						progress = Math.floor(progress * 100);
						controller.send('uploadProgress', data, progress, data.loaded);
					}
				},
				onComplete: function onComplete() {
					controller.send('vimeoUploadComplete', ticket);
				}
			}).uploadFile();
		},


		initDropzone: function initDropzone($element) {

			Dropzone.autoDiscover = false;

			var me = this,
			    controller = me.get('controller'),
			    dzSelector = '.dropzone',
			    acceptedFiles = this.get('acceptedFiles') || 'image/*',
			    uploadBaseUrl = this.get('uploadBaseUrl');

			// var isTouchSupported = (typeof(window.ontouchstart) != 'undefined');

			$(dzSelector, $element).dropzone({
				// init: function(a) {
				//
				// 	// this.on("processing", function(file) {
				// 	// 	if(typeof console === 'object') { console.log('processing',this.options); }
				// 	// 	// this.options.url = " /api/v1/candidates/{candidateId}/pics";
				// 	// });
				//
				// 	// if(isTouchSupported) {
				// 	// 	var uploadtouchtrigger = '.dz-trigger',
				// 	// 		trigger = $('.dz-trigger'),
				// 	// 		me = this;
				// 	//
				// 	// 	var fixtouchlinks = new Hammer(trigger.get(0), {});
				// 	// 	fixtouchlinks.on('tap', function(e) {
				// 	// 		me.hiddenFileInput.click();
				// 	// 		e.preventDefault();
				// 	// 	});
				// 	// }
				// },
				accept: function accept(file, done) {
					var dz = this;

					if (me.isVideo(file.type)) {
						// if(typeof console === 'object') { console.log('Dropzone.ACCEPT?',file, done); }
						done('this is an video');
						if (me.get('getVimeoUploadToken')) {
							me.get('getVimeoUploadToken')().then(function (candidate) {
								if (candidate) {
									var media = candidate.get('media');
									if (media && media.vimeo && media.vimeo.current_upload) {
										me.vimeoUpload(file, media.vimeo.current_upload);
									}
								}
							});
						}
					} else {
						if (me.get('getPictureUploadToken')) {
							me.get('getPictureUploadToken')().then(function (model) {
								if (model) {
									var id = model.get('id');
									dz.options.url = uploadBaseUrl + id + '/pics';
									done();
								}
							}, function () {
								done('error');
							});
						} else if (me.get('uploadModelId')) {
							dz.options.url = uploadBaseUrl + me.get('uploadModelId') + '/pics';
							done();
						} else {
							done('error');
						}
					}
				},
				dictDefaultMessage: '', // || this.t('upload.take_photo'),
				method: 'post',
				url: '/api/v1/picture/upload',
				paramName: 'file',
				autoProcessQueue: true,
				maxFiles: 1,
				acceptedFiles: acceptedFiles,
				clickable: '.dz-trigger',
				thumbnailWidth: 225
			});

			this.dz = Dropzone.forElement(dzSelector);

			this.dz.on('sending', function (file, xhr, formData) {
				me.sendAction('dz_BeforeUpload', file, xhr, formData);
			}).on('uploadprogress', function (file, progress, bytesSent) {
				controller.send('uploadProgress', file, progress, bytesSent);
			}).on('success', function (file, responseText, e) {
				controller.send('dz_UploadSuccess', responseText, file, e);
			}).on('error', function (file, xhr) {
				me.sendAction('dz_Error', file, xhr);
				controller.set('isProcessing', false);
			}).on('thumbnail', function (file, dataUrl) {
				$('.dropzone-preview img').attr('src', dataUrl);
			});
		},

		willDestroyElement: function willDestroyElement() {
			if (this.dz) {
				this.dz.disable();
				this.dz.destroy();
			}
		},

		isVideo: function isVideo(str) {
			var patt = new RegExp("video");
			return patt.test(str);
		}
	});
});