define('symbolsofpeace/services/lazy-video-providers/vimeo', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var RSVP = _ember.default.RSVP,
	    $ = _ember.default.$;
	exports.default = {
		apiUrl: function apiUrl(videoId) {
			return '//vimeo.com/api/oembed.json?url=http%3A//vimeo.com/' + videoId;
		},
		embedUrl: function embedUrl(videoId) {
			return '//player.vimeo.com/video/' + videoId;
		},
		thumbnailUrl: function thumbnailUrl(videoId) {
			var apiUrl = this.apiUrl(videoId);
			return new RSVP.Promise(function (resolve) {
				$.getJSON(apiUrl).then(function (res) {
					resolve(res.thumbnail_url);
				});
			});
		}
	};
});