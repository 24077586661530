define('symbolsofpeace/routes/privacy-policy', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseContentRoute.default.extend({

    docTitle: 'Privacy Policy'

  });
});