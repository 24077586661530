define('symbolsofpeace/routes/application', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _ember.default.Route.extend({
		i18n: _ember.default.inject.service(),

		/**
   * Load user model
   *
   * @param transition
   * @returns {boolean}
   */
		// 	beforeModel: function (transition) {
		//
		// 		//var t = this.store.unloadAll('user');
		//
		// 		var me = this,
		// 			controller = me.controllerFor('application');
		//
		// 		controller.set('languages',this.get('languages'));
		//
		// 		if ( controller.get('loginChecked') === false ) {
		//
		// 			// create globalstatestore
		// 			// me.get('store').createRecord('applicationstate', {
		// 			// 	id: 'globalstate',
		// 			// 	// lang: this.get('globals').get('srvParams.lang'),
		// 			// });
		//
		//
		// 			transition.abort();
		//
		// 			var transit = function() {
		// 				controller.set('loginChecked', true);
		//
		// //				reload the transition
		// 				var url = transition.intent.url;
		// 				if ( !url ) {
		// 					url = transition.targetName;
		// 				}
		//
		// 				me.transitionTo( url );
		// 			};
		//
		// 			// Change user to model
		//
		// 			$.get("/api/v1/auths/checkuser").then(function(a) {
		// 				if(a && a.user) {
		// 					controller.setUser(a.user);
		// 				}
		// 				transit();
		// 			}, function() {
		// 				transit();
		// 				return null;
		// 			}.bind(this));
		//
		// 			return false;
		// 		}
		// 	},
		//

		/**
   * categories
   */
		beforeModel: function beforeModel() {
			var controller = this.controllerFor('application');
			// var me = this;

			return this.get('store').findAll('category').then(function (categories) {
				controller.setCategories(categories);
				return categories;
			}, function () {
				return null;
			});
		},


		model: function model() {
			var controller = this.controllerFor('application');

			return this.get('store').queryRecord('user', {}).then(function (user) {
				var username = user.get('username');
				controller.setCurrentUser(user);
				console.log('Currently logged in as ' + username, controller.get('userIsLoggedIn'));
				return user;
			}, function () {
				controller.setCurrentUser(null);
				return null;
			});
		},

		afterModel: function afterModel() {
			var controller = this.controllerFor('application');

			return this.get('store').queryRecord('votingbasket', {}).then(function (votingbasket) {
				controller.setVotingbasket(votingbasket);
				return votingbasket;
			}, function () {
				// if(typeof console === 'object') { console.log('ERROR RMINE',t); }
				return null;
			});
		},


		/**
   * Languages
   * To add a new translation:
   * - add a new translation file inapp/app/locales, e.g. dk.js
   * - add translation the new language here
   * - add "Accept Terms" and "Privacy Policy" template file for your language in wow-app/app/templates/acceppt-terms, e.g. dk.hbs
   * - add language key in /sites/n7w/n7w_wallofwonders/src/N7W/WallOfWondersBundle/Services/LocaleHelper.php --> getLanguage
   * - add meta tags translation in /sites/n7w/n7w_wallofwonders/src/N7W/WallOfWondersBundle/Resources/views/Frontend/Global/_meta_mulitlang_description.html.twig
   * - add start-up translation in wow-app/public/_js/start-splash.js
   */
		languages: [
		// {lang: "العربية", id: 'ar'},
		// {lang: "deutsch", id: 'de'},
		{ lang: "english", id: 'en' }],

		supportedLangs: function supportedLangs() {
			var supportedLangs = [],
			    idx = 0;
			var languages = this.get('languages');

			for (var i in languages) {
				if (!languages.hasOwnProperty(i)) {
					continue;
				}
				supportedLangs[idx] = languages[i].id;
				idx++;
			}

			return supportedLangs;
		},

		/**
   * Get (and set) language through jsconfig.js on activation of the application
   */
		detectLanguage: function () {

			// UTIL.storage.checkStorage(ENV.APP.localStorageCacheVersion);
			//
			// var lang = 'en';
			// var browserLang = this.get('globals').get('srvParams.lang');
			//
			// var supportedLangs = this.supportedLangs();
			// var idx = $.inArray(browserLang,supportedLangs);
			//
			// if(idx >= 0) {
			// 	lang = supportedLangs[idx];
			// }
			//
			// var set = Ember.set;
			// var application = this.container.lookup('application:main');
			// set(application, 'locale', lang);

			this.set('i18n.locale', 'en');
			this.initServices();
			var me = this;

			setTimeout(function () {
				var controller = me.controllerFor('application');
				controller.hideStartSplash();
			}, 500);
		}.on('activate'),

		// afterModel: function(user) {
		// 	this.set('i18n.locale', user.get('locale'));
		// }

		/**
   * init foreign services
   */
		initServices: function initServices() {
			UTIL.socialConnect.initService('facebook');
			//UTIL.socialConnect.initService('twitter');
		},

		actions: {

			goTo: function goTo() {
				this.transitionTo.apply(this, arguments);
				return false;
			},

			/**
    * Application actions to show/remove modal overlay
    * @param name
    * @param model
    */
			showModal: function showModal(name, model) {
				if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
					console.log('Application.showModal', name, model);
				}
				this.render(name, {
					into: 'application',
					outlet: 'modal',
					controller: 'error-modal',
					model: model
				});
			},

			hideModal: function hideModal() {
				this.disconnectOutlet({
					outlet: 'modal',
					parentView: 'application'
				});
			},


			symbolQuickLook: function symbolQuickLook(symbol) {

				var sql = this.controllerFor('symbol-quick-look');
				var m = sql.get('model');
				// if (typeof console === 'object') {
				// 	console.log('symbolQuickLook.sql,m', sql, m);
				// }

				if (1 == 2 && m) {
					sql.set('model', symbol);
					// sql.sparentModelChanged();
				} else {
					this.render('symbol-quick-look', {
						into: 'application',
						outlet: 'symbol-quick-look',
						controller: 'symbol-quick-look',
						model: symbol
					});
				}

				return false;
			},

			hideSymbolQuickLook: function hideSymbolQuickLook(refresh) {
				// if(typeof console === 'object') { console.log('Application.hideSymbolQuickLook.refresh',refresh); }
				var appController = this.controllerFor('application');

				$('.pushover').remove();

				if (!refresh) {
					appController.set('_maskMediaTile', false);
					appController.set('_quickLookLoading', false);
				}

				this.disconnectOutlet({
					outlet: 'symbol-quick-look',
					parentView: 'application'
				});

				return false;
			},
			loading: function loading(transition) {
				// if(typeof console === 'object') { console.log('APP-LOADING',transition,originRoute); }
				var controller = this.controllerFor('application');
				controller.set('currentlyLoading', true);
				transition.promise.finally(function () {
					controller.set('currentlyLoading', false);
				});
			}
		}

	});
});