define("symbolsofpeace/locales/en/translations", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		// "some.translation.key": "Text for some.translation.key",
		//
		// "a": {
		//   "nested": {
		//     "key": "Text for a.nested.key"
		//   }
		// },
		//
		// "key.with.interpolation": "Text with {{anInterpolation}}"
		"title_hash": "#7peace",
		"title": "7 Symbols of Peace",
		"title_n7w": "New 7 Wonders",
		"privacy_policy": "Terms and Conditions",
		"categories": {
			"title": "Categories"
		},
		"category": {
			"title": "Category",
			"all": "All"
		},
		"nominate": {
			"title": "Nominate your symbol",
			"take_symbol": "What´s your symbol?",
			"tag_symbol": "#Tag your symbol",
			"import_from_below": "or import from below",
			"symbol": "Symbol",
			"tag": "Tag",
			"note": "Note",
			"sign_up": "Register",
			"import_library": "Your Library",
			"import_url": "Url",
			"import_youtube": "Youtube",
			"import_youtube_text": "paste a youtube link here",
			"import_vimeo": "Vimeo",
			"crop": "crop your image",
			"hashtag": "Hashtag",
			"hashtag_search": "Existing hashtags",
			"add_note": "Add a note",
			"additional_tags": "Additional Tags",
			"signup": "Join us",
			"nominate_go_on_or_delete_candidate": "You uploaded already an nomination, do you wish to go on with that or start new?",
			"you_nominated": "You nominated a symbol",
			"first_step": "Your first step for peace",
			"promote": "Now promote your symbol",
			"choose-thumbnail": "Choose video thumbnail"
		},
		"symbols": {
			"interesting": "Pick",
			"latest": "Latest",
			"mostLiked": "Most liked",
			"mostShared": "Most shared",
			"all": "All Symbols",
			"search": "Search",
			"my_symbols": "My symbols",
			"offline": "Offline",
			"delete_confirm": "Do you really want to delete this symbol?",
			"delete_reason": "Reason",
			"coose_thumpnail_hint": "Play video and pause it on the frame you'd like to use as a thumbnail<br />then press \"Create Thumbnail\""
		},
		"candidate": {
			"title": "Nomination",
			"share": "Share",
			"tweet": "Tweet",
			"google_plus": "Google+",
			"email": "E-Mail",
			"like": "Like",
			"additional_tags": "Additional Tags",
			"nominated_by": "Nominated by",
			"nominated_by_from": "from",
			"delete_confirm": "Do you really want to delete this nomination?",
			"poster_image": "Upload a poster image to your video",
			"change_reason": "Reason"
		},
		"comments": {
			"title": "Join the discussion",
			"shorttitle": "Comment",
			"noResult": "Be the first one and write a comment.",
			"answer": "Answer",
			"answers": "Answers",
			"message": "Your comment",
			"delete_confirm": "Do you really want to delete this comment?",
			"do_answer": "Answer",
			"change_reason": "Reason",
			"report": "Report",
			"report_confirm": "Do you really want to report this comment as inappropriate?"
		},
		"index": {
			"text": "Nominate your symbol of peace. Let’s share peace all over the world!"
		},
		"about": {
			"title": "About the campaign",
			"text": "<p>Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, litot Europa usa li sam vocabular.</p><p>Li lingues differe solmen in li grammatica, li pronunciation e li plu commun vocabules. Omnicos directe al desirabilite de un nov lingua franca: On refusa continuar payar custosi traductores.</p><p>At solmen va esser necessi far uniform grammatica, pronunciation e plu sommun paroles. Ma quande lingues coalesce, li grammatica del resultant lingue es plu simplic e regulari quam ti del coalescent lingues.</p><p>At solmen va esser necessi far uniform grammatica, pronunciation e plu sommun paroles. Ma quande lingues coalesce, li grammatica del resultant lingue es plu simplic e regulari quam ti del coalescent lingues.</p><p>At solmen va esser necessi far uniform grammatica, pronunciation e plu sommun paroles. Ma quande lingues coalesce, li grammatica del resultant lingue es plu simplic e regulari quam ti del coalescent lingues.</p><p>At solmen va esser necessi far uniform grammatica, pronunciation e plu sommun paroles. Ma quande lingues coalesce, li grammatica del resultant lingue es plu simplic e regulari quam ti del coalescent lingues.</p><p>At solmen va esser necessi far uniform grammatica, pronunciation e plu sommun paroles. Ma quande lingues coalesce, li grammatica del resultant lingue es plu simplic e regulari quam ti del coalescent lingues.</p>"
		},
		"voting_phases": {
			"title": "Voting phases"
		},
		"claims": {
			"share_peace": "Let's share peace",
			"whats_your_symbol_of_peace": "What’s your symbol of Peace?",
			"about_the_campaign": "About the Campaign",
			"got_better_pic_for_symbol": "Better photo or video for this?"
		},
		"buttons": {
			"nomimate": "Nominate your symbol",
			"ok": "OK",
			"load_more": "Load more",
			"save": "Save",
			"more": "More",
			"close": "Close",
			"next": "Next",
			"cancel": "Cancel",
			"signin": "Sign in",
			"signup": "Register",
			"logout": "Log-Out",
			"login_with_facebook": "Log in with Facebook",
			"login_with_twitter": "Log in with Twitter",
			"view_all_nomimations": "View all nominations",
			"take_picture": "Upload a picture or video",
			"upload_picture": "Upload a picture or video",
			"upload_poster": "Upload a picture",
			"import_from_below": "or import from below",
			"goon": "Go on",
			"start_new": "Start new",
			"crop": "Crop image",
			"choose-thumbnail": "Choose Thumbnail",
			"delete": "Delete",
			"edit": "Edit",
			"remove": "Remove",
			"tag": "Tag",
			"edit_tag": "Edit tag",
			"follow_us_on_facbook": "Follow us on facebook",
			"follow_us_on_twitter": "Follow us on twitter",
			"complete_your_profile": "Complete your profile",
			"delete_my_profile": "Remove my profile",
			"back": "Back",
			"send": "Send",
			"accept": "Please accept our",
			"why_7": "Why 7 symbols of peace?",
			"get_thumbnail": "Create Thumbnail",
			"previousFrame": "Previous",
			"nextFrame": "Next",
			"play": "Play",
			"pause": "Pause",
			"report": "Report",
			"vote": "Vote"
		},
		"user": {
			"access_denied": "Access denied",
			"profile_title": "Your Profile",
			"signin_title": "Sign in",
			"signup_title": "Register",
			"username": "Username",
			"e_mail": "e-Mail",
			"origin": "Country",
			"password": "Password",
			"choose_origin": "Choose your country",
			"picture": "Photo",
			"delete_confirm": "Do you really want to delete your account?",
			"lost_password": "Lost password",
			"lost_password_success": "Please check your email (and spam folder) for further instructions on how to reset your login password."
		},
		"share": {
			"fb": "Facebook",
			"fb_caption": "Your symbol of peace",
			"fb_description": "My symbol of peace. Let’s share peace all over the world! #7peace",
			"fb_vote_description": "I voted for this symbol of peace. Let’s share peace all over the world! #7peace",
			"fb_my_votes_description": "I voted for my symbols of peace. Let’s share peace all over the world! #7peace",
			"fb_name": "7 Symbols of Peace",
			"fb_published": "Your Post was published.",
			"sub_title": "Share with your friends,<br>join together on 7 Symbols of Peace",
			"title": "Share",
			"whatsapp": "Whatsapp",
			"link": "Link",
			"email_from": "From",
			"email_to": "To",
			"email_subject": "Subject",
			"email_text": "Your Text",
			"email_to_placeholder": "Separate several addresses with comma",
			"email_successfully_send": "Email successfully send "
		},
		"contact": {
			"title": "Contact & feedback",
			"thanks": "Thanks for your message!",
			"name": "Name",
			"email": "eMail",
			"subscribe_to_nl": "Stay in touch for the latest New 7 Wonders news",
			"subscribe_to_nl_hint": "Your email address will only be used to contact you about your account and for activity notifications on this site. If you sign up for our newsletter, we will send occasional updates about the 7 Symbols of Peace and New 7 Wonders campaigns. You can unsubscribe at any time by using the link we include in each newsletter. You can also ask for the deletion of your data from our systems at any time using the Contact form. For any other details, please read our",
			"newsletter_subscription": "Newsletter subscription",
			"newsletter_unsubscription": "Newsletter Unsubscription",
			"newsletter_subscription_text": "Thanks for subscribing to our newsletter.",
			"newsletter_unsubscription_text": "Your removal request was succesful. You will not be receiving any more newsletters from us. If you change your mind, you can select the corresponding checkbox in your profile or while registering an account.",
			"message": "Message"
		},
		"errors": {
			'signin': "Invalid username or password",
			'signup': "Invalid username, email or password",
			"userprofile": "Save failed! Please recheck all the fields",
			"save_url": "Save failed. Please review your url",
			"save_youtube": "Save failed. Please review your youtube url",
			"nominate_tag": "Your tag cannot be accepted: It was either listed as inappropriate, or contains invalid characters (only letters and numbers are allowed)",
			"nominate_notes": "Save failed! Please try again later",
			"nominate_crop": "Save failed! Please try again later",
			"nominate_choose-thumbnail": "Save failed! Please try again later",
			"report_comment-thumbnail": "Report failed! Please try again later",
			"edit_comment": "Save failed! Please try again later",
			"upload_error": "Coudn´t upload your picture, please try again",
			"delete_candidate": "Coundn´t delete this nomination, you have no rights to do that",
			"delete_comment": "Coundn´t delete this comment, you have no rights to do that",
			"delete_symbol": "Coundn´t delete this symbol, you have no rights to do that",
			"username": "This username is invalid",
			"email": "This eMail is invalid",
			"error_fields": "An error occured, please correct the fields",
			"password": 'Your password must be at least 6 characters long, contain 1 lower and upper case letter and 1 number',
			"define_hashtag": 'You need to enter a tag before saving',
			"hashtag_length": 'Your hashtag must be at least 3 characters long',
			"notes_length": 'Your note must be at least 140 characters long',
			"comment_length": 'Your comment must be at least 2 characters long',
			"contact": "Submit failed, please review all fields. Please tell us your eMail, so that we can contact you",
			"aceppted_terms": "Please accept our terms and conditions",
			"aceppted_email_agreement": "Please accept the terms",
			"lost_password": "We could not verify your username or e-Mail. Please check, if it is correct",
			"register": "You need to be <a class='underline' href='/user-signin'>logged in</a> or <a class='underline' href='/user-signup'>registered</a> to send comments",
			"votingbasket_login": "Please <a class='underline' href='/user-signin'>log in</a> or <a class='underline' href='/user-signup'>register</a> in order to vote",
			"votingbasket_submit_error": "This voting is unconfirmend. Please look at your eMails and click on the link inside it to confirm this voting."
		},
		"votingbasket": {
			"title": "Vote",
			"howto": "How to<br/>At vero eos et accusam et justo duo dolores et ea rebum.",
			"youvotedfor": "You voted for",
			"you_allready_voted_for": "You already voted for",
			"override_your_voting": "Do you want to overwrite your Voting?",
			"email_agreement": "AGREE TO TERMS"
		},
		"voting": {
			"title": "Votes",
			"my_votes": "My 7 Symbols",
			"thanks": "Thanks for your voting!",
			"trend": "Voting trend",
			"add": "Add",
			"thanksforvotingtitle": "Thanks for voting!",
			"thanksforvoting": "We have sent you a confirmation link via email. Please click on that link to confirm your votes!"
		},
		"shortstrings": {
			"in": "in"
		}
	};
});