define('symbolsofpeace/components/comment-reply', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		submitFailed: false,
		defaultError: 'Submit failed.',
		error: 'Submit failed.',
		isProcessing: false,
		disabled: false,
		min_length: 2,

		init: function init() {
			this._super.apply(this, arguments);
			this.setProperties({
				submitFailed: false,
				isProcessing: false,
				disabled: false,
				userFailed: false
			});
		},

		checkField: function checkField(input) {
			if (input && input.length) {
				var val = input.val().trim();
				if (val.length < this.min_length) {
					this.set('lengthFailed', true);
					return false;
				}
			}
			return true;
		},

		higherParentCounter: function higherParentCounter() {
			var parent = this.get('parent');
			var comments = parent.get('counters.comments');
			if (parent && typeof comments !== 'undefined') {
				parent.set('counters.comments', comments + 1);
			}
		},


		actions: {
			reply: function reply(e) {
				var _this = this;

				var key = e.keyCode;
				var t = $(e.target);
				if (key === 13) {
					var v = t.val();

					if (!this.get('appController').get('userIsLoggedIn')) {
						return this.set('userFailed', true);
					}

					if (!this.checkField(t)) {
						return false;
					}

					var controller = this.get('controller');

					var comment = controller.get('store').createRecord('comment', {
						text: v.trim(),
						parent_type: this.get('parent_type'),
						parent_id: this.get('parent_id')
					});

					this.setProperties({
						error: this.get('defaultError'),
						submitFailed: false,
						lengthFailed: false,
						userFailed: false,
						disabled: true,
						isProcessing: true
					});

					return comment.save({ adapterOptions: { parent_type: this.get('parent_type') } }).then(function (c) {

						UTIL.tracker.track('event', {
							category: 'comment',
							action: 'new',
							label: 'New comment',
							value: 1
						});

						_this.get('setReply')(c);

						_this.setProperties({
							submitFailed: false,
							userFailed: false,
							disabled: false,
							isProcessing: false
						});

						t.val('');
						t.trigger('autoresize');
						_this.$().find('label').removeClass('active');

						_this.higherParentCounter();

						return c;
					}, function (e, b) {
						if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
							console.log('Comment Error', e, b);
						}
						var errorMsg = 'Save failed. Please try again later';
						if ((typeof e === 'undefined' ? 'undefined' : _typeof(e)) === 'object') {
							errorMsg = _this.get('appController').getErrors(e, true);
						}
						_this.setProperties({
							error: errorMsg,
							submitFailed: true,
							userFailed: false,
							disabled: false,
							isProcessing: false
						});
						return null;
					});
				}
			}
		}
	});
});