define('symbolsofpeace/components/comments-wrapper', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		classNames: ['comment-wrapper'],
		reloadAnswers: 0,
		noResult: false,

		actions: {
			setReply: function setReply(comment) {
				var reloadAnswers = this.get('reloadAnswers') + 1;
				if (comment) {
					reloadAnswers = comment;
				}
				this.set('pushComment', comment);
				this.set('reloadAnswers', reloadAnswers);
			},
			setNoResult: function setNoResult(a) {
				this.set('noResult', a);
			}
		}
	});
});