define('symbolsofpeace/routes/votes-of', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		docTitle: 'Votes',

		/**
   *
   * /api/v1/votingbaskets/id
   * @param params
   * @param transition
   * @returns {*|Promise.<TResult>}
   */
		model: function model(params) {

			var me = this;
			var tag = params.basket_id;

			return this.get('store').findRecord('votingbasket', tag).then(function (votingbasket) {
				return votingbasket;
			}, function () {
				me.transitionTo('symbols');
				return null;
			});
		}

	});
});