define('symbolsofpeace/routes/symbol/notes', ['exports', 'ember', 'symbolsofpeace/routes/symbol/base-symbol-candidate-route'], function (exports, _ember, _baseSymbolCandidateRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseSymbolCandidateRoute.default.extend({

		docTitle: 'Candidate Notes',

		afterModel: function afterModel() {
			this._super.apply(this, arguments);
			var symbolController = this.controllerFor('symbol');
			symbolController.set('isEditor', 'notes');
		},

		renderTemplate: function renderTemplate(controller, model) {
			this.render('nominate/notes', {
				model: model,
				controller: controller
			});
		},


		hideMessages: _ember.default.on('deactivate', function () {
			this._super.apply(this, arguments);
			var controller = this.controllerFor('symbol.notes');
			controller.setProperties({
				isProcessing: false,
				submitFailed: false
			});
		})
	});
});