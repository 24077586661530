define('symbolsofpeace/components/mdc-chips', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		chips: null,
		value: null,
		hashtags: [],
		autoTag: true,
		stopBlur: false,

		hasChips: false,
		_checkHasChips: function _checkHasChips() {
			var data = this.chips.material_chip('data');
			if (data.length) {
				this.set('hasChips', true);
				return true;
			}
			this.set('hasChips', false);
			return false;
		},

		hashtagSearch: _ember.default.computed('hashtags', function () {
			var data = this.get('hashtags');
			if (data && data.length) {
				return true;
			}
			return false;
		}),

		query: '',

		colorizeChip: _ember.default.observer('colorize', function () {
			this.colorizeTags();
			return this.get('colorize');
		}),

		didInsertElement: function didInsertElement() {

			var $element = this.$(),
			    me = this,
			    options = {};

			this.chips = $element.find('.chips');

			var values = this.get('value');
			if (values && (typeof values === 'undefined' ? 'undefined' : _typeof(values)) !== 'object') {
				values = [values];
			}

			if (values) {
				for (var i = 0; i < values.length; i++) {
					if (_typeof(values[i]) !== 'object') {
						values[i] = { tag: values[i] };
					}
				}
				options.data = values;
			}

			options.placeholder = this.get('placeholder');
			options.secondaryPlaceholder = this.get('secondaryPlaceholder');

			this.chips.material_chip(options);

			if (values) {
				if (this.chips.find('.chip').length) {
					me.initMDCChips();
				} else {
					UTIL.waitForElement('.chips .chip', function () {
						me.initMDCChips();
					}, 0);
				}
			}

			if (this.get('search')) {
				if (this.chips.find('.input').length) {
					if (me.get('label')) {
						me.makeLabel();
					}
					me.initSearch();
					me.fixInputSize();
				} else {
					UTIL.waitForElement('.chips .input', function () {
						me.initSearch();
					}, 0);
				}
			}

			this.setEvents();

			// save initial tags
			me.sendAction('saveTags', me.getData());
			me._checkHasChips();
		},


		setEvents: function setEvents() {

			var me = this;

			this.chips.on('chip.add', function (e, chip) {
				me.fixInputSize();
				var data = me.chips.material_chip('data');
				if (me.get('isSingle') && data.length && data.length > 1) {
					me.chips.deleteChip(0, me.chips);
				}
				me.sendAction('add', e, chip);
				me.sendAction('saveTags', me.getData());
				me.replaceCloseButtons();
				me.colorizeTags();
				me._checkHasChips();
			});

			this.chips.on('chip.delete', function (e, chip) {
				me.sendAction('delete', e, chip);
				me.sendAction('saveTags', me.getData());
				me.fixInputSize();
				me._checkHasChips();
			});

			this.chips.on('chip.select', function (e, chip) {
				// you have the selected chip here
				me.sendAction('select', e, chip);
				me.fixInputSize();
			});
		},

		getData: function getData() {

			var data = this.chips.material_chip('data');
			if (this.get('isSingle') && data.length) {
				data = data[0].tag;
			} else {
				if (data.length) {
					var nc = [];
					for (var i = 0; i < data.length; i++) {
						nc[i] = data[i].tag;
					}
					data = nc;
				} else {
					data = [];
				}
			}

			return data;
		},

		getLabel: function getLabel() {
			return this.chips.find('label');
		},

		activateLabel: function activateLabel() {
			var label = this.getLabel();
			if (!label.hasClass('active')) {
				label.addClass('active');
			}
		},

		deactivateLabel: function deactivateLabel() {
			var label = this.getLabel();
			var hasFocus = this.$().find('.chips.focus');
			if (!hasFocus.length && label.hasClass('active')) {
				label.removeClass('active');
			}
		},

		/**
   * create an label tag instead of an placeholder...
   */
		makeLabel: function makeLabel() {
			var me = this;
			var data = me.chips.material_chip('data');
			var input = this.chips.find('.input');
			var icon = this.get('labelIcon');
			var className = 'chips-label';
			if (data.length) {
				className += ' active';
			}
			var label = '<label class="' + className + '">' + me.get('label');
			if (icon) label += ' ' + icon;
			label += '</label>';
			input.closest('div').append(label);

			input.off('focus').on('focus', function () {
				me.activateLabel();
			});

			// input.off('blur').on('blur', function() {
			// 	me.activateLabel();
			// 	var data = me.chips.material_chip('data');
			// 	if(!data.length) {
			// 		me.deactivateLabel();
			// 	}
			// });
		},

		/**
   * Fix input size (.input)
   * in order to have a pointer event on the input element
   * in oder to show always the input element: set min-height on conatainer (.chips)
   *
   * @param f
   */
		fixInputSize: function fixInputSize(f) {

			var container = this.chips;
			// var containerMinHeight = 44;
			var completeWidth = 0;
			// var containerHeight = 30;
			var input = container.find('.input');
			var l = container.width();

			var chips = this.chips.find('.chip');
			var hc = 0,
			    sHc = 0;
			for (var i = 0; i < chips.length; i++) {
				sHc = $(chips[i]).outerWidth() + 10;
				hc = hc + sHc;
			}

			completeWidth += hc;

			var nw = Math.floor(l - hc);
			if (nw < 120) {
				nw = l;
			}

			if (f) {
				nw = f;
			}

			completeWidth += nw;

			if (completeWidth === 0) {
				completeWidth = 1;
			}

			var ml = Math.ceil(completeWidth / l);
			// containerMinHeight = ml * containerMinHeight;

			if (ml > 1) {
				input.css('cssText', 'width: ' + l + 'px !important;position: relative;');
				// this.chips.css('min-height', '45px');
			} else {
				input.css('cssText', 'width: ' + nw + 'px !important;');
				// this.chips.css('min-height', containerMinHeight + 'px');
			}
		},

		getInput: function getInput() {
			return this.chips.find('input');
		},

		getValue: function getValue() {
			var i = this.chips.find('input');
			return i.val();
		},

		_onBlur: function _onBlur() {
			var data,
			    me = this;

			var val = this.getValue();

			if (me.get('autoTag')) {
				data = me.chips.material_chip('data');
				var ut = UTIL.user.isDevice ? val.length > 0 : !data.length && val.length > 0;
				val = me.toHashTag(val);
				var hasFocus = this.getInput().is(":focus");

				// On delete, no autoinsert
				if (me.get('stopAutoTag')) {
					ut = false;
					me.set('stopAutoTag', false);
				}

				if (ut) {
					me.send('chooseTag', val, false);
					data = me.chips.material_chip('data');
					if (data.length === 0 && !hasFocus) {
						me.deactivateLabel();
					}
				} else {
					data = me.chips.material_chip('data');
					if (data.length === 0 && !hasFocus) {
						me.deactivateLabel();
					}
				}
			}
		},

		initSearch: function initSearch() {
			var me = this;

			if (me.get('autoTag')) {
				this.chips.find('input').off('blur').on('blur', function () {

					setTimeout(function () {

						if (me.get('stopBlur')) {
							me.set('stopBlur', false);
							return false;
						}

						me._onBlur();
					}, 250);
				});
			}

			if (this.chips.find('.input').length) {

				this.chips.find('.input').off('keyup').on('keyup', function () {
					me.searchHashtag();
				});

				var self = this.chips;
				var SELS = this.chips.SELS;

				// Click on delete icon in chip.
				self.$document.off('click.chips-delete', SELS.CHIPS + ' ' + SELS.DELETE).on('click.chips-delete', SELS.CHIPS + ' ' + SELS.DELETE, function (e) {
					me.set('stopAutoTag', true);
					var $target = $(e.target);
					var $chips = $target.closest(SELS.CHIPS);
					var $chip = $target.closest(SELS.CHIP);
					e.stopPropagation();
					self.deleteChip($chip.index(), $chips);
					$chips.find('input').focus();
				});

				if (this.get('camelcase')) {
					// var self = this.chips;
					// var SELS = this.chips.SELS;

					// THIS IS A COPY OF THE ORIGINAL CODE!!
					self.$document.off('keydown.chips-add', SELS.CHIPS + ' ' + SELS.INPUT).on('keydown.chips-add', SELS.CHIPS + ' ' + SELS.INPUT, function (e) {
						var $target = $(e.target);
						var $chips = $target.closest(SELS.CHIPS);
						var chipsLength = $chips.children(SELS.CHIP).length;

						// enter
						if (13 === e.which) {
							var val = me.toHashTag($target.val());
							$target.val(val);

							// Override enter if autocompleting.
							if (self.hasAutocomplete && $chips.find('.autocomplete-content.dropdown-content').length && $chips.find('.autocomplete-content.dropdown-content').children().length) {
								return;
							}

							e.preventDefault();

							self.addChip({ tag: val }, $chips);
							$target.val('');

							return;
						}

						// delete or left
						if ((8 === e.keyCode || 37 === e.keyCode) && '' === $target.val() && chipsLength) {
							e.preventDefault();
							self.selectChip(chipsLength - 1, $chips);
							$target.blur();
							return;
						}
					});
				}

				me.focusFirstField('chips', me.chips);
				// setTimeout(function() {
				// 	me.focusFirstField('chips',me.chips);
				// },100);
			}
		},

		initMDCChips: function initMDCChips() {
			this.replaceCloseButtons();
			this.colorizeTags();
		},

		searchHashtag: function searchHashtag() {

			var me = this;

			if (this.keydownTimeout) {
				clearTimeout(this.keydownTimeout);
			}

			this.keydownTimeout = setTimeout(function () {
				me.r_searchHashtag();
			}, 250);
		},

		r_searchHashtag: function r_searchHashtag() {
			var input = this.chips.find('.input');
			var hashtag = input.get(0).value;
			var me = this;
			this.set('query', hashtag);

			if (!hashtag || hashtag.length === 0) {
				me.set('hashtags', []);
				return true;
			}

			$.get(this.get('search'), {
				q: hashtag
			}).then(function (r) {

				if (r && r.data && r.data.length) {
					me.set('hashtags', r.data);
				} else {
					me.set('hashtags', []);
				}
			}, function () {
				me.set('hashtags', []);
			});
		},

		replaceCloseButtons: function replaceCloseButtons() {
			var closeButtons = this.chips.find('.chip i');
			closeButtons.html('').addClass('icon').addClass('icon-close');
		},

		colorizeTags: function colorizeTags() {
			if (this.get('colorize')) {
				var tags = this.chips.find('.chip');
				tags.removeClass(function (index, className) {
					return (className.match(/(^|\s)pace_\S+/g) || []).join(' ');
				});
				tags.addClass(this.get('colorize'));
			}
		},

		willDestroyElement: function willDestroyElement() {
			this.chips.find('.input').off('keyup');
		},

		normalizeHashtag: function normalizeHashtag(str) {
			str = str.replace("#", "");
			return str;
		},

		toHashTag: function toHashTag(str) {

			str = this.normalizeHashtag(str);

			return str.replace(/\s(.)/g, function ($1) {
				return $1.toUpperCase();
			}).replace(/\s/g, '').replace(/^(.)/, function ($1) {
				return $1;
			});
		},

		actions: {
			searchHashTags: function searchHashTags() {
				this.searchHashtag();
			},
			chooseTag: function chooseTag(tag, noBlur) {
				var me = this;
				this.set('stopBlur', noBlur);
				me.chips.addChip({ tag: tag }, me.chips);
				if (me.get('search')) {
					me.chips.find('input').val('');
					me.r_searchHashtag();
				}
			}
		}

	});
});