define('symbolsofpeace/app', ['exports', 'ember', 'symbolsofpeace/resolver', 'ember-load-initializers', 'symbolsofpeace/config/environment'], function (exports, _ember, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Ember.MODEL_FACTORY_INJECTIONS = true;

  var App = _ember.default.Application.extend({
    serverVariablesService: _ember.default.inject.service('serverVariables'),
    version: _ember.default.computed.reads('serverVariablesService.version'),
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  // fastdom.onError = function(error) {
  // 	if(typeof console === 'object') { console.log('FASTDOM error!',error); }
  // };

  exports.default = App;
});