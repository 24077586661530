define('symbolsofpeace/components/category-symbol-headline', ['exports', 'symbolsofpeace/components/base-component', 'ember'], function (exports, _baseComponent, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		color: _ember.default.computed('symbol.category.color', function () {
			return 'pace_' + this.get('symbol.category.color');
		}),

		title: _ember.default.computed('symbol.category.title', function () {
			return this.get('symbol.category.title');
		})

		// init() {
		// 	this._super(...arguments);
		// 	let symbol = this.get('symbol');
		//
		// 	// var appController = this.get('appController');
		// 	// let categories = appController.getCategories();
		// 	// this.set('categories',categories);
		// }

	});
});