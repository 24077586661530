define('symbolsofpeace/components/is-my-candidate', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		isMyCandidate: _ember.default.computed('candidate.id', 'appController.user.id', function () {
			return this.get('appController').isMyCandidate(this.get('candidate'));
		}),

		profileCompleted: _ember.default.computed('appController.user', function () {
			var completed = false;
			var user = this.get('appController').get('user');
			if (user) {
				if (user.get('country.code') && user.get('pic') && user.get('username')) {
					completed = true;
				}
			}
			return completed;
		})

	});
});