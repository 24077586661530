define('symbolsofpeace/components/swiper-container', ['exports', 'ember', 'symbolsofpeace/templates/components/swiper-container'], function (exports, _ember, _swiperContainer) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = _ember.default.Component,
	    computed = _ember.default.computed,
	    observer = _ember.default.observer,
	    on = _ember.default.on,
	    run = _ember.default.run,
	    $ = _ember.default.$;


	var swiperParameters = [
	// Keyboard / Mousewheel
	'keyboardControl', 'mousewheelControl', 'mousewheelForceToAxis', 'mousewheelInvert', 'mousewheelReleaseOnEdges'];

	exports.default = Component.extend({
		layout: _swiperContainer.default,
		classNames: ['swiper-container'],
		swiper: false,

		swiperOptions: computed.apply(undefined, ['pagination', 'loop', 'vertical', 'onlyExternal', 'effect'].concat(swiperParameters, [function () {
			var _this = this;

			var options = {};
			var me = this;

			swiperParameters.forEach(function (parameter) {
				if (_this.get(parameter)) {
					options[parameter] = parameter;
				}
			});

			if (this.get('pagination')) {
				options.pagination = typeof this.get('pagination') === 'boolean' ? '#' + this.get('elementId') + ' > .swiper-pagination' : this.get('pagination');
				options.paginationClickable = true;
			}

			if (this.get('observer')) {
				options.observer = this.get('observer');
			}

			if (this.get('nextButton')) {
				options.nextButton = this.get('nextButton');
			}

			if (this.get('prevButton')) {
				options.prevButton = this.get('prevButton');
			}

			if (this.get('navigation')) {
				options.nextButton = '.swiper-button-next';
				options.prevButton = '.swiper-button-prev';
			}

			if (this.get('loop')) {
				options.loop = true;
			}

			if (this.get('initialSlide')) {
				options.initialSlide = this.get('initialSlide');
			}

			// Disables swiping
			if (this.get('followFinger')) {
				options.followFinger = false;
			}

			if (typeof this.get('simulateTouch') === 'boolean') {
				options.simulateTouch = this.get('simulateTouch');
			}

			// disable all user interactions
			if (this.get('onlyExternal')) {
				options.onlyExternal = true;
			}

			if (this.get('vertical')) {
				options.direction = 'vertical';
			}

			if (this.get('slidesPerGroup')) {
				options.slidesPerGroup = this.get('slidesPerGroup');
			}

			if (this.get('slidesPerView')) {
				options.slidesPerView = this.get('slidesPerView');
			}

			if (this.get('slidesPerColumn')) {
				options.slidesPerColumn = this.get('slidesPerColumn');
			}

			if (this.get('spaceBetween')) {
				options.spaceBetween = this.get('spaceBetween');
			}

			if (this.get('centered')) {
				options.centeredSlides = true;
			}

			if (this.get('freeMode')) {
				options.freeMode = true;
			}

			if (this.get('speed')) {
				options.speed = this.get('speed');
			}

			if (this.get('freeModeSticky')) {
				options.freeModeSticky = true;
			}

			if (this.get('grabCursor')) {
				options.grabCursor = true;
			}

			if (this.get('nested')) {
				options.nested = true;
			}

			if (this.get('breakpoints')) {
				options.breakpoints = this.get('breakpoints');
			}

			if (this.get('keyboardControl')) {
				options.keyboardControl = this.get('keyboardControl');
			}

			if (this.get('autoplay')) {
				options.autoplay = this.get('autoplay');
			}

			if (this.get('autoplayStopOnLast')) {
				options.autoplayStopOnLast = this.get('autoplayStopOnLast');
			}

			if (this.get('autoplayDisableOnInteraction')) {
				options.autoplayDisableOnInteraction = this.get('autoplayDisableOnInteraction');
			}

			if (this.get('watchSlidesProgress')) {
				options.watchSlidesProgress = true;
			}

			if (this.get('watchSlidesVisibility')) {
				options.watchSlidesVisibility = true;
			}

			// basic support for 'effect' API
			var effect = this.get('effect');
			if (effect && effect !== 'slide') {
				options.effect = this.get('effect');

				// look for effect configurations if an effect other than the default
				// 'slide' effect is given
				var effectConfigs = this.getProperties('fade', 'cube', 'overflow', 'flip');

				// add available effect configurations to options
				Object.keys(effectConfigs).forEach(function (c) {
					if (effectConfigs[c]) {
						options[c] = effectConfigs[c];
					}
				});
			}

			options.onSlideChangeEnd = this.slideChanged.bind(this);

			/**
    * hide pagination, if not needed (slides.length === 1)
    * @param swiper
    * @param paginationContainer
    */
			options.onPaginationRendered = function (swiper, paginationContainer) {
				var length = 0;
				if (swiper.slides && swiper.slides.length) {
					length = swiper.slides.length;
				}
				if (length > 1) {
					$('#' + me.get('elementId')).addClass('swiper--has__multiple-slides');
					$(paginationContainer).removeClass('hidden');
					$('#' + me.get('elementId')).find('.swiper-button-next').removeClass('hidden');
					$('#' + me.get('elementId')).find('.swiper-button-prev').removeClass('hidden');
				} else {
					$(paginationContainer).removeClass('hidden').addClass('hidden');
					$('#' + me.get('elementId')).find('.swiper-button-next').removeClass('hidden').addClass('hidden');
					$('#' + me.get('elementId')).find('.swiper-button-prev').removeClass('hidden').addClass('hidden');
				}
			};

			return options;
		}])),

		updateTriggered: observer('updateFor', function () {
			run.once(this, this.get('swiper').update);
		}),

		forceUpdate: function forceUpdate(updateTranslate) {
			this.get('swiper').update(updateTranslate === undefined ? false : updateTranslate);
			this.get('swiper').slideTo(this.get('currentSlide'));
		},
		slideChanged: function slideChanged(swiper) {
			var index = this.get('loop') ? $(swiper.slides).filter('.swiper-slide-active').attr('data-swiper-slide-index') : swiper.activeIndex;
			this.set('currentSlideInternal', index);
			this.set('currentSlide', index);

			if (this.get('onChange')) {
				this.sendAction('onChange', swiper.slides[swiper.activeIndex], swiper.activeIndex);
			}
			// if(typeof console === 'object') { console.log('slideChanged',index); }
		},


		currentSlideModified: observer('currentSlide', function () {
			var _this2 = this;

			run.later(this, function () {
				if (_this2.get('currentSlide') !== _this2.get('currentSlideInternal')) {
					var index = _this2.get('currentSlide');

					if (_this2.get('loop')) {
						var swiper = _this2.get('swiper');
						index = $(swiper.slides).filter('[data-swiper-slide-index=' + _this2.get('currentSlide') + ']').prevAll().length;
					}

					_this2.get('swiper').slideTo(index);
					_this2.set('currentSlideInternal', _this2.get('currentSlide'));
				}
			});
		}),

		// paginationNeeded: function() {
		//
		// 	var swiper = this.get('swiper');
		// 	if(typeof console === 'object') { console.log('swiper.slides,swiper.slides.length',swiper.slides,swiper.slides.length); }
		// },

		initSwiper: on('didInsertElement', function () {
			run.scheduleOnce('afterRender', this, function () {
				this.set('swiper', new Swiper('#' + this.get('elementId'), this.get('swiperOptions')));
				this.set('registerAs', this);
				if (this.get('afterSwiperInit')) {
					this.sendAction('afterSwiperInit', this);
				}
			});
		})
	});
});