define('symbolsofpeace/controllers/symbol/edit-tag', ['exports', 'symbolsofpeace/mixins/tag', 'symbolsofpeace/controllers/symbol/base-symbol-controller', 'ember'], function (exports, _tag, _baseSymbolController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseSymbolController.default.extend(_tag.default, {

		// colorizeHashtag: false,
		colorizeHashtag: _ember.default.computed('appController.useCategories', function () {
			var appController = this.get('appController');
			return !appController.useCategories;
		}),
		changeReason: true,

		newTag: null,
		newColor: _ember.default.computed('model.category.color,model.color', function () {
			var appController = this.get('appController');
			var color = null;
			if (appController.useCategories) {
				color = this.get('model.category.color');
			} else {
				color = this.get('model.color');
			}
			return color;
		}),

		newCategory: _ember.default.computed('model.category', function () {
			return this.get('model.category');
		}),

		tagFieldName: 'tag',
		tagFieldGetter: 'newTag',
		colorFieldName: 'color',
		colorFieldGetter: 'newColor',

		categoryFieldGetter: 'newCategory',

		mainTag: _ember.default.computed('model.tag', function () {
			var mainTag = this.get('model.tag');
			return mainTag;
		}),

		save: function save() {

			var me = this;
			var valid = true;
			var parentController = this.get('parentController');

			me.setProperties({
				submitFailed: false,
				hashtagFailed: false,
				hashtag_length: false
			});

			if (!this.getHashTagValue()) {
				valid = false;
			}

			if (valid) {

				me.setProperties({
					isProcessing: true,
					submitFailed: false
				});

				var data = { "symbol": {
						tag: this.get('newTag'),
						color: this.get('newColor'),
						category: this.get('newCategory'),
						change_reason: this.get('model.change_reason')
					} };

				var pTag = this.get('model.tag');

				if (pTag === this.get('newTag') && this.get('model.color') === this.get('newColor')) {
					return parentController.gotoRoute('parentSymbol');
				}

				// if(typeof console === 'object') { console.log('SAVE SYMBOL',data); }

				jQuery.ajax({
					method: 'PUT',
					url: '/api/v1/symbols/' + pTag,
					data: JSON.stringify(data),
					dataType: "json",
					contentType: "application/json"
				}).done(function (c) {

					me.setProperties({
						isProcessing: false
					});

					if ((typeof c === 'undefined' ? 'undefined' : _typeof(c)) !== 'object') {
						me.setProperties({
							isProcessing: false,
							submitFailed: true
						});
						return null;
					}

					var symbol = c.symbol;

					if ((typeof symbol === 'undefined' ? 'undefined' : _typeof(symbol)) === 'object' && parentController.get('model')) {
						var pTag = parentController.get('model.tag');
						if (pTag) {
							parentController.set('model.tag', symbol.tag);
							parentController.set('model.color', symbol.color);
						}
					}

					me.set('model.change_reason', '');

					UTIL.tracker.track('event', {
						category: 'Symbol',
						action: 'symbol-tag-saved',
						label: 'Symbol Tag saved',
						value: 1
					});

					parentController.gotoRoute('parentRemoveSymbol');

					return c;
				}).fail(function (e) {
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('error.symbol change failed', e);
					}
					me.setProperties({
						isProcessing: false,
						submitFailed: true
					});
					return null;
				});
			} else {
				me.setProperties({
					isProcessing: false
				});
			}
		}
	});
});