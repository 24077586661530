define('symbolsofpeace/components/nominate-headline-breadcrumb', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		actions: {

			goTo: function goTo() {
				var c = this.get('nominateController');
				c.transitionToRoute.apply(c, arguments);
				return false;
			}
		}

	});
});