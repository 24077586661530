define('symbolsofpeace/components/comment-view', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		className: ['comment-view__wrapper'],
		classNameBindings: ['childLevel'],

		allowReply: _ember.default.computed('model.level', function () {
			return this.get('model.level') < 3;
		}),

		childLevel: _ember.default.computed('model.level', function () {
			return 'comment-view__level' + this.get('model.level');
		})

	});
});