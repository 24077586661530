define('symbolsofpeace/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		username: _emberData.default.attr(),
		email: _emberData.default.attr(),
		password: _emberData.default.attr(),
		country: _emberData.default.attr('object', { defaultValue: {} }),
		loggedIn: _emberData.default.attr('boolean'),
		pic: _emberData.default.attr('object'),
		interactions: _emberData.default.attr('object'),
		admin: _emberData.default.attr(),
		// newsletter_confirmed: DS.attr('boolean'),
		newsletter_requested: _emberData.default.attr('boolean'),

		token: function () {
			return this.get('id');
		}.property('id'),

		isLoggedIn: function () {
			return this.get('username') ? true : false;
		}.property('username')

	});
});