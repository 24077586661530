define('symbolsofpeace/components/base-component', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Component.extend({

		focusFirstField: function focusFirstField(t, $element) {

			$element = $element || this.$();
			var fields = [];

			switch (t) {
				case 'chips':
					fields = $element.find('input');
					break;
				default:
					fields = $element.find('input[type=text],textarea');
			}

			if (fields.length) {
				$(fields[0]).focus().click();
			}
		}

	});
});