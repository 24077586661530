define('symbolsofpeace/routes/symbols', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		docTitle: 'Symbols'

		// model: function(params) {
		//
		// 	if(typeof console === 'object') { console.log('SymbolsRoute params',params); }
		// },

	});
});