define('symbolsofpeace/controllers/nominate/index', ['exports', 'symbolsofpeace/controllers/nominate/base-nominate-controller', 'ember'], function (exports, _baseNominateController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseNominateController.default.extend({

		isDevice: false,
		isProcessing: false,
		submitFailed: false,

		actionAreas: ['urlAction'],
		processings: ['isUrlProzessing'],

		activateChooseFile: false,

		activateUrlUpload: false,
		url: null,
		urlAction: 0,
		isUrlProzessing: false,
		urlError: null,

		activateYoutubeUpload: true,
		youtubeUrl: null,
		youtubeAction: 0,
		isYoutubeProzessing: false,
		youtubeError: null,

		showUploadProgress: false,
		uploadProgress: 0,
		hasCandidate: false,

		init: function init() {
			this._super.apply(this, arguments);
			// if(typeof console === 'object') { console.log('NominateIndex.init',true,this.get('model')); }
			if (UTIL.user.isDevice) {
				this.set('isDevice', true);
			}

			this.setProgress(0);
			this.set('hasCandidate', false);
		},


		disableProcessings: function disableProcessings() {
			var processings = this.processings;
			for (var i = 0; i < processings.length; i++) {
				this.set(processings[i], false);
			}
		},

		setProgress: function setProgress(progress) {
			var s = _ember.default.String.htmlSafe('width:' + progress + '%');
			if (!progress) {
				this.set('showUploadProgress', false);
				this.set('uploadProgress', s);
			} else {
				this.set('showUploadProgress', true);
				this.set('uploadProgress', s);
			}
		},

		actions: {
			goon: function goon() {
				var parentController = this.get('parentController');
				var candidate = parentController.getCandidate();

				var completedStep = parentController.getCompletedStep();
				if (completedStep) {
					parentController.gotoRoute(completedStep + 1);
				} else {

					if (candidate && candidate.get('media') && candidate.get('media.poster') && candidate.get('media.poster.url_base')) {
						parentController.gotoRoute('crop');
					} else {
						parentController.gotoRoute('tag');
					}
				}
			},
			startNew: function startNew() {
				var me = this;
				var parentController = this.get('parentController');
				var candidate = parentController.getCandidate();

				// if(typeof console === 'object') { console.log('candidate',this.get('model'),candidate); }

				candidate.destroyRecord().then(function () {
					parentController.setCompletedStep(0);
					parentController.storeCandidate(null);
					me.set('hasCandidate', false);
				}, function (e) {
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('candidate NOT DESTROYED', e);
					}
				});
			},
			openAction: function openAction(a) {
				var i = this.get(a);
				this.set(a, i + 1);
			},
			saveYoutubeUrl: function saveYoutubeUrl() {
				//youtubeError, youtubeUrl, isYoutubeProzessing
				var url = this.get('youtubeUrl');
				var me = this;
				var parentController = this.get('parentController');
				this.disableProcessings();
				this.set('isYoutubeProzessing', true);

				me.setProperties({
					isYoutubeProzessing: true,
					youtubeError: false
				});

				var candidate = this.get('store').createRecord('candidate', {
					media: {
						type: 'youtube-suggest',
						youtube: { url: url }
					}
				});

				return candidate.save({ adapterOptions: { youtubeSuggest: true } }).then(function (c) {

					parentController.storeCandidate(c);

					UTIL.tracker.track('event', {
						category: 'Candidate',
						action: 'youtube-suggest',
						label: 'New youtube suggestion',
						value: 1
					});

					me.set('isYoutubeProzessing', false);
					var i = me.get('youtubeAction');
					me.set('youtubeAction', i + 1);

					setTimeout(function () {
						me.set('youtubeUrl', '');
						parentController.gotoRoute('tag');
					}, 500);

					return c;
				}, function () {
					me.setProperties({
						isYoutubeProzessing: false,
						youtubeError: true
					});
					// var i = me.get('youtubeAction');
					// me.set('youtubeAction',(i+1));
					parentController.storeCandidate(null);
					return null;
				});
			},
			saveUrl: function saveUrl() {
				// eslint-disable-next-line no-unused-vars
				var url = this.get('url');
				var me = this;
				this.disableProcessings();
				this.set('isUrlProzessing', true);
				setTimeout(function () {
					me.set('isUrlProzessing', false);
					var i = me.get('urlAction');
					me.set('urlAction', i + 1);
				}, 1000);
			},


			dz_BeforeUpload: function dz_BeforeUpload(file, xhr, formData) {
				if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
					console.log('NominateIndex', file, xhr, formData);
				}
				return false;
				// var model = this.get('controllers.mosaic').get('model');

				// formData.append('mosaicId', model.get('id') );
			},

			// eslint-disable-next-line no-unused-vars
			uploadProgress: function uploadProgress(file, progress, bytesSent) {
				this.setProgress(progress);
			},

			dz_UploadSuccess: function dz_UploadSuccess(data) {

				var me = this,
				    parentController = this.get('parentController');

				if ((typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object' && data.candidate) {
					var candidate = parentController.getCandidate();
					candidate.set('media', data.candidate.media);

					me.setProperties({
						isProcessing: false,
						submitFailed: false
					});
					parentController.storeCandidate(candidate);
					UTIL.tracker.track('event', { category: 'Candidate', action: 'picture-upload-complete', label: 'Picture upload completed', value: 1 });
					me.setProgress(0);
					parentController.gotoRoute('crop');
					return candidate;
				} else {
					parentController.storeCandidate(null);
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('Error.photoUploaded with ', data);
					}
					me.setProperties({
						isProcessing: false,
						submitFailed: true
					});
				}
			},

			returnPictureUploadToken: function returnPictureUploadToken() {

				var me = this;
				var parentController = this.get('parentController');
				this.set('isProcessing', true);
				var candidate = this.get('store').createRecord('candidate', {
					media: {
						type: 'pic'
					}
				});

				return candidate.save({ adapterOptions: { vimeoUpload: true } }).then(function (c) {

					parentController.storeCandidate(c);

					UTIL.tracker.track('event', {
						category: 'Candidate',
						action: 'picture-upload',
						label: 'New picture upload',
						value: 1
					});
					return c;
				}, function () {
					me.setProperties({
						isProcessing: false
					});
					parentController.storeCandidate(null);
					return null;
				});
			},

			returnVimeoUploadToken: function returnVimeoUploadToken() {

				var me = this;
				var parentController = this.get('parentController');
				this.set('isProcessing', true);
				var candidate = this.get('store').createRecord('candidate', {
					// main_tag: 'chris-testet',
					media: {
						type: 'vimeo-upload'
					}
				});

				return candidate.save({ adapterOptions: { vimeoUpload: true } }).then(function (c) {

					// do we have an upload ticket?
					var current_upload = false;
					if (c) {
						var media = c.get('media');
						if (media && media.vimeo && media.vimeo.current_upload) {
							current_upload = true;
						}
					}

					parentController.storeCandidate(c);

					if (current_upload) {
						UTIL.tracker.track('event', {
							category: 'Candidate',
							action: 'vimeo-upload',
							label: 'New vimeo upload',
							value: 1
						});
						return c;
					}

					me.setProperties({
						isProcessing: false
					});

					var vimeo = c.get('media.vimeo');
					if (vimeo) {
						var pictures = false;
						if (vimeo.status === '3-ready' && pictures) {
							parentController.gotoRoute('crop');
						} else {
							parentController.gotoRoute('tag');
						}
					}

					return null;
				}, function () {
					me.setProperties({
						isProcessing: false
					});
					parentController.storeCandidate(null);
					return null;
				});
			},

			// eslint-disable-next-line no-unused-vars
			vimeoUploadComplete: function vimeoUploadComplete(ticket) {

				var me = this;
				var parentController = this.get('parentController');
				var candidate = parentController.getCandidate();

				if (candidate) {
					candidate.set('media.vimeo.uri', 'get');
					candidate.save({ adapterOptions: { vimeoUploadComplete: true } }).then(function (c) {
						// if(typeof console === 'object') { console.log('vimeoUploadComplete.SAVED',c); }
						me.setProperties({
							isProcessing: false
						});
						me.setProgress(0);
						parentController.storeCandidate(c);

						UTIL.tracker.track('event', { category: 'Candidate', action: 'vimeo-upload-complete', label: 'Vimeo upload completed', value: 1 });

						var vimeo = c.get('media.vimeo');
						if (vimeo) {
							var pictures = false;
							if (vimeo.status === '3-ready' && pictures) {
								parentController.gotoRoute('crop');
							} else {
								parentController.updateCompletedStep(1);
								parentController.gotoRoute('tag');
							}
						}

						return c;
					}, function () {
						me.setProperties({
							isProcessing: false
						});
						parentController.storeCandidate(null);
						return null;
					});
				}
			}

		}

	});
});