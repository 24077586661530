define('symbolsofpeace/controllers/privacy-policy', ['exports', 'symbolsofpeace/controllers/base-controller'], function (exports, _baseController) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseController.default.extend({

		termsTemplate: 'privacy-policy/en'

		// lang: function() {
		// 	this.setLang();
		// }.property('appController.model.lang'),
		//
		// setLang: function() {
		//
		// 	var applicationController = this.get('appController');
		// 	var lang = applicationController.get('model.lang');
		//
		// 	if(typeof lang !== 'undefined') {
		// 		this.set('termsTemplate','privacy-policy/'+lang);
		// 	} else {
		// 		this.set('termsTemplate','privacy-policy/en');
		// 	}
		// }


	});
});