define('symbolsofpeace/models/votingbasket', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		updated: _emberData.default.attr(),
		created: _emberData.default.attr(),
		submitted: _emberData.default.attr('boolean'),
		owner: _emberData.default.attr('object', { defaultValue: {} }),
		vote1: _emberData.default.attr('object'),
		vote2: _emberData.default.attr('object'),
		vote3: _emberData.default.attr('object'),
		vote4: _emberData.default.attr('object'),
		vote5: _emberData.default.attr('object'),
		vote6: _emberData.default.attr('object'),
		vote7: _emberData.default.attr('object')

	});
});