define('symbolsofpeace/routes/nominate', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		docTitle: 'Nominate',

		model: function model(params) {

			var me = this;
			var tag = params.tag;

			if (tag && tag === 'new') {
				return null;
			}

			return this.get('store').findRecord('symbol', tag).then(function (symbol) {
				return symbol;
			}, function () {
				me.transitionTo('nominate', 'new');
				return null;
			});
		}

	});
});