define('symbolsofpeace/routes/contact', ['exports', 'ember', 'symbolsofpeace/routes/base-content-route'], function (exports, _ember, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		docTitle: 'Contact',

		showStopper: _ember.default.on('deactivate', function () {
			this._super.apply(this, arguments);
			var controller = this.controllerFor('contact');
			controller.setProperties({
				submitFailed: false,
				isDisbabled: false,
				submitSuccess: false,
				isProcessing: false,
				message: ''
			});
		})
	});
});