define('symbolsofpeace/routes/my-symbols', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		docTitle: 'My Symbols',

		model: function model() {

			var controller = this.controllerFor('application'),
			    me = this;

			if (controller.getCurrentUser()) {
				return controller.getCurrentUser();
			}

			return this.get('store').queryRecord('user', {}).then(function (user) {
				return user;
			}, function () {
				me.transitionTo('user-signin');
				return null;
			});
		}
	});
});