define('symbolsofpeace/controllers/nominate/tag', ['exports', 'symbolsofpeace/controllers/nominate/base-nominate-controller', 'symbolsofpeace/mixins/tag'], function (exports, _baseNominateController, _tag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseNominateController.default.extend(_tag.default, {

    // colorizeHashtag: false,
    setCategory: false
  });
});