define('symbolsofpeace/routes/nominate/base-nominate-route', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		model: function model(params) {

			var c = this.paramsFor('nominate');
			var me = this;
			var nominateController = me.controllerFor('nominate');

			return this.get('store').findRecord('candidate', params.candidate_id).then(function (candidate) {
				if (c && c.tag) {
					nominateController.set('nominateTo', c.tag);
				}

				return candidate;
			}, function () {
				me.transitionTo('nominate');
				return null;
			});
		},

		afterModel: function afterModel(candidate) {
			var nominateController = this.controllerFor('nominate');
			nominateController.storeCandidate(candidate);
			var completedStep = nominateController.getCompletedStep();
			if (completedStep) {
				nominateController.updateCompletedStep(completedStep);
			}

			var target = '/nominate/' + nominateController.get('nominateTo');
			this.showCandidateCropDialog(candidate, target);
		}
	});
});