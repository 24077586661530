define('symbolsofpeace/controllers/symbol/tag', ['exports', 'symbolsofpeace/mixins/tag', 'symbolsofpeace/controllers/symbol/base-symbol-controller'], function (exports, _tag, _baseSymbolController) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseSymbolController.default.extend(_tag.default, {

		colorizeHashtag: false,
		setCategory: false,
		changeReason: true,
		isOnlineCandidate: true

	});
});