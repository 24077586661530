define('symbolsofpeace/adapters/candidate', ['exports', 'ember-data', 'symbolsofpeace/mixins/delete-with-payload', 'ember'], function (exports, _emberData, _deleteWithPayload, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTAdapter.extend(_deleteWithPayload.default, {

		namespace: 'api/v1',

		/**
   *
   * As in the case of store.query(), a query object can also be passed to store.queryRecord()
   * and is available for the adapter's queryRecord() to use to qualify the request.
   * However the adapter must return a single model object, not an array containing one element,
   * otherwise Ember Data will throw an exception.
   *
   * @param store
   * @param type
   * @param query
   * @returns {*}
   */
		// eslint-disable-next-line no-unused-vars
		queryRecord: function queryRecord(store, type, query) {
			return _ember.default.$.getJSON("/api/v1/candidates/current");
		}
	});
});