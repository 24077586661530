define('symbolsofpeace/components/user-signin', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		submitFailed: false,
		isProcessing: false,
		username: null,
		password: null,

		// lost password
		showLostPassword: true,
		modalAction: null,
		lp_username: null,
		isLPProcessing: false,
		lpError: true,
		lpSuccess: true,

		init: function init() {
			this._super.apply(this, arguments);
			var m = this.get('model');
			this.resetLostPassword();
			if (m) {
				this.username = this.get('model.username');
				this.password = this.get('model.password');
			}
		},

		didInsertElement: function didInsertElement() {
			Materialize.updateTextFields();
			this.focusFirstField();
		},

		signIn: function signIn() {

			var me = this;
			var applicationController = this.get('applicationController');
			var parent = this.get('parent');

			this.setProperties({
				submitFailed: false,
				isProcessing: true
			});

			var user = applicationController.get('store').createRecord('user', {
				username: this.get('username'),
				password: this.get('password')
			});

			return user.save({ adapterOptions: { signin: true } }).then(function (user) {

				UTIL.tracker.track('event', { category: 'User', action: 'signin', label: 'E-Mail Sign-In', value: 1 });
				applicationController.setCurrentUser(user);

				if (parent && parent === 'nominate') {

					me.sendAction('afterSave', function (co) {
						me.setProperties({
							submitFailed: false,
							isProcessing: false
						});

						applicationController.set('_createdNewCandidate', true);

						var parentController = me.get('parentController');
						if (parentController && typeof parentController.setCompletedStep === 'function') {
							parentController.setCompletedStep(0);
						}

						var model = me.get('model');
						if (model) {
							if (co && co.candidate && co.candidate.owner) {
								model.set('owner', co.candidate.owner);
							}
							applicationController.transitionToRoute('symbol.candidate', model.get('main_tag'), model.get('id'));
						} else {
							if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
								console.log('ERROR - no candidate found to transition to', model);
							}
						}
					});
				} else {
					me.sendAction('afterSave');
					me.setProperties({
						submitFailed: false,
						isProcessing: false
					});
					applicationController.transitionToRoute('user-profile');

					if (applicationController.get('votingPhase')) {
						var votingbasket = applicationController.getVotingbasket();
						applicationController.refreshVotes(votingbasket);
					}
				}
				return user;
			}, function () {
				me.setProperties({
					submitFailed: true,
					isProcessing: false
				});
				return null;
			});
		},

		resetLostPassword: function resetLostPassword() {
			this.setProperties({
				email: null,
				isLPProcessing: false,
				lpError: false,
				lpSuccess: false
			});
		},

		recoverPassword: function recoverPassword() {
			var me = this;

			this.resetLostPassword();

			me.setProperties({
				isLPProcessing: true
			});

			jQuery.ajax({
				type: 'POST',
				method: 'post',
				url: '/account/resetting/send-email',
				data: { username: this.get('lp_username') }
				// dataType: "json",
			}).done(function () {
				me.setProperties({
					isLPProcessing: false,
					lpError: false,
					lpSuccess: true
				});
			}).fail(function (e) {
				if (console && console.log) {
					console.log("recoverPassword error", e);
				}

				me.setProperties({
					isLPProcessing: false,
					lpError: true,
					lpSuccess: false
				});
			});
		},

		actions: {
			openAction: function openAction(a) {
				var i = this.get(a);
				this.set(a, i + 1);
			},


			signIn: function signIn() {
				return this.signIn();
			},

			recoverPassword: function recoverPassword() {
				return this.recoverPassword();
			}
		}
	});
});