define('symbolsofpeace/routes/my-votes', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		docTitle: 'Vote',

		/**
   *
   * /api/v1/votingbasket/mine
   * @param params
   * @param transition
   * @returns {*|Promise.<TResult>}
   */
		model: function model(params, transition) {

			// var me = this;
			var controller = this.controllerFor('application');

			var userIsLoggedIn = controller.get('userIsLoggedIn');
			var userHasVoted = controller.get('userHasVoted');

			if (!userIsLoggedIn) {
				return null;
			}

			if (!userHasVoted) {
				this.transitionTo('index');
			}

			var votingbasket = controller.getVotingbasket();

			if (votingbasket) {
				return votingbasket;
			}

			return this.get('store').queryRecord('votingbasket', {}).then(function (votingbasket) {
				// if(typeof console === 'object') { console.log('votingbasket',votingbasket); }
				controller.setVotingbasket(votingbasket);
				return votingbasket;
			}, function () {
				transition.abort();
				return null;
			});
		}

	});
});