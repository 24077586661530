define('symbolsofpeace/routes/nominate/signin', ['exports', 'symbolsofpeace/routes/nominate/base-nominate-route'], function (exports, _baseNominateRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseNominateRoute.default.extend({

		docTitle: 'Nominate Sign In',

		afterModel: function afterModel() {
			this._super.apply(this, arguments);
			var nominateController = this.controllerFor('nominate');
			nominateController.set('activeStep', 4);
		}
	});
});