define('symbolsofpeace/controllers/symbol/candidate', ['exports', 'symbolsofpeace/controllers/symbol/base-symbol-controller', 'ember'], function (exports, _baseSymbolController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseSymbolController.default.extend({

		newCandidate: true,
		lastResult: null,
		lastParam: 'all',
		limit: 50,
		offset: 0,
		page: 1,

		country: _ember.default.observer('model.owner.country', function () {
			var countryCode = this.get('model.owner.country.code'),
			    me = this;

			if (countryCode) {
				_ember.default.$.getJSON("/cc2country/" + countryCode + "/en").then(function (data) {
					if (data) {
						me.set('countryName', data.countryName);
						return data.countryName;
					}
					return null;
				});
			}
		}),

		actions: {

			/**
    *
    * Load (filtered) symbols
    *
    * @param param
    * @param limit
    * @param page
    * @param keepExisting
    * @returns {*|Promise.<TResult>}
    */
			commentFilter: function commentFilter(param, limit, page, keepExisting) {

				var me = this;
				var lastResults = this.get('lastResults');

				// var appController = this.get('appController');
				// appController.send('hideSymbolQuickLook',false);

				if (lastResults && keepExisting !== true) {
					me.set('isLoading', true);
				}

				param = param || this.lastParam;
				limit = limit || this.limit;
				page = page || this.page;

				var model = this.get('model');
				// let candidate = this.get('store').peekRecord('candidate', model.get('id'));

				return this.get('store').query('comment', {
					parent_type: 'candidates',
					parent_id: model.get('id'),
					c: param,
					limit: limit,
					page: page }).then(function (results) {

					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('filter.results', results);
					}

					model.set('comments', results.toArray());

					me.set('isLoading', false);
					var meta = results.get('meta');

					if (lastResults && keepExisting === true) {
						var r = _ember.default.A();
						r.pushObjects(lastResults.toArray());
						r.pushObjects(results.toArray());
						results = r;
					} else {
						me.set('stopperVisible', false);
					}

					me.set('lastResults', results);
					me.set('lastParam', param);
					return {
						query: param,
						meta: meta,
						results: results
					};
				}, function () {
					me.set('isLoading', false);
					return {
						query: param,
						meta: {
							last: true
						},
						results: lastResults
					};
				});
			}
		}
	});
});