define('symbolsofpeace/components/symbol-listing', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		classNames: ['media', 'media-square'],
		tagName: 'article',
		elementId: 'tile-',
		isLoading: false,

		/**
   * On pushover / quickview: mask every tile but the active one
   * --> disbaled for now!
   */
		mask: _ember.default.computed('appController._maskMediaTile', function () {
			var _maskMediaTile = this.get('appController._maskMediaTile');
			var symbol = this.get('symbol.tag');
			if (_maskMediaTile === symbol) {
				return false;
			}

			return false; // --> enable with: _maskMediaTile;
		}),

		quickLookLoading: _ember.default.computed('appController._quickLookLoading', function () {
			var q = this.get('appController._quickLookLoading');
			if (q === false) {
				this.set('isLoading', false);
			}
			return q;
		}),

		init: function init() {
			this._super.apply(this, arguments);
			this.set('elementId', 'tile-' + this.get('symbol.tag'));
		},

		actions: {

			symbolQuickLook: function symbolQuickLook(symbol) {

				var me = this;
				var appController = me.get('appController');

				appController.send('hideSymbolQuickLook', true);
				appController.set('_maskMediaTile', symbol.get('tag'));
				appController.set('_quickLookLoading', true);
				this.set('isLoading', true);
				setTimeout(function () {
					appController.send('symbolQuickLook', symbol);
				}, 100);
				return false;
			}
		}
	});
});