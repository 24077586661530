define('symbolsofpeace/mixins/tag', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _ember.default.Mixin.create({

		// colorizeHashtag: false,
		colorizeHashtag: _ember.default.computed('appController.useCategories', function () {
			var appController = this.get('appController');
			return !appController.useCategories;
		}),
		colors: ['purple', 'blue', 'lightblue', 'green', 'yellow', 'orange', 'red'],
		colorPrefix: 'pace_',

		setCategory: false,

		useCategories: function useCategories() {
			var appController = this.get('appController');
			return appController.useCategories;
		},

		// setCategory: Ember.computed('appController.useCategories',function () {
		// 	var appController = this.get('appController');
		// 	return (!appController.useCategories);
		// }),

		// newTag: null,
		// newColor: Ember.computed('model.category.color',function () {
		// 	return this.get(this.get('colorFieldGetter'));
		// }),
		newColor: _ember.default.computed('model.category.color,model.color', function () {
			var useCategories = this.useCategories();
			var color = this.get('model.color');
			if (useCategories) {
				color = this.get('model.category.color');
			}
			return color;
		}),

		tagFieldName: 'main_tag',
		tagFieldGetter: 'model.main_tag',
		colorFieldName: 'color',
		// colorFieldGetter: 'model.category.color',
		// colorFieldGetter: function() {
		// 	let useCategories = this.useCategories();
		// 	let colorFieldGetter = 'model.color';
		// 	if(useCategories) {
		// 		colorFieldGetter = 'model.category.color';
		// 	}
		// 	return colorFieldGetter;
		// },

		colorFieldGetter: _ember.default.computed('appController.useCategories', function () {
			var useCategories = this.useCategories();
			var colorFieldGetter = 'model.color';
			if (useCategories) {
				colorFieldGetter = 'model.category.color';
			}
			return colorFieldGetter;
		}),

		categoryFieldGetter: 'model.category',

		mainTag: _ember.default.computed('model.main_tag', 'parentController.nominateTo', function () {
			var mainTag = this.get('model.main_tag');
			var parentController = this.get('parentController');
			var nominateTo = parentController.get('nominateTo');

			if (!mainTag && nominateTo && nominateTo !== 'new') {
				mainTag = nominateTo;
			}

			return mainTag;
		}),

		hashColor: _ember.default.computed('newColor', function () {
			// if(typeof console === 'object') { console.log('hashColor',this.get(this.get('colorFieldGetter'))); }
			return this.get('colorPrefix') + this.get(this.get('colorFieldGetter'));
		}),

		submitFailed: false,
		hashtagFailed: false,
		isProcessing: false,

		init: function init() {
			this._super.apply(this, arguments);
			this.setProperties({
				submitFailed: false,
				hashtagFailed: false,
				stopAutoTag: false
			});
		},


		checkField: function checkField(f) {
			var v = this.get(f);
			if (!v || !v.length) return false;
			return true;
		},

		inputToModel: function inputToModel() {
			var input = $('.chips .input');
			if (input && input.length) {
				var val = input.val();

				if (val && val.length >= 3) {
					this.set(this.get('tagFieldGetter'), val);
				}
			}
		},

		getHashTagValue: function getHashTagValue() {

			var valid = true;
			var tagFieldGetter = this.get('tagFieldGetter');

			// check, if we have an hashtag in our textfield
			this.inputToModel();
			var v = this.get(tagFieldGetter);

			if (!v || !v.length) {
				valid = false;
				this.set('hashtagFailed', true);
			} else if (v && v.length < 3) {
				valid = false;
				this.set('hashtagLengthFailed', true);
			}

			return valid;
		},

		save: function save() {

			var me = this;
			var valid = true;
			var parentController = this.get('parentController');
			var tagFieldName = this.get('tagFieldName');

			me.setProperties({
				submitFailed: false,
				hashtagFailed: false,
				hashtag_length: false
			});

			if (!this.getHashTagValue()) {
				valid = false;
			}

			if (valid) {

				me.setProperties({
					isProcessing: true,
					submitFailed: false
				});

				// if(typeof console === 'object') { console.log('SAVE!!!',this.get('model').toJSON()); }

				this.get('model').save({ adapterOptions: { modelSave: true } }).then(function (c) {

					if (me.get('isOnlineCandidate') && parentController.get('model')) {
						var pTag = parentController.get('model.tag');
						if (pTag) {
							parentController.set('model.tag', c.get(tagFieldName));
						}
					}

					me.setProperties({
						isProcessing: false
					});

					parentController.storeCandidate(c);

					if (typeof parentController.updateCompletedStep === 'function') {
						parentController.updateCompletedStep(2);
					}

					UTIL.tracker.track('event', {
						category: 'Candidate',
						action: 'candidate-tag-saved',
						label: 'Candidate Tag saved',
						value: 1
					});

					if (me.get('isOnlineCandidate')) {
						parentController.gotoRoute('parentRemoveSymbolCandidate');
					} else {
						parentController.gotoRoute('notes');
					}
					return c;
				}, function () {
					me.setProperties({
						isProcessing: false,
						submitFailed: true
					});
					parentController.storeCandidate(null);
					return null;
				});
			} else {
				me.setProperties({
					isProcessing: false
				});
			}
		},

		setHashTagColor: function setHashTagColor(color) {
			var colorFieldGetter = this.get('colorFieldGetter');
			this.set(colorFieldGetter, color);
		},
		updateCategory: function updateCategory(category) {
			var categoryFieldGetter = this.get('categoryFieldGetter');
			this.set(categoryFieldGetter, category);
		},


		actions: {
			save: function save() {
				var me = this;

				me.setProperties({
					isProcessing: true,
					submitFailed: false
				});

				//wait for blur on input
				setTimeout(function () {
					me.save();
				}, 500);
			},
			setCategory: function setCategory(category) {
				if (category && (typeof category === 'undefined' ? 'undefined' : _typeof(category)) === 'object') {
					this.setHashTagColor(category.color);
					this.updateCategory(category);
				}
			},
			chipSave: function chipSave(data) {
				var tagFieldGetter = this.get('tagFieldGetter');
				this.set(tagFieldGetter, data);
			},
			chipAdded: function chipAdded(e, chip) {
				var tagFieldGetter = this.get('tagFieldGetter');
				this.set(tagFieldGetter, chip.tag);
			},
			colorizeHashtag: function colorizeHashtag(color) {
				var colorFieldGetter = this.get('colorFieldGetter');
				this.set(colorFieldGetter, color);
			}
		}

	});
});