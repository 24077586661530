define('symbolsofpeace/components/symbol-listing-stopper', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		tagName: '',

		/**
   * show stopper on second or last position
   */
		showStopper: _ember.default.computed('idx', function () {
			var me = this;
			var controller = this.get('controller');
			// var meta = this.get('resultMeta');


			if (controller) {
				var idx = this.get('idx');
				// if(typeof console === 'object') { console.log('STOPPER',controller.get('stopperVisible'),idx); }

				if (controller.get('stopperVisible')) return false;else {

					if (idx === 3) {
						// if(typeof console === 'object') { console.log('Symbol Listing Stopper IDX META!!',this.get('idx'), ((idx+1) / 4),  UTIL.isInt(((idx+1) / 4))); }
						this.set('aboutStopper', true);
						setTimeout(function () {
							me.resizeStopper();
						}, 150);
						return true;
					} else if (idx === 7) {
						// if(typeof console === 'object') { console.log('Symbol Listing Stopper IDX IS 7!!',this.get('idx'), ((idx+1) / 4),  UTIL.isInt(((idx+1) / 4))); }
						this.set('aboutStopper', false);
						setTimeout(function () {
							me.resizeStopper();
						}, 150);
						return true;
					} else if (idx > 8 && UTIL.isInt((idx + 1) / 8)) {
						// if(typeof console === 'object') { console.log('Symbol Listing Stopper IDX IS INT!!',this.get('idx'), ((idx+1) / 4),  UTIL.isInt(((idx+1) / 4))); }
						this.set('aboutStopper', false);
						setTimeout(function () {
							me.resizeStopper();
						}, 150);
						return true;
					}
				}
			} else {
				if (this.get('idx') === false) {
					// if(typeof console === 'object') { console.log('Symbol Listing Stopper IDX FALSE',this.get('idx')); }
					this.set('aboutStopper', false);
					setTimeout(function () {
						me.resizeStopper();
					}, 150);
					return true;
				} else if (this.get('idx') === 3) {
					// if(typeof console === 'object') { console.log('Symbol Listing Stopper IDX 3',this.get('idx')); }
					this.set('aboutStopper', false);
					setTimeout(function () {
						me.resizeStopper();
					}, 150);
					return true;
				}
				return false;
			}
			return false;
		}),

		resizeStopper: function resizeStopper() {
			var heightContainer = this.get('heightContainer');
			var h = $('body').find(heightContainer).height();
			if (h) {
				$('.media-listing-stopper').height(h);
			}
		},

		didInsertElement: function didInsertElement() {
			this.resizeStopper();
			$(window).on('resize', $.proxy(this.resizeStopper, this));
		},

		willDestroyElement: function willDestroyElement() {
			$(window).off('resize', $.proxy(this.resizeStopper, this));
			var controller = this.get('controller');
			if (controller) {
				controller.set('stopperVisible', false);
			}
		}

	});
});