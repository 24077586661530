define('symbolsofpeace/adapters/votingbasket', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _emberData.default.RESTAdapter.extend({

		namespace: 'api/v1',

		createRecord: function createRecord(store, type, snapshot) {
			if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
				console.log('snapshot', snapshot, snapshot.adapterOptions);
			}
			if (snapshot.adapterOptions.vote === true) {
				return this.vote(store, type, snapshot);
			}
		},

		vote: function vote(store, type, snapshot) {

			var data = {};
			var serializer = store.serializerFor('votingbasket');

			serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
		},

		/**
   *
   * As in the case of store.query(), a query object can also be passed to store.queryRecord()
   * and is available for the adapter's queryRecord() to use to qualify the request.
   * However the adapter must return a single model object, not an array containing one element,
   * otherwise Ember Data will throw an exception.
   *
   * @param store
   * @param type
   * @param query
   * @returns {*}
   */
		// eslint-disable-next-line no-unused-vars
		queryRecord: function queryRecord(store, type, query) {
			return _ember.default.$.getJSON("/api/v1/votingbasket/mine");
		}
	});
});