define('symbolsofpeace/components/mdc-helper', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		tagName: 'div',
		classNames: ['materialize-helper'],

		didInsertElement: function didInsertElement() {

			if (this.get('updateTextFields')) {
				Materialize.updateTextFields();
				$('textarea').trigger('autoresize');
			}

			if (this.get('focus_FirstField')) {
				this.focusFirstField();
			}

			if (this.get('check_FieldLength')) {
				this.ckeck_FieldLength();
			}
		},


		ckeck_FieldLength: function ckeck_FieldLength() {
			this.sendAction('check_FieldLength');
		}

	});
});