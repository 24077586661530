define('symbolsofpeace/adapters/user', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _emberData.default.RESTAdapter.extend({

		namespace: 'api/v1',

		// methodForRequest({ requestType }) {
		// 	if(typeof console === 'object') { console.log('USER.methodForRequest',requestType); }
		//
		// 	if (requestType === "createRecord") {
		// 		return "POST";
		// 	}
		//
		// 	return this._super(...arguments);
		// },
		//
		// urlForCreateRecord(modelName, snapshot) {
		// 	return '/login_check';
		// }


		createRecord: function createRecord(store, type, snapshot) {

			if (snapshot.adapterOptions.signup === true) {
				return this.signUp(store, type, snapshot);
			} else {
				return this.signIn(store, type, snapshot);
			}
		},

		signUp: function signUp(store, type, snapshot) {

			var data = {};
			var serializer = store.serializerFor('user');
			var newUser = {};

			serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

			if (data && data.user) {
				newUser._username = data.user.username;
				newUser._password = data.user.password;
			}

			// if(typeof console === 'object') { console.log('x '+message+': %o',p); }

			var data_str = 'fos_user_registration_form[email]=' + data.user.email;
			data_str += '&fos_user_registration_form[username]=' + data.user.username;
			if (data.user.newsletter_requested) {
				data_str += '&n7w-newsletter-opt-in=yes';
			}
			data_str += '&fos_user_registration_form[plainPassword][first]=' + data.user.password;
			data_str += '&fos_user_registration_form[plainPassword][second]=' + data.user.password;
			// eslint-disable-next-line no-undef
			data_str += '&g-recaptcha-response=' + grecaptcha.getResponse();

			var url = '/register/';

			return new _ember.default.RSVP.Promise(function (resolve, reject) {
				jQuery.ajax({
					type: 'POST',
					method: "POST",
					url: url,
					dataType: 'json',
					data: data_str
				}).then(function (data) {
					if ((typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object' && _typeof(data.user) === 'object') {
						data.user.loggedIn = true;
					}
					_ember.default.run(null, resolve, data);
				}, function (jqXHR) {
					jqXHR.then = null; // tame jQuery's ill mannered promises
					_ember.default.run(null, reject, jqXHR);
				});
			});
		},

		signIn: function signIn(store, type, snapshot) {
			var data = {};
			var serializer = store.serializerFor('user');
			var newUser = {};

			serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

			if (data && data.user) {
				newUser._username = data.user.username;
				newUser._password = data.user.password;
				// data.user._csrf_token = '';
				// data.user._submit = 'security.login.submit';
				// data.user._submit = 'Login';
				// data.user._remember_me = 'on';
			}

			return $.post("/login_check", newUser).then(function (user) {
				return user;
			}, function () {
				return null;
			});
		},

		/**
   *
   * As in the case of store.query(), a query object can also be passed to store.queryRecord()
   * and is available for the adapter's queryRecord() to use to qualify the request.
   * However the adapter must return a single model object, not an array containing one element,
   * otherwise Ember Data will throw an exception.
   *
   * @param store
   * @param type
   * @param query
   * @returns {*}
   */
		// eslint-disable-next-line no-unused-vars
		queryRecord: function queryRecord(store, type, query) {
			return _ember.default.$.getJSON("/api/v1/auths/checkuser");
		}
	});
});