define('symbolsofpeace/mixins/crop', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _ember.default.Mixin.create({

		posterUrl: _ember.default.computed('model.poster.url_base', function () {
			return this.get('model.poster.url_base');
		}),

		activateTab: null,
		activeTab: 0,
		gotoTab: null,
		changeTab: 0,

		isProcessing: false,

		tabs: [{
			title: 'Square',
			id: 'square_tab',
			icon: 'icon-quadrat'
		}, {
			title: 'Landscape',
			id: 'landscape_tab',
			icon: 'icon-landscape'
		}],

		getCropData: function getCropData(canvasData, cropBoxData, imageData) {

			var m = {
				cropX: (cropBoxData.left - canvasData.left) / imageData.width,
				cropY: (cropBoxData.top - canvasData.top) / imageData.height,
				cropWidth: cropBoxData.width / imageData.width,
				cropHeight: cropBoxData.height / imageData.height
			};

			if (m.cropX < 0) {
				m.cropX = 0;
			}
			if (m.cropY < 0) {
				m.cropY = 0;
			}
			if (m.cropWidth > 1) {
				m.cropWidth = 1;
			}
			if (m.cropHeight > 1) {
				m.cropHeight = 1;
			}

			return m;
		},

		saveCropData: function saveCropData(type, canvasData, cropBoxData, imageData) {

			var parentController = this.get('parentController');
			var candidate = this.get('model');

			var crops = candidate.get('media.poster.crops');
			var m = this.getCropData(canvasData, cropBoxData, imageData);

			if ((typeof crops === 'undefined' ? 'undefined' : _typeof(crops)) !== 'object') {
				crops = {};
			}

			crops[type] = m.cropX + '-' + m.cropY + '-' + m.cropWidth + '-' + m.cropHeight;
			candidate.set('media.poster.crops', crops);
			parentController.storeCandidate(candidate);
		},

		save: function save(canvasData, cropBoxData, imageData, type) {

			var parentController = this.get('parentController');
			var candidate = this.get('model');
			var crops = candidate.get('media.poster.crops');
			var m = this.getCropData(canvasData, cropBoxData, imageData);

			if ((typeof crops === 'undefined' ? 'undefined' : _typeof(crops)) !== 'object') {
				crops = {};
			}

			crops[type] = m.cropX + '-' + m.cropY + '-' + m.cropWidth + '-' + m.cropHeight;
			candidate.set('media.poster.crops', crops);

			// {"candidate": "media": { "poster": { "crops": { "landscape": "<cropX>-<cropY>-<cropW>-<cropH>", "square": "<cropX>-<cropY>-<cropW>-<cropH>" }}}}

			var me = this;
			var valid = true;

			if (valid) {

				me.setProperties({
					isProcessing: true,
					submitFailed: false
				});

				candidate.save({ adapterOptions: { cropSave: true } }).then(function (c) {

					me.setProperties({
						isProcessing: false
					});

					parentController.storeCandidate(c);

					if (typeof parentController.updateCompletedStep === 'function') {
						parentController.updateCompletedStep(1);
					}

					UTIL.tracker.track('event', {
						category: 'Candidate',
						action: 'candidate-crop-saved',
						label: 'Candidate Crop Data saved',
						value: 1
					});

					parentController.gotoRoute('active');

					return c;
				}, function () {
					me.setProperties({
						isProcessing: false,
						submitFailed: true
					});
					parentController.storeCandidate(null);
					return null;
				});
			}
		},

		actions: {

			/**
    * get all croppers a on this page and save therr data
    */
			saveAllCroppers: function saveAllCroppers() {
				//
				var me = this;
				var croppers = $('body').find('.image-cropper__img');
				croppers.each(function () {
					if (typeof $(this).cropper === 'function') {
						var $image = $(this),
						    mediaType = $(this).attr('data-media-type'),
						    canvasData,
						    cropBoxData,
						    imageData;

						canvasData = $image.cropper('getCanvasData');
						cropBoxData = $image.cropper('getCropBoxData');
						imageData = $image.cropper('getImageData');
						me.saveCropData(mediaType, canvasData, cropBoxData, imageData);
					}
				});
			},


			/**
    * Save crop data in model,
    *
    * @param canvasData
    * @param cropBoxData
    * @param imageData
    * @param type
    */
			saveData: function saveData(canvasData, cropBoxData, imageData, type) {

				this.setProperties({
					submitFailed: false
				});

				this.saveCropData(type, canvasData, cropBoxData, imageData);

				this.set('gotoTab', 'landscape_tab');
				this.set('changeTab', this.get('changeTab') + 1);
			},
			saveSquare: function saveSquare(canvasData, cropBoxData, imageData) {
				this.save(canvasData, cropBoxData, imageData, 'square');
				return false;
			},


			/**
    * Save landscape crop data in model and persist,
    *
    * @param canvasData
    * @param cropBoxData
    * @param imageData
    * @param type
    */
			save: function save(canvasData, cropBoxData, imageData, type) {
				this.save(canvasData, cropBoxData, imageData, type);
				return false;
			}
		}

	});
});