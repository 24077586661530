define('symbolsofpeace/components/user-profile', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		modalAction: 0,
		isDeleteProzessing: null,
		isProcessing: false,
		isLogoutProzessing: false,
		logoutFailed: false,
		submitFailed: false,
		usernameValid: true,
		usernameSubmitFailed: false,
		emailValid: true,
		emailSubmitFailed: false,

		parentStopProcessing: _ember.default.observer('stopProcessing', function () {
			this.set('isProcessing', false);
		}),

		didInsertElement: function didInsertElement() {
			Materialize.updateTextFields();
			this.focusFirstField();
			var applicationController = this.get('applicationController');

			applicationController.checkIfUserWantsToVote();
		},

		logOut: function logOut() {
			var me = this,
			    applicationController = this.get('applicationController');

			this.setProperties({
				isLogoutProzessing: true,
				isProcessing: false,
				logoutFailed: false,
				usernameSubmitFailed: false,
				emailSubmitFailed: false,
				submitFailed: false
			});

			$.get("/api/v1/auths/logout", {}).then(function () {
				me.setProperties({
					isLogoutProzessing: false
				});

				applicationController.setCurrentUser(null);
				applicationController.get('store').unloadAll('user');
				applicationController.transitionToRoute('index');
			}, function () {
				me.setProperties({
					isLogoutProzessing: false,
					logoutFailed: true
				});
			});
		},

		originMatcher: function originMatcher(option) {
			return option.label;
		},

		setProgress: function setProgress(progress) {
			var s = _ember.default.String.htmlSafe('width:' + progress + '%');
			if (!progress) {
				this.set('showUploadProgress', false);
				this.set('uploadProgress', s);
			} else {
				this.set('showUploadProgress', true);
				this.set('uploadProgress', s);
			}
		},

		clearValidations: function clearValidations() {
			this.setProperties({
				isLogoutProzessing: false,
				usernameSubmitFailed: false,
				logoutFailed: false,
				isProcessing: false,
				submitFailed: false
			});
		},

		checkFields: function checkFields() {

			var isValid = true;
			var usernameValid = this.get('usernameValid');
			var emailValid = this.get('emailValid');

			if (!usernameValid) {
				this.set('usernameSubmitFailed', true);
				isValid = false;
			}

			if (!emailValid) {
				this.set('emailSubmitFailed', true);
				isValid = false;
			}

			return isValid;
		},

		actions: {

			/**
    * trigger toggle modal
    * @param a
    */
			openAction: function openAction(a) {
				var i = this.get(a);
				this.set(a, i + 1);
			},


			/**
    * Progress is a bit of fake ;)
    */
			deleteUser: function deleteUser() {
				var me = this,
				    applicationController = this.get('applicationController');

				this.set('isDeleteProzessing', true);
				applicationController.set('userIsLoggedIn', false);

				setTimeout(function () {
					me.set('isDeleteProzessing', false);
					var i = me.get('modalAction');
					me.set('modalAction', i + 1);
					setTimeout(function () {
						me.sendAction('deleteUser');
					}, 250);
				}, 500);
			},

			usernameValid: function usernameValid(t) {
				this.set('usernameValid', t);
			},

			setUsername: function setUsername(t) {
				this.set('model.username', t);
			},

			emailValid: function emailValid(t) {
				this.set('emailValid', t);
			},

			setEmail: function setEmail(t) {
				this.set('model.email', t);
			},

			dz_UploadSuccess: function dz_UploadSuccess(r) {
				this.clearValidations();
				this.setProgress(0);
				this.set('model.pic', r.user.pic);
				UTIL.tracker.track('event', { category: 'User', action: 'picture-upload-complete', label: 'Picture upload completed', value: 1 });
			},

			uploadProgress: function uploadProgress(file, progress) {
				this.setProgress(progress);
			},

			returnPictureUploadToken: function returnPictureUploadToken() {
				return this.get('model');
			},

			selectOrgin: function selectOrgin(p) {
				// if(typeof console === 'object') { console.log('power select onchange',p,p.label); }
				this.set('model.country.code', p.label);
				this.set('userCountry', p.label);
				// return p.label;
			},

			save: function save() {

				this.clearValidations();

				if (!this.checkFields()) {
					return false;
				}

				this.setProperties({
					isProcessing: true
				});

				this.get('myController').send('save');
			},

			logOut: function logOut() {
				this.logOut();
			}

		}
	});
});