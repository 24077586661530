define('symbolsofpeace/components/video-embed-player', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		player: null,
		secondsPerFrame: 0.25,

		didInsertElement: function didInsertElement() {

			var vimeo = this.get('vimeo');
			var parentView = this.get('parent-view');

			if (parentView === 'editor-view' && vimeo) {
				this.findPlayer();
			}
		},

		willDestroyElement: function willDestroyElement() {},

		findPlayer: function findPlayer() {

			if (this.player) {
				return this.player;
			}

			var $element = this.$();
			var iframe = $element.find('iframe').get(0);
			return this.player = new Vimeo.Player(iframe);
		},

		actions: {

			getThumbnail: function getThumbnail() {
				var me = this;

				this.findPlayer();
				this.player.getCurrentTime().then(function (seconds) {
					me.get('controller').send('saveThumbnail', seconds);
				}).catch(function (error) {
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('VIMEO API Error', error);
					}
				});
			},

			nextFrame: function nextFrame() {
				var me = this;
				this.findPlayer();

				// eslint-disable-next-line no-unused-vars
				me.player.getDuration().then(function (duration) {
					me.player.getCurrentTime().then(function (seconds) {
						seconds = seconds + me.secondsPerFrame;
						// eslint-disable-next-line no-unused-vars
						me.player.setCurrentTime(seconds).then(function (seconds) {
							me.player.pause();
						}).catch(function (error) {
							if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
								console.log('VIMEO API Error', error);
							}
							switch (error.name) {
								case 'RangeError':
									// the time was less than 0 or greater than the video’s duration
									break;
								default:
									// some other error occurred
									break;
							}
						});
					}).catch(function (error) {
						if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
							console.log('VIMEO API Error', error);
						}
					});
				}).catch(function (error) {
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('VIMEO API Error', error);
					}
				});
			},

			previousFrame: function previousFrame() {

				var me = this;
				this.findPlayer();

				me.player.getCurrentTime().then(function (seconds) {
					seconds = seconds - me.secondsPerFrame;
					// eslint-disable-next-line no-unused-vars
					me.player.setCurrentTime(seconds).then(function (seconds) {
						me.player.pause();
					}).catch(function (error) {
						if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
							console.log('VIMEO API Error', error);
						}
						switch (error.name) {
							case 'RangeError':
								// the time was less than 0 or greater than the video’s duration
								break;
							default:
								// some other error occurred
								break;
						}
					});
				}).catch(function (error) {
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('VIMEO API Error', error);
					}
				});
			},

			play: function play() {
				this.findPlayer();
				this.player.play();
			},
			pause: function pause() {
				this.findPlayer();
				this.player.pause();
			}

		}

	});
});