define('symbolsofpeace/components/comment-answers', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		reloadAnswers: 0,
		showActions: _ember.default.computed.or('answers', 'reply'),
		modalReportAction: 0,
		isReportProzessing: false,
		reportError: false,

		showReport: _ember.default.computed('model.id', 'model.interactions', function () {
			var isMyComment = this.isMyComment(this.get('model'));
			var modelId = this.get('model.id');

			if (isMyComment) {
				return false;
			}

			var appController = this.get('appController');
			var m = appController.hasUserInteraction('reportInappropriate.Comment', modelId);

			if (m === true) {
				return false;
			}

			return true;
		}),

		init: function init() {
			this._super.apply(this, arguments);
		},


		/**
   * Check if "this" is my comment
   * @param model
   * @returns {boolean}
   */
		isMyComment: function isMyComment(model) {

			var appController = this.get('appController');
			var isMy = false,
			    userId = void 0,
			    ownerId = void 0;
			var user = appController.getCurrentUser();

			if (user && model) {
				userId = user.get('id');
				ownerId = model.get('creator.id');
				if (userId === ownerId) {
					isMy = true;
				}
			}

			return isMy;
		},

		toggleReply: function toggleReply() {
			this.set('showReply', !this.get('showReply'));
		},
		toggleShowAnswers: function toggleShowAnswers() {
			this.set('showAnswers', !this.get('showAnswers'));
		},
		toggleReportWindow: function toggleReportWindow() {
			var i = this.get('modalReportAction');
			this.set('modalReportAction', i + 1);
		},


		actions: {
			confirmReportContent: function confirmReportContent() {
				this.toggleReportWindow();
			},
			reportContent: function reportContent() {
				var _this = this;

				this.setProperties({
					isReportProzessing: true,
					reportError: false
				});

				var modelId = this.get('model.id');

				_ember.default.$.getJSON(" /api/v1/comments/" + modelId + "/report").then(function (data) {
					if (data) {

						var appController = _this.get('appController');
						appController.setUserInteraction('reportInappropriate.Comment', modelId);

						// let reports = UTIL.storage.getLocal( 'reports' );
						// if ( reports ) {
						// 	reports = JSON.parse( reports );
						// }
						// else {
						// 	reports = [];
						// }
						// reports.push( modelId );
						// UTIL.storage.storeLocal( 'reports', JSON.stringify( reports ) );

						_this.toggleReportWindow();
						_this.setProperties({
							isReportProzessing: false,
							reportError: false,
							showReport: false
						});
						return data;
					}
					return null;
				});
			},
			setReply: function setReply(comment) {
				var showAnswers = this.get('showAnswers');

				this.set('showReply', false);

				if (!showAnswers) {
					this.toggleShowAnswers();
				} else {
					var reloadAnswers = this.get('reloadAnswers') + 1;
					if (comment) {
						reloadAnswers = comment;
					}
					this.set('pushComment', comment);
					this.set('reloadAnswers', reloadAnswers);
				}
			},
			loadReply: function loadReply() {
				this.toggleReply();
			},
			loadAnswers: function loadAnswers() {
				this.toggleShowAnswers();
			}
		}
	});
});