define('symbolsofpeace/components/user-signup', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		submitFailed: false,
		isProcessing: false,
		isFacebookProzessing: false,
		isTwitterProzessing: false,
		usernameValid: true,
		usernameSubmitFailed: false,
		emailValid: true,
		emailSubmitFailed: false,
		passwordSubmitFailed: false,
		acceptedTermsFailed: false,
		newsletter_requested: false,

		modalAction: 0,
		termsTemplate: 'privacy-policy/en',
		acceptedTerms: true,

		clearValidations: function clearValidations() {
			this.setProperties({
				submitFailed: false,
				error_plainPassword: false,
				error_email: false,
				error_username: false,
				usernameSubmitFailed: false,
				emailSubmitFailed: false,
				passwordSubmitFailed: false,
				acceptedTermsFailed: false,
				isTwitterProzessing: false,
				isProcessing: false,
				isFacebookProzessing: false
			});
		},

		checkFields: function checkFields() {

			var isValid = true;
			var usernameValid = this.get('usernameValid');
			var emailValid = this.get('emailValid');
			var acceptedTerms = this.get('acceptedTerms');

			if (!this.checkPassword()) {
				this.set('passwordSubmitFailed', true);
				isValid = false;
			}

			if (!usernameValid || !this.checkField('username')) {
				this.set('usernameSubmitFailed', true);
				isValid = false;
			}

			if (!emailValid || !this.checkField('email')) {
				this.set('emailSubmitFailed', true);
				isValid = false;
			}

			if (!acceptedTerms) {
				this.set('acceptedTermsFailed', true);
				isValid = false;
			}

			return isValid;
		},

		checkField: function checkField(f) {
			var v = this.get(f);
			if (!v || !v.length) return false;
			return true;
		},

		checkPassword: function checkPassword() {
			// at least one number, one lowercase and one uppercase letter
			// at least six characters
			var str = this.get('password');
			var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
			return re.test(str);
		},

		setError: function setError(e) {
			if (e && e.responseJSON) {
				var errors = e.responseJSON.errors;
				for (var i = 0; i < errors.length; i++) {
					if (errors[i].source) {
						this.set('error_' + errors[i].source.parameter, true);
					}
				}
			}
		},

		signUp: function signUp() {

			var me = this;
			var applicationController = this.get('applicationController');
			var parent = this.get('parent');

			this.clearValidations();
			this.set('isProcessing', true);

			if (!this.checkFields()) {
				this.set('isProcessing', false);
				return false;
			}

			var user = applicationController.get('store').createRecord('user', {
				username: this.get('username'),
				email: this.get('email'),
				password: this.get('password'),
				newsletter_requested: this.get('newsletter_requested')
			});

			return user.save({ adapterOptions: { signup: true } }).then(function (user) {

				user.set('password', null);
				applicationController.setCurrentUser(user);

				UTIL.tracker.track('event', { category: 'User', action: 'signup', label: 'E-Mail Sign-Up', value: 1 });

				if (parent && parent === 'nominate') {

					me.sendAction('afterSave', function (co) {

						applicationController.set('_createdNewCandidate', true);

						me.setProperties({
							submitFailed: false,
							isProcessing: false
						});
						var parentController = me.get('parentController');
						if (parentController && typeof parentController.setCompletedStep === 'function') {
							parentController.setCompletedStep(0);
						}

						var model = me.get('model');
						if (model) {
							if (co && co.candidate && co.candidate.owner) {
								model.set('owner', co.candidate.owner);
							}
							applicationController.transitionToRoute('symbol.candidate', model.get('main_tag'), model.get('id'));
						} else {
							if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
								console.log('ERROR - no candidate found to transition to', model);
							}
						}
					});
				} else {
					me.setProperties({
						submitFailed: false,
						isProcessing: false
					});
					me.sendAction('afterSave');
					applicationController.transitionToRoute('user-profile');

					if (applicationController.get('votingPhase')) {
						var votingbasket = applicationController.getVotingbasket();
						applicationController.refreshVotes(votingbasket);
					}
				}
				return user;
			}, function (e) {
				me.setError(e);
				me.setProperties({
					submitFailed: true,
					isProcessing: false
				});
				return null;
			});
		},

		actions: {

			openModal: function openModal() {
				var i = this.get('modalAction');
				this.set('modalAction', i + 1);
				return false;
			},

			usernameValid: function usernameValid(t) {
				this.set('usernameValid', t);
			},

			setUsername: function setUsername(t) {
				this.set('username', t);
			},

			emailValid: function emailValid(t) {
				this.set('emailValid', t);
			},

			setEmail: function setEmail(t) {
				this.set('email', t);
			},

			signUp: function signUp() {
				this.signUp();
			},

			twitterLogin: function twitterLogin() {

				this.setProperties({
					submitFailed: false,
					isProcessing: false,
					isTwitterProzessing: true,
					isFacebookProzessing: false
				});
			},

			facebookLogin: function facebookLogin() {

				this.setProperties({
					submitFailed: false,
					isProcessing: false,
					isTwitterProzessing: true,
					isFacebookProzessing: false
				});
			}
		}

	});
});