define('symbolsofpeace/models/category', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		slug: _emberData.default.attr('string'),
		title: _emberData.default.attr('string'),
		key: _emberData.default.attr(),
		online: _emberData.default.attr(),
		rank: _emberData.default.attr(),
		color: _emberData.default.attr()

	});
});