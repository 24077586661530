define('symbolsofpeace/routes/symbol/candidate', ['exports', 'ember', 'symbolsofpeace/routes/symbol/base-symbol-candidate-route'], function (exports, _ember, _baseSymbolCandidateRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseSymbolCandidateRoute.default.extend({

		docTitle: 'Symbol Candidate',

		candidateIsNotNew: _ember.default.on('deactivate', function () {
			this._super.apply(this, arguments);
			var appController = this.controllerFor('application');
			appController.set('_createdNewCandidate', false);
		})

	});
});