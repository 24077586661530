define('symbolsofpeace/components/candidate-admin-actions', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		classNames: ['candidate-admin-actions', 'content--margin24', 'flex-view', 'flex-row', 'flex-view--center'],
		tagName: 'ul',
		classNameBindings: ['isAdmin:isAdmin:hidden'],

		deleteChangeReason: true,
		modalAction: 0,
		isDeleteProzessing: null,

		isAdmin: _ember.default.computed('candidate.id', 'appController.user.id', function () {
			return this.get('appController').canEditCandidate(this.get('candidate'));
		}),

		isVimeoUploadVideo: _ember.default.computed('candidate.media.type', function () {

			var media = this.get('candidate.media');

			if ((typeof media === 'undefined' ? 'undefined' : _typeof(media)) === 'object') {
				var t = media.type;
				switch (t) {
					case 'vimeo-upload':
						if (media.video && media.video.id) {
							this.set('videoUrl', media.video.id);
						}
						return true;
				}
			}
			return false;
		}),

		actions: {

			/**
    * trigger toggle modal
    * @param a
    */
			openAction: function openAction(a) {
				var i = this.get(a);
				this.set(a, i + 1);
			},


			/**
    * Progress is a bit of fake ;)
    */
			deleteCandidate: function deleteCandidate() {
				var me = this;
				this.set('isDeleteProzessing', true);
				setTimeout(function () {
					me.set('isDeleteProzessing', false);
					var i = me.get('modalAction');
					me.set('modalAction', i + 1);
					setTimeout(function () {
						me.sendAction('deleteCandidate');
					}, 250);
				}, 500);
			}

		}

	});
});