define('symbolsofpeace/components/voting-item', ['exports', 'symbolsofpeace/components/base-component', 'ember'], function (exports, _baseComponent, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({
		// export default BaseComponent.extend(votingButton,{

		classNames: ['media', 'media-square'],
		tagName: 'article',
		defaultLinkTo: 'index',
		showVotingActions: true,

		candidate: _ember.default.computed('votingbasketJson', function () {
			var candidate = this.getCandidate();
			// let votingbasketJson = this.get('votingbasketJson');
			return candidate;
		}),

		symbol: _ember.default.computed('votingbasketJson', function () {
			var symbol = this.getSymbol();
			// let votingbasketJson = this.get('votingbasketJson');
			return symbol;
		}),

		// init() {
		// 	this._super(...arguments);
		// 	// if(typeof console === 'object') { console.log('candidate',this.get('candidate')); }
		// 	// this.setVotingItemByKey();
		// },

		// setVotingItemByKey() {
		// 	let votingbasket = this.get('votingbasket');
		// 	let votingbasketKey = this.get('votingbasketKey');
		//
		// 	if(votingbasket && votingbasketKey) {
		// 		let vote = votingbasket.get('vote'+votingbasketKey);
		// 		let candidate = null;
		//
		// 		if(vote && vote.candidate) {
		// 			candidate = vote.candidate;
		// 		}
		// 		else if(vote && vote.symbol && vote.symbol.top_conadidate) {
		// 			candidate = vote.candidate;
		// 		}
		//
		// 		if(candidate) {
		// 			this.set('symbol',vote.symbol);
		// 			this.set('candidate',candidate);
		// 			// if(typeof console === 'object') { console.log('candidate',candidate); }
		// 		}
		// 	}
		// },

		getSymbol: function getSymbol() {
			var votingbasket = this.get('votingbasket');
			var votingbasketKey = this.get('votingbasketKey');
			var symbol = null;

			if (votingbasket && votingbasketKey) {
				var vote = votingbasket.get(votingbasketKey);

				if (vote && vote.symbol) {
					symbol = vote.symbol;
				}
			}

			// if(typeof console === 'object') { console.log('symbol',symbol); }

			return symbol;
		},
		getCandidate: function getCandidate() {
			var votingbasket = this.get('votingbasket');
			var votingbasketKey = this.get('votingbasketKey');
			var candidate = null;

			if (votingbasket && votingbasketKey) {
				var vote = votingbasket.get(votingbasketKey);

				if (vote && vote.candidate) {
					candidate = vote.candidate;
				} else if (vote && vote.symbol && vote.symbol.top_conadidate) {
					candidate = vote.candidate;
				}
			}

			// if(typeof console === 'object') { console.log('candidate',candidate); }
			return candidate;
		},


		actions: {
			checkAlreadyVotedFor: function checkAlreadyVotedFor(event) {
				// if(typeof console === 'object') { console.log('event',event); }
				event.preventDefault();
				event.stopPropagation();

				this.checkAlreadyVotedForCandidate();
			},
			gotoCandidate: function gotoCandidate(tag, id) {
				var appController = this.get('appController');
				appController.transitionToRoute('symbol.candidate', tag, id);
			},
			gotoSymbol: function gotoSymbol(tag, event) {
				event.preventDefault();
				event.stopPropagation();
				var appController = this.get('appController');
				appController.transitionToRoute('symbol', tag);
			},
			gotoCategory: function gotoCategory(category, event) {
				// if(typeof console === 'object') { console.log('gotoCategory',category, typeof category.toJSON); }
				event.preventDefault();
				event.stopPropagation();

				var appController = this.get('appController');
				if (typeof category.toJSON === 'function') {
					appController.gotoCategory(category.toJSON());
				} else {
					appController.gotoCategory(category);
				}

				// $('#basket-slide-out').sidenav('close');
				$('.fixedheader__votelink').sideNav('hide');
			}
		}

	});
});