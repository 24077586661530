define('symbolsofpeace/mixins/notes', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Mixin.create({

		notesLengthFailed: false,
		submitFailed: false,
		min_length: 140,

		init: function init() {

			this.setProperties({
				isProcessing: false,
				submitFailed: false,
				notesLengthFailed: false
			});
		},

		checkField: function checkField() {
			var input = $('#nominate_note');

			if (input && input.length) {
				var val = input.val().trim();
				if (val.length < this.min_length) {
					this.set('notesLengthFailed', true);
					return false;
				}
			}
			return true;
		},

		save: function save() {

			var me = this;
			me.setProperties({
				submitFailed: false,
				notesLengthFailed: false
			});

			var valid = this.checkField();
			var parentController = this.get('parentController');
			var appController = this.get('appController');
			var userIsLoggedIn = appController.get('userIsLoggedIn');

			if (valid) {

				me.setProperties({
					isProcessing: true
				});

				this.get('model').save({ adapterOptions: { modelSave: true } }).then(function (c) {

					parentController.storeCandidate(c);

					UTIL.tracker.track('event', {
						category: 'Candidate',
						action: 'candidate-notes-saved',
						label: 'Candidate Notes saved',
						value: 1
					});

					var nominateRoute = typeof parentController.updateCompletedStep === 'function';

					if (nominateRoute && userIsLoggedIn) {

						me.send('completeCandidate', function (co) {
							appController.set('_createdNewCandidate', true);

							me.setProperties({
								isProcessing: false
							});

							parentController.setCompletedStep(0);

							if (co && co.candidate && co.candidate.owner) {
								c.set('owner', co.candidate.owner);
							}

							appController.transitionToRoute('symbol.candidate', c.get('main_tag'), c.get('id'));
						});
					} else {
						me.setProperties({
							isProcessing: false
						});

						if (nominateRoute) {
							parentController.updateCompletedStep(3);
						}

						parentController.gotoRoute('signup');
					}

					return c;
				}, function () {
					me.setProperties({
						isProcessing: false,
						submitFailed: true
					});
					parentController.storeCandidate(null);
					return null;
				});
			}
		},

		actions: {
			checkQty: function checkQty() {
				var input = $('#nominate_note');
				var qtyLabel = $('#nominate_note_qty');

				if (input && input.length) {

					var val = input.val().trim();
					var l = val.length;
					if (l < this.min_length) {
						qtyLabel.html('(' + l + ' / ' + this.min_length + ')');
						qtyLabel.addClass('red').removeClass('green');
					} else {
						qtyLabel.html('(' + l + ')');
						qtyLabel.addClass('green').removeClass('red');
					}
				}
			},


			/**
    * set timout is needed in order to wait for chips to get added!
    */
			save: function save() {
				var me = this;
				me.save();
			},
			chipSave: function chipSave(data) {
				this.set('model.additional_tags', data);
			}
		}

	});
});