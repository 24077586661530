define('symbolsofpeace/components/symbol-admin-actions', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		classNames: ['symbol-admin-actions', 'content--margin24', 'flex-view', 'flex-row', 'flex-view--center'],
		tagName: 'ul',
		classNameBindings: ['isAdmin:isAdmin:hidden'],

		isAdmin: _ember.default.computed('appController.userIsAdmin', function () {
			return this.get('appController.userIsAdmin');
		}),

		actions: {

			/**
    * trigger toggle modal
    * @param a
    */
			openAction: function openAction(a) {
				var i = this.get(a);
				this.set(a, i + 1);
			},


			deleteSymbol: function deleteSymbol() {
				var me = this;
				this.set('isDeleteProzessing', true);
				setTimeout(function () {
					me.set('isDeleteProzessing', false);
					var i = me.get('modalAction');
					me.set('modalAction', i + 1);
					setTimeout(function () {
						me.sendAction('deleteSymbol');
					}, 250);
				}, 500);
			}

		}

	});
});