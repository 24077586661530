define('symbolsofpeace/components/media-listing-filter', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		classNames: ['media-listing-filter'],
		filterValue: 'interesting',
		lastParams: {},
		searchvalue: '',
		meta: {},
		isLoading: false,
		// infiniteScroll: true,

		updateCategory: _ember.default.computed('selectedCategory', function () {
			var selectedCategory = this.get('selectedCategory');

			// if(typeof console === 'object') { console.log('MediaListingFilter.selectedCategory',selectedCategory); }

			var p = null;

			if (selectedCategory && selectedCategory.id) {
				p = selectedCategory.id;
			}

			this.do_filter(p, 'cg');

			return selectedCategory;
		}),

		/**
   * Last loaded item of query
   */
		isLast: _ember.default.computed('meta.last', function () {
			return this.get('meta.last');
		}),

		/**
   * Reload result if parent model (symbol!) changed
   * @returns {boolean}
   */
		parentModelChanged: function parentModelChanged() {
			this.reloadResult();
			return true;
		},

		// didUpdate() {
		// 	let s = this.get('selectedCategory');
		// 	let slug = null;
		//
		// 	if(s && typeof s.get === 'function') {
		// 		slug = s.get('slug');
		// 	} else if(s && typeof s === 'object') {
		// 		slug = s.slug;
		// 	}
		//
		// 	if(typeof console === 'object') { console.log('didUpdatedidUpdatedidUpdate',slug); }
		// },

		init: function init() {
			var _this = this;

			this.set('noResult', true);
			this._super.apply(this, arguments);
			this.get('filter')('').then(function (allResults) {
				_this.set('results', allResults.results);
				_this.set('meta', allResults.meta);
				_this.afterResultsLoaded();
			});

			this.limit = this.get('limit') || 10;
			this.page = this.get('page') || 1;

			if (this.get('resetAndReload') >= 0) {
				this.addObserver('resetAndReload', this, 'parentModelChanged');
			}
		},


		didInsertElement: function didInsertElement() {
			Materialize.updateTextFields();
			// we want to make sure 'this' inside `didScroll` refers
			// to the IndexView, so we use jquery's `proxy` method to bind it
			if (this.get('infiniteScroll')) {
				$(window).on('scroll', $.proxy(this.didScroll, this));
			}
		},

		willDestroyElement: function willDestroyElement() {
			// have to use the same argument to `off` that we did to `on`
			if (this.get('infiniteScroll')) {
				$(window).off('scroll', $.proxy(this.didScroll, this));
			}
		},

		// this is called every time we scroll
		didScroll: function didScroll() {
			if (this.isScrolledToBottom()) {
				this.loadMore(this.limit);
			}
		},

		// we check if we are at the bottom of the page
		isScrolledToBottom: function isScrolledToBottom() {
			var bottomDistance = 10;
			var distanceToViewportTop = $(document).height() - $(window).height();
			var viewPortTop = $(document).scrollTop();
			// if(typeof console === 'object') { console.log('viewPortTop',viewPortTop,distanceToViewportTop,(viewPortTop - (distanceToViewportTop - bottomDistance)),$(document).height(),$(window).height()); }

			if (viewPortTop === 0) {
				// if we are at the top of the page, don't do
				// the infinite scroll thing
				return false;
			}

			return viewPortTop - (distanceToViewportTop - bottomDistance) >= 0;
		},

		activateNav: function activateNav(activeEl) {
			var $element = this.$();
			$element.find('li.active').removeClass('active');
			if (activeEl.nodeName === 'A') {
				$(activeEl).closest('li').addClass('active');
			} else if (activeEl.nodeName === 'LI') {
				$(activeEl).addClass('active');
			}
		},

		loadMore: function loadMore(limit) {
			var _this2 = this;

			var appController = this.get('appController');

			if (this.get('isLast')) {
				return true;
			}

			if (this.isLoading) {
				return false;
			}

			if (appController.get('_quickLookLoading')) {
				return false;
			}

			this.isLoading = true;
			var filterAction = this.get('filter');
			this.page = this.page + 1;
			this.deactivateLoadMoreBtn();

			limit = limit || this.limit;

			filterAction(this.filterValue, limit, this.page, true).then(function (resultsObj) {
				_this2.set('results', resultsObj.results);
				_this2.set('meta', resultsObj.meta);
				_this2.activateLoadMoreBtn();
				_this2.afterResultsLoaded();
			});
		},

		reloadResult: function reloadResult() {
			var _this3 = this;

			this.isLoading = true;
			var filterAction = this.get('filter');
			this.page = 1;

			filterAction(this.filterValue, this.limit, this.page, false).then(function (resultsObj) {
				_this3.set('results', resultsObj.results);
				_this3.set('meta', resultsObj.meta);
				_this3.afterResultsLoaded();
			});
		},

		activateLoadMoreBtn: function activateLoadMoreBtn() {
			var lm = $('.media-listing-filter__loadmore');
			lm.removeClass('disabled').removeClass('spin').removeAttr('disabled', 'disabled');
		},

		deactivateLoadMoreBtn: function deactivateLoadMoreBtn() {
			var lm = $('.media-listing-filter__loadmore');
			lm.addClass('disabled').addClass('spin').attr('disabled', 'disabled');
		},

		/**
   * hide Filter, if we have no results
   */
		afterResultsLoaded: function afterResultsLoaded() {
			var meta = this.get('meta');
			this.isLoading = false;

			if (!this.get('forceHideFilter')) {
				if (meta && meta.last === true && !meta.total) {
					this.set('hideFilter', true);
					this.set('noResult', true);
				} else {
					this.set('hideFilter', false);
					this.set('noResult', false);
				}
			}
		},

		do_filter: function do_filter(p, type) {
			var _this4 = this;

			var params = Object.assign({}, this.get('lastParams'));

			if (p === null) {
				delete params[type];
			} else {
				params[type] = p;
			}

			if (typeof params['c'] === 'undefined') {
				params['c'] = this.filterValue;
			}

			var filterAction = this.get('filter');
			this.page = 1;

			filterAction(params, this.limit, this.page).then(function (resultsObj) {

				// if(typeof console === 'object') { console.log('resultsObj',resultsObj,params); }

				_this4.set('lastParams', params);

				if (resultsObj.query === params) {
					_this4.set('results', resultsObj.results);
					_this4.set('meta', resultsObj.meta);
				}
				_this4.afterResultsLoaded();
			});
		},


		actions: {

			_loadMore: function _loadMore(limit, event) {
				event.preventDefault();
				this.loadMore(limit);
			},

			handleCategoryFilter: function handleCategoryFilter(p) {

				var appController = this.get('appController');
				appController.gotoCategory(p);

				if (p && (typeof p === 'undefined' ? 'undefined' : _typeof(p)) === 'object') {
					p = p.id;
				}

				// this.do_filter(p,'cg');
			},
			handleFilterEntry: function handleFilterEntry(p, event) {

				event.preventDefault();

				this.isLoading = true;
				this.activateNav(event.target);

				this.do_filter(p, 'c');

				return false;
			}
		}

	});
});