define('symbolsofpeace/mixins/voting-button', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _ember.default.Mixin.create({
		reArrangeVotingBasket: function reArrangeVotingBasket(votingbasket, key) {
			var votingBasketJson = votingbasket.toJSON();

			for (var i = 1; i <= 7; i++) {
				// eslint-disable-next-line no-unused-vars
				var vote = votingBasketJson['vote' + i];
				if (i >= key && i !== 7) {
					votingBasketJson['vote' + i] = votingBasketJson['vote' + (i + 1)];
					votingbasket.set('vote' + i, votingBasketJson['vote' + (i + 1)]);
				}
			}

			// if(typeof console === 'object') { console.log('NEWWWWW votingBasketJson',votingBasketJson); }
		},
		checkAlreadyVotedForCandidate: function checkAlreadyVotedForCandidate() {
			var _this = this;

			var showConfirmButton = false;

			if (!showConfirmButton) {
				this.voteFor(true);
			} else {

				// var me = this;
				var appController = this.get('appController');
				// let category = this.get( 'symbol.category' );
				var symbol = this.get('symbol');
				// let candidate = this.get( 'candidate' );

				this.set('showModal', true);
				var modalAction = this.get('modalAction');
				modalAction++;

				var tag = '';
				if (symbol && typeof symbol.get === 'function') {
					tag = symbol.get('tag');
				} else if (symbol && (typeof symbol === 'undefined' ? 'undefined' : _typeof(symbol)) === 'object') {
					tag = symbol.tag;
				}

				this.set('modalAction', modalAction);
				var title = 'Reset your vote for ' + tag;

				// let msg = 'You already voted for <i>' + symbol.get('tag') + '</i>. Do you want to reset your voting?';
				var msg = 'You already voted for this candidate. Do you want to reset your voting?';

				var fn = function fn() {
					_this.voteFor(true);
					// .then((a,b) => {
					// 	if(typeof console === 'object') { console.log('TEST',a,b); }
					// });
				};

				appController.showConfirmDialog(title, msg, fn);
			}
		},
		voteFor: function voteFor(reset) {

			var me = this;
			var appController = this.get('appController');
			// let category = this.get('symbol.category');
			var symbol = this.get('symbol');
			var candidate = this.get('candidate');

			var candidateId = null;
			if (candidate && typeof candidate.get === 'function') {
				candidateId = { id: candidate.get('id') };
			} else if (candidate && (typeof candidate === 'undefined' ? 'undefined' : _typeof(candidate)) === 'object') {
				candidateId = { id: candidate.id };
			}

			var symbolId = '';
			var tag = '';
			if (symbol && typeof symbol.get === 'function') {
				symbolId = symbol.get('id');
				tag = symbol.get('tag');
			} else if (symbol && (typeof symbol === 'undefined' ? 'undefined' : _typeof(symbol)) === 'object') {
				symbolId = symbol.id;
				tag = symbol.tag;
			}

			this.setProperties({
				submitFailed: false,
				isProcessing: true
			});

			var data = {};

			var votingbasket = appController.getVotingbasket();
			var key = 'vote1';

			if (reset) {
				var numKey = appController.findCandidateVoting(candidate);
				data = null;
				data = {
					candidate: candidateId,
					remove: true
				};
				// eslint-disable-next-line no-unused-vars
				var new_votingbasket = this.reArrangeVotingBasket(votingbasket, numKey);

				key = appController.findNextFreeVotingKey(votingbasket);
				key = 'vote' + key;

				// if(typeof console === 'object') { console.log('votingbasket',votingbasket.toJSON(),key,numKey); }
				// if(typeof console === 'object') { console.log('new_votingbasket, data',data); }
			} else {
				key = appController.findNextFreeVotingKey();
				key = 'vote' + key;
				data = {
					// category : { id: category.id },
					symbol: { id: symbolId },
					candidate: candidateId
				};
			}

			votingbasket.set(key, data);

			// if(typeof console === 'object') { console.log('votingbasket',votingbasket,votingbasket.toJSON()); }

			return votingbasket.save().then(function () {

				if (reset) {
					votingbasket.set(key, {});
				}

				appController.setVotingbasket(votingbasket);

				if (!reset) {
					appController.openVotingBasket();
				}

				if (!reset) {
					UTIL.tracker.track('event', { category: 'Voting', action: 'vote', label: 'Vote', value: 1 });
					UTIL.tracker.track('event', { category: 'Voting for Symbol ' + tag, action: 'vote-for-symbol-' + tag, label: 'Vote for Symbol ' + tag, value: 1 });
				}

				me.setProperties({
					submitFailed: false,
					isProcessing: false
				});

				var parentView = me.get('parent-view');
				if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
					console.log('parentView', parentView);
				}
				if (!reset && parentView === 'candidate-listing') {
					appController.send('gotoSymbols');
				}

				// setTimeout()
				// return newvotingbasket.store.queryRecord('votingbasket', {}).then(function(vt) {
				// 	// appController.setVotingbasket(vt);
				// 	// return votingbasket;
				// 	if(typeof console === 'object') { console.log('QUERY!!!',vt,vt.toJSON()); }
				// 	appController.openVotingBasket();
				// 	return vt;
				// });

				return votingbasket;
			}, function () {
				me.setProperties({
					submitFailed: true,
					isProcessing: false
				});
				return null;
			});
		}
	});
});