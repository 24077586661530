define('symbolsofpeace/mixins/delete-with-payload', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Mixin.create({
		deleteRecord: function deleteRecord(store, type, snapshot) {
			var id = snapshot.id;
			var data = {};
			var serializer = store.serializerFor(type.modelName);

			serializer.serializeIntoHash(data, type, snapshot);

			return this.ajax(this.buildURL(type.modelName, id, snapshot, 'deleteRecord'), "DELETE", {
				data: data
			});
		}
	});
});