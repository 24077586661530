define('symbolsofpeace/routes/base-content-route', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Route.extend({

		/**
   * Forwarder to cropImageDialog in application controller
   *
   * @param candidate
   * @param target
   */
		showCandidateCropDialog: function showCandidateCropDialog(candidate, target) {
			var appController = this.controllerFor('application');
			appController.cropImageDialog(candidate, target);
		},

		activate: function activate() {
			this._super.apply(this, arguments);
			window.scrollTo(0, 0);
			var appController = this.controllerFor('application');
			appController.send('hideSymbolQuickLook', false);
		},

		showStopper: _ember.default.on('deactivate', function () {
			this._super.apply(this, arguments);
			var controller = this.controllerFor('my-symbols.index');
			controller.set('stopperVisible', false);
		}),

		actions: {

			// The didTransition action is fired after a transition has successfully been completed.
			// This occurs after the normal model hooks (beforeModel, model, afterModel, setupController) have resolved.
			didTransition: function didTransition() {

				//this._super(arguments);
				var baseDocTitle = '7 Symbols of Peace';
				var sep = ' | ';

				var docTitle = this.get('docTitle');
				if (docTitle) {
					document.title = this.get('docTitle') + sep + baseDocTitle;
				} else {
					document.title = baseDocTitle;
				}

				UTIL.tracker.track('page', {});

				return true; // Bubble the didTransition event
			}
			//
			//// The loading action is fired on the route when a route's model hook returns a promise that is not already resolved.
			//loading: function(transition, route) {
			//	if(typeof console === 'object') { console.log('BaseContentRoute.loading',transition, route); }
			//
			//	return true; // Bubble the loading event
			//},
			//
			//error: function(error, transition) {
			//	// Assuming we got here due to the error in `beforeModel`,
			//	// we can expect that error === "bad things!",
			//	// but a promise model rejecting would also
			//	// call this hook, as would any errors encountered
			//	// in `afterModel`.
			//
			//	// The `error` hook is also provided the failed
			//	// `transition`, which can be stored and later
			//	// `.retry()`d if desired.
			//	if(typeof console === 'object') { console.log('BaseContentRoute - WARNING transition error',error, transition); }
			//	this.transitionTo('splash');
			//}
		}

	});
});