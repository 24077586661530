define('symbolsofpeace/helpers/jit-url', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.jitUrl = jitUrl;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	/**
  *
  * JIT URLS
  *
  * <jit_host_mit_protocol_mit_doppelpunkt_und_doppelslash><base_url>,<W>x<H>,<jitprocessors>.<format>
  * also zb /image/_j/vimeo/59abe8c323447758913c63b4,400x400,xcrop-0-0-1-1,ex-crop-cc.jpg
  *
  * @param params
  * @returns {*}
  */

	function jitUrl(params /*, hash*/) {
		var _params = _slicedToArray(params, 3),
		    poster = _params[0],
		    type = _params[1],
		    bgImg = _params[2];

		var url = void 0;
		var jitDomain = '',
		    crop = '';
		// if(typeof console === 'object') { console.log('poster of',type,poster); }

		if ((typeof poster === 'undefined' ? 'undefined' : _typeof(poster)) !== 'object') {
			return '';
		}

		switch (type) {
			case 'profile':
				if (poster.crops && poster.crops.square) {
					crop = ',xcrop-' + poster.crops.square;
				}
				url = jitDomain + poster.base_url + ',500x500' + crop + ',ex-crop-cc.jpg';
				break;
			case 'base-crop':
				url = jitDomain + poster.url_base + ',1000xX.jpg';
				break;
			case 'square':
				if (poster.crops && poster.crops.square) {
					crop = ',xcrop-' + poster.crops.square;
				}
				url = jitDomain + poster.url_base + ',500x500' + crop + ',ex-crop-cc.jpg';
				break;
			case 'square-huge':
				if (poster.crops && poster.crops.square) {
					crop = ',xcrop-' + poster.crops.square;
				}
				url = jitDomain + poster.url_base + ',1000x1000' + crop + ',ex-crop-cc.jpg';
				break;
			default:
			case 'landscape':
				if (poster.crops && poster.crops.landscape) {
					crop = ',xcrop-' + poster.crops.landscape;
				} else if (poster.crops && poster.crops.square) {
					crop = ',xcrop-' + poster.crops.square;
				}
				url = jitDomain + poster.url_base + ',1600x900' + crop + ',ex-crop-cc.jpg';
				break;
		}

		if (bgImg) {
			url = _ember.default.String.htmlSafe('background-image:url(' + url + ');');
		}

		return url;
	}

	exports.default = _ember.default.Helper.helper(jitUrl);
});