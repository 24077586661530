define('symbolsofpeace/components/mdc-modal', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		showOnCreation: true,
		modal: null,
		status: 'closed',

		/**
   * Update "this" variable through you controller to trigger parentToggle
   */
		parentToggle: _ember.default.observer('toggleModal', function () {
			var t = this.get('status');
			if (t === 'closed') {
				this.show();
			} else {
				this.close();
			}
		}),

		didInsertElement: function didInsertElement() {

			var $el = null,
			    me = this;

			var $element = this.$();
			if ($element.hasClass('modal')) {
				$el = $element;
			} else {
				$el = $element.find('.modal').modal();
			}
			this.set('modal', $el);

			$el.modal({
				dismissible: this.get('dismissible') || true,
				ready: function ready() {
					// Callback for Modal open. Modal and trigger parameters available.
					me.set('status', 'open');
					me.focusFirstField();
				},
				complete: function complete() {
					// Callback for Modal close
					me.set('status', 'closed');
				}
			});

			// $('.modal').modal({
			// 		dismissible: true, // Modal can be dismissed by clicking outside of the modal
			// 		opacity: .5, // Opacity of modal background
			// 		inDuration: 300, // Transition in duration
			// 		outDuration: 200, // Transition out duration
			// 		startingTop: '4%', // Starting top style attribute
			// 		endingTop: '10%', // Ending top style attribute
			// 		ready: function(modal, trigger) { // Callback for Modal open. Modal and trigger parameters available.
			// 			alert("Ready");
			// 			console.log(modal, trigger);
			// 		},
			// 		complete: function() { alert('Closed'); } // Callback for Modal close
			// 	}
			// );

			if (this.get('showOnCreation')) {
				this.show();
			}
		},

		show: function show() {
			this.get('modal').modal('open');
			return false;
		},

		close: function close() {
			this.get('modal').modal('close');
			return false;
		},

		actions: {
			close: function close() {
				this.close();
			}
		}
	});
});