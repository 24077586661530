define('symbolsofpeace/components/fixed-header', ['exports', 'symbolsofpeace/components/base-component', 'ember'], function (exports, _baseComponent, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		emailSubmitFailed: false,
		acceptedTermsFailed: false,
		modalAction: 0,
		modalActionVotingThanks: 0,
		termsTemplate: 'privacy-policy/en',

		votingBasketOpener: _ember.default.computed('appController.votingBasketOpener', function () {
			var appController = this.get('appController');
			this.showBasket();
			return appController.votingBasketOpener;
		}),

		getVotingbasket: function getVotingbasket() {
			var appController = this.get('appController');
			var votingbasket = appController.get('votingbasket');
			return votingbasket;
		},


		didInsertElement: function didInsertElement() {
			$('.nav-slide-out-collapse').sideNav({
				menuWidth: 300, // Default is 300
				edge: 'left', // Choose the horizontal origin
				closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
				draggable: true // Choose whether you can drag to open on touch screens,
				// onOpen: function(el) {  }, // A function to be called when sideNav is opened
				// onClose: function(el) {  }, // A function to be called when sideNav is closed
			});

			var appController = this.get('appController');
			var userHasVoted = appController.get('userHasVoted');
			if (!userHasVoted) {
				$('.fixedheader__votelink').sideNav({
					menuWidth: 300, // Default is 300
					edge: 'right', // Choose the horizontal origin
					closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
					draggable: true // Choose whether you can drag to open on touch screens,
					// onOpenStart: (el) => {
					// 	if(typeof console === 'object') { console.log('onOpenStart',el); }
					// }
					// onOpen: function(el) {  }, // A function to be called when sideNav is opened
					// onClose: function(el) {  }, // A function to be called when sideNav is closed
				});
			}
		},

		hideBasket: function hideBasket() {
			$('.fixedheader__votelink').sideNav('hide');
			return false;
		},

		showBasket: function showBasket() {
			$('.fixedheader__votelink').sideNav('show');
			return false;
		},

		userHasVoted: function userHasVoted(d) {
			var appController = this.get('appController');
			if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
				console.log('USER HAS VOTED', d);
			}
			if ((typeof d === 'undefined' ? 'undefined' : _typeof(d)) === 'object' && _typeof(d.votingbasket) === 'object' &&
			// d.votingbasket.submitted === true
			d.meta.success === true) {
				var votingbasket = this.getVotingbasket();
				votingbasket.set('submitted', true);
				appController.setVotingbasket(votingbasket);
				// appController.transitionToRoute('my-votes');
				this.openVotingbasketModalThanksVoting();
			}
		},
		checkForUserLogin: function checkForUserLogin() {
			var _this = this;

			// var me = this;
			var appController = this.get('appController');

			this.set('showModal', true);
			var modalAction = this.get('modalAction');
			modalAction++;

			this.set('modalAction', modalAction);
			var title = 'Please log in or register';

			// let msg = 'You already voted for <i>' + symbol.get('tag') + '</i>. Do you want to reset your voting?';
			var msg = 'Please log in or register in order to vote.';

			var fn = function fn() {
				_this.hideBasket();
				appController.set('userWantsToVoteAfterLogin', true);
				appController.transitionToRoute('user-signin');
			};

			var gotoLoginCallback = function gotoLoginCallback() {
				_this.hideBasket();
				appController.set('userWantsToVoteAfterLogin', true);
			};

			appController.showConfirmDialog(title, msg, fn, {
				loginButton: false,
				signupButton: true,
				gotoLoginCallback: gotoLoginCallback,
				gotoRegisterCallback: gotoLoginCallback
			});
		},


		checkFields: function checkFields() {

			var isValid = true;
			var appController = this.get('appController');

			if (appController.get('userIsLoggedIn')) {
				return true;
			}

			var emailValid = this.get('emailValid');
			var acceptedTerms = this.get('email_agree');

			if (!emailValid || !this.checkField('email')) {
				this.set('emailSubmitFailed', true);
				isValid = false;
			}

			if (!acceptedTerms) {
				this.set('acceptedTermsFailed', true);
				isValid = false;
			}

			return isValid;
		},

		checkField: function checkField(f) {
			var v = this.get(f);
			if (!v || !v.length) return false;
			return true;
		},

		saveVotingBasket: function saveVotingBasket() {

			var me = this;
			var votingbasket = this.getVotingbasket();
			var id = votingbasket.get('id');

			me.setProperties({
				isProcessing: true,
				emailSubmitFailed: false,
				acceptedTermsFailed: false,
				submitFailed: false
			});

			if (!this.checkFields()) {
				this.set('isProcessing', false);
				return false;
			}

			var url = '/api/v1/votingbaskets/' + id + '/commit';

			var email = this.get('email');
			var appController = this.get('appController');
			if (appController.get('userIsLoggedIn')) {
				email = appController.get('user.email');
			}

			// let data = JSON.stringify(votingbasket.toJSON());
			var r = { votingbasket: votingbasket.toJSON(), email: email };
			var data = JSON.stringify(r);

			jQuery.ajax({
				type: 'POST',
				method: 'put',
				url: url,
				data: data,
				dataType: "json",
				contentType: "application/json"
			}).done(function (d) {
				me.setProperties({
					isProcessing: false,
					submitFailed: false
				});

				me.userHasVoted(d);
				$('.fixedheader__votelink').sideNav('hide');
			}).fail(function (e) {

				if (console && console.log) {
					console.log("setVote error", e);
				}

				me.setProperties({
					isProcessing: false,
					submitFailed: true
				});
			});
		},


		openVotingbasketModalThanksVoting: function openVotingbasketModalThanksVoting() {
			var i = this.get('modalActionVotingThanks');
			this.set('modalActionVotingThanks', i + 1);
			return false;
		},

		actions: {
			gotoIndex: function gotoIndex(event) {
				event.preventDefault();
				var appController = this.get('appController');
				appController.transitionToRoute('index', { queryParams: { cg: null } });
			},


			openVotingbasketModal: function openVotingbasketModal() {
				var i = this.get('modalAction');
				this.set('modalAction', i + 1);
				return false;
			},

			emailValid: function emailValid(t) {
				this.set('emailValid', t);
			},

			setEmail: function setEmail(t) {
				this.set('email', t);
			},

			showNav: function showNav() {
				$('.nav-slide-out-collapse').sideNav('show');
				return false;
			},

			voteNow: function voteNow() {
				this.saveVotingBasket();
				return false;
			},

			__voteNow: function __voteNow() {
				var appController = this.get('appController');

				if (appController.userIsLoggedIn) {
					$('.fixedheader__votelink').sideNav('hide');
					this.saveVotingBasket();
					return false;
				} else {
					this.checkForUserLogin();
				}
			}

		}
	});
});