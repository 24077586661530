define('symbolsofpeace/services/server-variables', ['exports', 'ember', 'ember-cli-server-variables/services/server-variables', 'symbolsofpeace/config/environment'], function (exports, _ember, _serverVariables, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serverVariables.default.extend({
    env: _ember.default.computed(function () {
      return _environment.default;
    })
  });
});