define('symbolsofpeace/routes/user-profile', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		docTitle: 'User Profile',

		model: function model(params, transition) {

			var me = this;
			var controller = this.controllerFor('application');

			return this.get('store').queryRecord('user', {}).then(function (user) {
				controller.setCurrentUser(user);
				return user;
			}, function () {
				controller.setCurrentUser(null);
				transition.abort();
				me.transitionTo('user-signin');
				return null;
			});
		},

		getCountries: function getCountries(controller) {
			$.getJSON('/country-selection').then(function (data) {
				var result = [];
				var idx = 0;
				for (var i in data) {
					result[idx] = { value: i, label: data[i] };
					idx++;
				}
				controller.set('countries', result);
			});
		},

		setupController: function setupController(controller, model) {
			controller.set('model', model);
			this.getCountries(controller);
		}

	});
});