define('symbolsofpeace/routes/user-signup', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		docTitle: 'User Sign Up',

		model: function model(params, transition) {

			var me = this;
			var controller = this.controllerFor('application');

			return this.get('store').queryRecord('user', {}).then(function (user) {
				controller.setCurrentUser(user);
				transition.abort();
				me.transitionTo('user-profile');
				return user;
			}, function () {
				controller.setCurrentUser(null);
				return null;
			});
		}
	});
});