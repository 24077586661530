define('symbolsofpeace/controllers/symbol-quick-look', ['exports', 'symbolsofpeace/controllers/symbol/index', 'ember'], function (exports, _index, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _index.default.extend({

		hideFilter: true,
		limit: 7,

		resetChild: 0,

		reloadChild: _ember.default.computed('resetChild', function () {
			return this.get('resetChild');
		}),

		init: function init() {
			this._super.apply(this, arguments);
			this.addObserver('model.tag', this, 'parentModelChanged');
		},

		/**
   * Reload result if parent model (symbol!) changed
   * @returns {boolean}
   */
		parentModelChanged: function parentModelChanged() {
			var i = this.get('resetChild') + 1;
			this.set('resetChild', i);
			return true;
		},

		actions: {
			onSwiperChange: function onSwiperChange(swiper) {
				var candidateId = $(swiper).find('.media-view-wrapper').data('candidate');
				if (candidateId) {
					var appController = this.get('appController');
					appController.send('trackCandidate', candidateId, 'view');
				}
			},

			setCategory: function setCategory(category) {
				// if(typeof console === 'object') { console.log('setCategory',category,this.get('model')); }
				var appController = this.get('appController');
				appController.saveSymbolCategory(this.get('model'), category);
				// this.set('model.category',category);
				// this.saveSymbol();
			}
		}

	});
});