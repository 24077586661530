define('symbolsofpeace/controllers/base-controller', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Controller.extend({
		appController: _ember.default.inject.controller('application'),
		errorController: _ember.default.inject.controller('error-modal'),
		isLoading: false
	});
});