define('symbolsofpeace/models/comment', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		creator: _emberData.default.attr('object'),
		counters: _emberData.default.attr('object'),
		creation_date: _emberData.default.attr(),
		rank: _emberData.default.attr(),
		level: _emberData.default.attr(),
		text: _emberData.default.attr(),
		rendered_text: _emberData.default.attr(),

		parent_type: _emberData.default.attr(),
		parent_id: _emberData.default.attr(),

		symbolComment: _emberData.default.belongsTo('symbol'),
		candidateComment: _emberData.default.belongsTo('candidate'),
		children: _emberData.default.hasMany('comment', { inverse: 'parent' }),
		parent: _emberData.default.belongsTo('comment', { inverse: 'children' })

	});
});