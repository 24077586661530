define('symbolsofpeace/controllers/symbol/choose-thumbnail', ['exports', 'symbolsofpeace/controllers/symbol/base-symbol-controller'], function (exports, _baseSymbolController) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseSymbolController.default.extend({

		isProcessing: false,
		submitFailed: false,

		actions: {

			saveThumbnail: function saveThumbnail(p) {

				var me = this;
				var valid = true;
				var parentController = this.get('parentController');
				var model = this.get('model');

				me.setProperties({
					submitFailed: false
				});

				if (valid) {

					me.setProperties({
						isProcessing: true,
						submitFailed: false
					});

					var data = { "poster_frame": p };

					jQuery.ajax({
						method: 'PUT',
						url: '/api/v1/candidates/' + model.get('id') + '/posterframe',
						data: JSON.stringify(data),
						dataType: "json",
						contentType: "application/json"
					}).done(function (c) {

						me.setProperties({
							isProcessing: false
						});

						if ((typeof c === 'undefined' ? 'undefined' : _typeof(c)) !== 'object') {
							me.setProperties({
								isProcessing: false,
								submitFailed: true
							});
							return null;
						}

						UTIL.tracker.track('event', {
							category: 'Symbol',
							action: 'candidate-thumbnal-saved',
							label: 'Candidate thumbnail saved',
							value: 1
						});

						me.set('model', c.candidate);
						parentController.gotoRoute('default');

						return c;
					}).fail(function (e) {
						if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
							console.log('error.candidate thumbnail change failed', e);
						}
						me.setProperties({
							isProcessing: false,
							submitFailed: true
						});
						return null;
					});
				} else {
					me.setProperties({
						isProcessing: false
					});
				}
			}

		}

	});
});