define('symbolsofpeace/components/mdc-tabs', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		classNames: ['mdc-tabs', 'row'],
		tabElement: null,
		tabs: [],
		active: 0,

		change: _ember.default.observer('changeTab', function () {
			var $element = this.$();
			var goto = this.get('gotoTab');
			$element.find('ul.tabs').tabs('select_tab', goto);
			return goto;
		}),

		// goto: Ember.observer('gotoTab', function() {
		// 	var $element = this.$();
		// 	var goto = this.get('gotoTab');
		// 	$element.find('ul.tabs').tabs('select_tab', goto);
		// 	return goto;
		// }),

		didInsertElement: function didInsertElement() {
			var me = this;
			var $element = this.$();
			var controller = this.get('controller');

			// get and save active tab content element
			// (to init something on display:!none)
			var activeTab = $element.find('a.active');
			if (activeTab && controller) {
				var href = activeTab.attr('href');
				activeTab = $element.find(href);
				if (controller) {
					controller.set('activateTab', activeTab);
				}
			}

			this.tabElement = $element.find('ul.tabs');

			this.tabElement.tabs({
				onShow: function onShow(tab) {
					if (controller) {
						controller.set('activateTab', tab);
					}

					var tabId = $(tab).attr('id');
					var showFN = me.get(tabId);
					if (showFN) {
						me.sendAction(tabId, tab);
					}
				}
			});
		}
	});
});