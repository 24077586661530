define('symbolsofpeace/controllers/user-profile', ['exports', 'symbolsofpeace/controllers/base-controller'], function (exports, _baseController) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseController.default.extend({

		saveDone: 0,

		updateField: function updateField(f, v) {
			this.set('model.' + f, v);
		},

		actions: {

			deleteUser: function deleteUser() {

				var me = this,
				    model = this.get('model');

				model.destroyRecord().then(function () {
					me.transitionToRoute('symbols');
				}, function (e) {
					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('user NOT DESTROYED', e);
					}
				});
			},

			save: function save() {

				var model = this.get('model'),
				    afterSave,
				    onError,
				    me = this;

				if (model) {
					if (typeof afterSave !== 'function') {
						afterSave = function afterSave(u) {
							var saveDone = me.get('saveDone');
							me.set('saveDone', saveDone + 1);
							return u;
						};
					}
					if (typeof onError !== 'function') {
						onError = function onError(p) {
							if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
								console.log('User save error', p);
							}
							return null;
						};
					}

					model.save().then(afterSave, onError);
				}
			}
		}

	});
});