define('symbolsofpeace/transforms/array', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Transform.extend({
		//deserialize: function(serialized) {
		//  return serialized;
		//},
		//
		//serialize: function(deserialized) {
		//  return deserialized;
		//}


		deserialize: function deserialize(value) {
			if (_ember.default.isArray(value)) {
				return _ember.default.A(value);
			} else {
				return _ember.default.A();
			}
		},
		serialize: function serialize(value) {
			if (_ember.default.isArray(value)) {
				return _ember.default.A(value);
			} else {
				return _ember.default.A();
			}
		}
	});
});