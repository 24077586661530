define('symbolsofpeace/components/candidate-listing', ['exports', 'symbolsofpeace/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		classNames: ['media', 'media-square'],
		tagName: 'article',

		actions: {}

	});
});