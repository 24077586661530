define('symbolsofpeace/routes/symbol/base-symbol-candidate-route', ['exports', 'ember', 'symbolsofpeace/routes/base-content-route'], function (exports, _ember, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		model: function model(params) {

			var me = this;

			return this.get('store').findRecord('candidate', params.candidate_id).then(function (candidate) {
				return candidate;
			}, function () {
				me.transitionTo('symbols');
				return null;
			});
		},

		afterModel: function afterModel(candidate) {
			var symbolController = this.controllerFor('symbol');
			symbolController.storeCandidate(candidate);
			symbolController.set('isEditor', null);

			var target = '/symbol/' + candidate.get('main_tag');
			this.showCandidateCropDialog(candidate, target);
		},


		isCandidate: _ember.default.on('activate', function () {
			this._super.apply(this, arguments);
			var symbolController = this.controllerFor('symbol');
			symbolController.set('isCandidate', true);
		}),

		isNotCandidate: _ember.default.on('deactivate', function () {
			this._super.apply(this, arguments);
			var symbolController = this.controllerFor('symbol');
			symbolController.set('isCandidate', false);
		})

	});
});