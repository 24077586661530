define('symbolsofpeace/controllers/candidates', ['exports', 'symbolsofpeace/controllers/base-controller', 'ember'], function (exports, _baseController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseController.default.extend({
		lastResult: null,
		lastParam: { q: 'top-pre' },
		limit: 50,
		offset: 0,
		page: 1,

		actions: {

			/**
    *
    * Load (filtered) symbols
    *
    * @param param
    * @param limit
    * @param page
    * @param keepExisting
    * @returns {*|Promise.<TResult>}
    */
			predefinedFilter: function predefinedFilter(param, limit, page, keepExisting) {

				var me = this;
				var lastResults = this.get('lastResults');

				var appController = this.get('appController');
				appController.send('hideSymbolQuickLook', false);

				if (lastResults && keepExisting !== true) {
					me.set('isLoading', true);
				}

				// if(typeof console === 'object') { console.log('predefinedFilter.param,this.lastParam',param,this.lastParam); }

				param = param || this.lastParam;
				limit = limit || this.limit;
				page = page || this.page;

				var sendParams = Object.assign({}, param, {
					limit: limit,
					page: page
				});

				// sendParams = this.setCategory(sendParams);

				// if(typeof console === 'object') { console.log('predefinedFilter.param',param,sendParams,keepExisting); }

				return this.get('store').query('candidate', sendParams).then(function (results) {
					me.set('isLoading', false);
					var meta = results.get('meta');

					if (lastResults && keepExisting === true) {
						var r = _ember.default.A();
						r.pushObjects(lastResults.toArray());
						r.pushObjects(results.toArray());
						results = r;
					} else {
						me.set('stopperVisible', false);
					}

					me.set('lastResults', results);
					me.set('lastParam', param);

					return {
						query: param,
						meta: meta,
						results: results
					};
				}, function () {
					me.set('isLoading', false);

					return {
						query: param,
						meta: lastResults ? lastResults.get('meta') : {
							last: true
						},
						results: lastResults
					};
				});
			}
		}

	});
});