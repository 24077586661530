define('symbolsofpeace/components/candidate-additional-tags-list', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		classNames: ['additional-tags-list', 'content--margin24'],
		tagName: 'div',

		// hasTags: Ember.computed('candidate.additional_tags',function() {
		// 	// candidate.additional_tags
		// })

		hasTags: _ember.default.computed.notEmpty('candidate.additional_tags')

	});
});