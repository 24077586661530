define('symbolsofpeace/controllers/error-modal', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _ember.default.Controller.extend({
		// appController: Ember.inject.controller('application'),
		// needs:['application'],

		errorObj: {
			statusText: 'Sorry',
			statusCode: 404,
			reason: null,
			message: 'An Error occured.'
		},

		/**
   * Raise error
   *
   *
   me.controllerFor('error-modal').raise({
  			statusText: 'Sorry',
  			statusCode: 404,
  			message:	'An error occured'
  		});
   *
   * @param error
   */
		raise: function raise(error) {

			error = error.responseJSON ? error.responseJSON : error;

			var errorObj = this.errorObj,
			    m = null;

			if (error) {
				error = error || {};
				$.extend(errorObj, error);
				m = _ember.default.Object.create(errorObj);
			}

			// call showModal in application route
			this.send('showModal', 'error-modal', m);

			if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
				console.log('An error occured', errorObj);
			}
		}

	});
});