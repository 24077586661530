define('symbolsofpeace/routes/symbol/choose-thumbnail', ['exports', 'ember', 'symbolsofpeace/routes/symbol/base-symbol-candidate-route'], function (exports, _ember, _baseSymbolCandidateRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseSymbolCandidateRoute.default.extend({

		docTitle: 'Candidate Choose Vimeo Video Thumbnail',

		afterModel: function afterModel() {
			this._super.apply(this, arguments);
			var symbolController = this.controllerFor('symbol');
			symbolController.set('isEditor', 'choose-thumbnail');
		},

		hideMessages: _ember.default.on('deactivate', function () {
			this._super.apply(this, arguments);
			var controller = this.controllerFor('symbol.choose-thumbnail');
			controller.setProperties({
				isProcessing: false,
				submitFailed: false
			});
		})

	});
});