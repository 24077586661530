define('symbolsofpeace/controllers/symbol/index', ['exports', 'symbolsofpeace/controllers/symbol/base-symbol-controller', 'ember'], function (exports, _baseSymbolController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseSymbolController.default.extend({
		symbolController: _ember.default.inject.controller('symbol'),

		symbol: null,
		lastResult: null,
		lastParam: { c: 'interesting' },
		limit: 20,
		offset: 0,
		page: 1,
		total: 0,
		resetChild: 0,
		stopperVisible: false,

		// init() {
		// 	this._super(...arguments);
		// 	if(typeof console === 'object') { console.log('SymbolIndex.stopperVisible',this.get('stopperVisible'))};
		// },

		actions: {

			/**
    *
    * Load (filtered) candidates
    *
    * @param param
    * @param limit
    * @param page
    * @param keepExisting
    * @returns {*|Promise.<TResult>}
    */
			predefinedFilter: function predefinedFilter(param, limit, page, keepExisting) {

				var me = this;
				var parentController = this.get('parentController');
				var showMyCandidates = parentController.get('showMyCandidates');
				var lastResults = this.get('lastResults');

				if (lastResults && keepExisting !== true) {
					me.set('isLoading', true);
				}

				param = param || this.lastParam;
				limit = limit || this.limit;
				page = page || this.page;

				var params = {
					symbol: this.get('model.tag'),
					// c: param,
					limit: limit,
					page: page
				};

				Object.assign(params, param);

				if (showMyCandidates) {
					params.user = 'me';
				}

				return this.get('store').query('candidate', params).then(function (results) {
					me.set('isLoading', false);
					var meta = results.get('meta');

					if (lastResults && keepExisting === true) {
						var r = _ember.default.A();
						r.pushObjects(lastResults.toArray());
						r.pushObjects(results.toArray());
						results = r;
					} else {
						me.set('stopperVisible', false);
					}

					me.set('total', meta.total);
					me.set('lastResults', results);
					me.set('lastParam', param);

					return {
						query: param,
						meta: meta,
						results: results
					};
				}, function () {
					me.set('isLoading', false);
					return {
						query: param,
						meta: {
							last: true
						},
						results: lastResults
					};
				});
			}
		}
	});
});