define('symbolsofpeace/controllers/nominate', ['exports', 'symbolsofpeace/controllers/base-controller', 'ember'], function (exports, _baseController, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseController.default.extend({

		activeStep: 1,
		completedStep: null,
		candidate: null,

		nominateTo: _ember.default.computed('model.tag', function () {
			var m = this.get('model');
			if (m && m.get('tag')) {
				return m.get('tag');
			}
			return 'new';
		}),

		setCompletedStep: function setCompletedStep(step) {
			this.set('completedStep', step);
			if (!step) {
				UTIL.storage.removeSession('nominate-step');
			} else {
				UTIL.storage.storeSession('nominate-step', step);
			}
		},

		updateCompletedStep: function updateCompletedStep(step) {
			var savedStep = this.getCompletedStep();

			if (step >= savedStep || !savedStep && step > 0) {
				this.set('completedStep', step);
				if (!step) {
					UTIL.storage.removeSession('nominate-step');
				} else {
					UTIL.storage.storeSession('nominate-step', step);
				}
			}
		},

		getCompletedStep: function getCompletedStep() {
			var step = this.get('completedStep');
			if (step === null || typeof step === 'undefined') {
				step = this.getAndSetSavedCompletedStep();
			}
			return step;
		},

		getAndSetSavedCompletedStep: function getAndSetSavedCompletedStep() {
			var step = UTIL.storage.getSession('nominate-step');
			if (step) {
				step = parseInt(step);
				this.set('completedStep', step);
			}
			return step;
		},

		storeCandidate: function storeCandidate(c) {
			this.set('candidate', c);
		},

		getCandidate: function getCandidate() {
			return this.get('candidate');
		},

		gotoRoute: function gotoRoute(r) {
			var candidate = this.getCandidate();
			var nominateTo = this.get('nominateTo');

			switch (r) {
				case 'active':
					var completedStep = this.getCompletedStep();
					if (completedStep) {
						this.gotoRoute(completedStep + 1);
					} else {
						this.gotoRoute(2);
					}
					break;
				case 4:
				case 'signup':
					this.set('activeStep', 4);
					this.transitionToRoute('nominate.signup', nominateTo, candidate);
					break;
				case 3:
				case 'notes':
					this.set('activeStep', 3);
					this.transitionToRoute('nominate.notes', nominateTo, candidate);
					break;
				case 2:
				case 'tag':
					this.set('activeStep', 2);
					this.transitionToRoute('nominate.tag', nominateTo, candidate);
					break;
				case 'crop':
					this.transitionToRoute('nominate.crop', nominateTo, candidate);
					break;
			}
		}

	});
});