define('symbolsofpeace/services/lazy-video-providers', ['exports', 'ember', 'symbolsofpeace/services/lazy-video-providers/youtube', 'symbolsofpeace/services/lazy-video-providers/vimeo', 'symbolsofpeace/services/lazy-video-providers/instagram'], function (exports, _ember, _youtube, _vimeo, _instagram) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var YOUTUBE_REGEX = /(https?:\/\/)?(www.)?(youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/watch\?feature=player_embedded&v=)([A-Za-z0-9_-]*)(\&\S+)?(\?\S+)?/;
	var VIMEO_REGEX = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
	var INSTAGRAM_REGEX = /(https?:\/\/)?(www.)?instagr(am\.com|\.am)\/p\/([A-Za-z0-9_-]*)/;

	var Service = _ember.default.Service,
	    $ = _ember.default.$,
	    assert = _ember.default.assert;
	exports.default = Service.extend({
		getUrl: function getUrl(url, endpoint, opts) {
			// eslint-disable-next-line no-unused-vars
			var params = void 0;
			opts = typeof opts === 'undefined' ? {} : opts;
			params = $.param(opts);

			var provider = this._getProvider(url)[endpoint];
			var videoId = this._getVideoId(url);

			return '' + provider(videoId);
		},
		getThumbnailUrl: function getThumbnailUrl(url) {
			var videoId = this._getVideoId(url);
			return this._getProvider(url).thumbnailUrl(videoId);
		},


		youtube: _youtube.default,
		vimeo: _vimeo.default,
		instagram: _instagram.default,

		_getVideoId: function _getVideoId(url) {
			var videoId = void 0,
			    video = void 0;
			if (url) {
				if (VIMEO_REGEX.test(url)) {
					video = VIMEO_REGEX.exec(url);
					videoId = video[3];
				}

				if (YOUTUBE_REGEX.test(url)) {
					video = YOUTUBE_REGEX.exec(url);
					videoId = video[4];
				}

				if (INSTAGRAM_REGEX.test(url)) {
					video = INSTAGRAM_REGEX.exec(url);
					videoId = video[4];
				}
			}

			assert('Couldn\'t determine videoId from url: ' + url, videoId);

			return videoId;
		},
		_getProvider: function _getProvider(url) {
			var providerName = void 0,
			    provider = void 0;

			if (url) {
				if (VIMEO_REGEX.test(url)) {
					providerName = 'vimeo';
				}

				if (YOUTUBE_REGEX.test(url)) {
					providerName = 'youtube';
				}

				if (INSTAGRAM_REGEX.test(url)) {
					providerName = 'instagram';
				}
			}

			provider = this.get(providerName);
			assert('Couldn\'t determine provider from url: ' + url, provider);

			return provider;
		}
	});
});