define('symbolsofpeace/components/candidate-listing-stopper', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		tagName: '',

		/**
   * show stopper on second or last position
   */
		showStopper: _ember.default.computed('idx', function () {
			var me = this;
			var controller = this.get('controller');
			if (controller.get('stopperVisible')) return false;else {
				var idx = this.get('idx');
				var meta = this.get('resultMeta');
				var isLast = meta.last;
				if (idx === 3 || isLast && idx === meta.total - 1) {
					controller.set('stopperVisible', true);
					setTimeout(function () {
						me.resizeStopper();
					}, 150);
					return true;
				}
			}
			return false;
		}),

		resizeStopper: function resizeStopper() {
			var h = $('body').find('.media-listing article').height();
			if (h) {
				$('.media-listing-stopper').height(h);
			}
		},

		didInsertElement: function didInsertElement() {
			this.resizeStopper();
			$(window).on('resize', $.proxy(this.resizeStopper, this));
		},

		willDestroyElement: function willDestroyElement() {
			$(window).off('resize', $.proxy(this.resizeStopper, this));
			var controller = this.get('controller');
			if (controller) {
				controller.set('stopperVisible', false);
			}
		}

	});
});