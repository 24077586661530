define('symbolsofpeace/routes/symbol', ['exports', 'symbolsofpeace/routes/base-content-route'], function (exports, _baseContentRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseContentRoute.default.extend({

		docTitle: 'Symbol',

		/**
   *
   * /api/v1/candidates?user=me&c=shared
   * @param params
   * @param transition
   * @returns {*|Promise.<TResult>}
   */
		model: function model(params, transition) {

			var me = this;
			var tag = params.tag;

			return this.get('store').findRecord('symbol', tag).then(function (symbol) {
				return symbol;
			}, function () {
				me.transitionTo('symbols');
				return null;
			});
		}

	});
});