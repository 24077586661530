define('symbolsofpeace/initializers/js-config', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.initialize = initialize;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/**
  *
  * App Wide Globals Injection
  *
  * May be used in templates:
  * {{globals.srvParams.AssetsPath}}
  *
  * In Controllers, Routes or Views
  * this.get('globals').get('name');
  *
  */

	var globals = _ember.default.Object.extend({
		name: '7Peace App',
		srvParams: srvParams,
		app_route: '/app',

		test: function test() {
			if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
				console.log('TEST!', true);
			}
		}
	});

	function initialize(application) {
		// application.inject('route', 'foo', 'service:foo');
		// container.typeInjection('component', 'store', 'store:main');
		application.register('global:variables', globals, { singleton: true });
		// application.inject('controller', 'globals', 'global:variables');
		// application.inject('route', 'globals', 'global:variables');
		// application.inject('view', 'globals', 'global:variables');
		application.inject('helper', 'globals', 'global:variables');
		application.inject('component', 'globals', 'global:variables');
		// application.inject('model', 'globals', 'global:variables');
	}

	exports.default = {
		name: 'js-config',
		initialize: initialize
	};
});