define('symbolsofpeace/adapters/category', ['exports', 'symbolsofpeace/mixins/delete-with-payload', 'ember-data'], function (exports, _deleteWithPayload, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTAdapter.extend(_deleteWithPayload.default, {

		namespace: 'api/v1'

		// urlForQuery (query, modelName) {
		// 	let parent_type = query.parent_type;
		// 	let parent_id = query.parent_id;
		// 	delete(query.parent_type);
		// 	delete(query.parent_id);
		//
		// 	let u = `/api/v1/${parent_type}s/${parent_id}/${modelName}s`;
		//
		// 	if(typeof console === 'object') { console.log('URL',u,query, modelName); }
		//
		// 	return u;
		// }

	});
});