define('symbolsofpeace/utils/i18n/missing-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (locale, key, context) {
    var values = Object.keys(context).map(function (key) {
      return context[key];
    });
    if (_typeof(window.console) === 'object') {
      window.console.log('Missing Translation in ' + locale, ': ', key, context, values);
    }
    return key + ' ' + values.join(', ');
  };

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };
});