define('symbolsofpeace/components/loading-animation', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Component.extend({

		classNames: ['loading-animation'],

		classNameBindings: ['maskBackground:mask', 'maskBackgroundDark:mask-dark', 'maskFixed:mask-fixed', 'topSpinner:spinner-on-top'],

		// maskBackgroundDark: function() {
		// 	return this.get('maskDark');
		// }.property('maskDark'),

		maskBackgroundDark: _ember.default.computed('maskDark', function () {
			return this.get('maskDark');
		}),

		// maskBackground: function() {
		// 	return this.get('mask');
		// }.property('mask'),

		maskBackground: _ember.default.computed('mask', function () {
			return this.get('mask');
		}),

		// topSpinner: function() {
		// 	return this.get('spinnerOnTop');
		// }.property('spinnerOnTop'),

		topSpinner: _ember.default.computed('spinnerOnTop', function () {
			return this.get('spinnerOnTop');
		}),

		/**
   * donno, if we really need this... already done by css
   */
		didInsertElement: function didInsertElement() {

			var $element = this.$(),
			    parentHeightSelector = this.get('parentHeightSelector'),
			    height;

			if (parentHeightSelector) {
				height = $element.closest(parentHeightSelector).height();
				if (height) {
					$element.css('height', height);
				}
			}
		}

	});
});