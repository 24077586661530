define('symbolsofpeace/components/mdc-tab', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({

		tagName: 'li',
		classNames: ['materialize-tabs-tab', 'tab', 'col'],
		classNameBindings: ['_colClass'],

		active: _ember.default.computed('setActive', function () {
			return this.get('idx') === this.get('setActive');
		})

	});
});