define('symbolsofpeace/components/comments-actions', ['exports', 'ember', 'symbolsofpeace/components/base-component'], function (exports, _ember, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _baseComponent.default.extend({

		tagName: 'span',
		hasComments: true,
		modalDeleteAction: 0,
		isDeleteProzessing: false,
		deleteError: false,

		editError: false,
		modalEditAction: 0,
		isEditProzessing: false,

		textareaId: _ember.default.computed('model.id', function () {
			var modelId = this.get('model.id');
			return 'comment_editor_' + modelId;
		}),

		init: function init() {
			this._super.apply(this, arguments);
			this.setProperties({
				isDeleteProzessing: false,
				deleteError: false,
				isEditProzessing: false,
				editError: false
			});
		},

		isAdmin: _ember.default.computed('model.id', 'appController.user.id', function () {
			return this.canEditComment(this.get('model'));
		}),

		canEditComment: function canEditComment(model) {
			var appController = this.get('appController');
			var user = appController.getCurrentUser();
			if (appController.testIsAdmin(user)) {
				return true;
			} else if (this.isMyComment(model)) {
				return true;
			}

			return false;
		},

		/**
   * Check if "this" is my comment
   * @param model
   * @returns {boolean}
   */
		isMyComment: function isMyComment(model) {
			var appController = this.get('appController');

			var isMy = false,
			    userId,
			    ownerId;

			var user = appController.getCurrentUser();

			if (user && model) {
				userId = user.get('id');
				ownerId = model.get('creator.id');
				if (userId === ownerId) {
					isMy = true;
					this.set('hasComments', true);
				}
			}

			return isMy;
		},

		checkField: function checkField() {
			var inputId = this.get('textareaId');
			var input = $('#' + inputId);

			if (input && input.length) {
				var val = input.val().trim();
				if (val.length < this.min_length) {
					this.set('lengthFailed', true);
					return false;
				}
			}
			return true;
		},

		save: function save() {
			var _this = this;

			var me = this;
			me.setProperties({
				error: '',
				editError: false,
				isEditProzessing: false,
				modalEditAction: false
			});

			var valid = this.checkField();

			if (valid) {

				me.setProperties({
					isEditProzessing: true
				});

				this.get('model').set('text', this.get('model').get('rendered_text'));

				this.get('model').save({ adapterOptions: { modelSave: true } }).then(function (c) {

					if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
						console.log('save', c);
					}

					UTIL.tracker.track('event', {
						category: 'Comment',
						action: 'comment-saved',
						label: 'Comment saved',
						value: 1
					});

					_this.setProperties({
						error: '',
						editError: false,
						isEditProzessing: false,
						modalEditAction: false
					});

					return c;
				}, function (e) {
					_this.setProperties({
						error: e,
						editError: true,
						isEditProzessing: false,
						modalEditAction: false
					});
					return null;
				});
			}
		},

		actions: {
			confirmEdit: function confirmEdit() {
				var i = this.get('modalEditAction');
				this.set('modalEditAction', i + 1);
				$('textarea').trigger('autoresize');
			},
			editComment: function editComment() {
				this.save();
			},
			confirmDelete: function confirmDelete() {
				var i = this.get('modalDeleteAction');
				this.set('modalDeleteAction', i + 1);
			},
			deleteComment: function deleteComment() {
				var model = this.get('model');
				var modelId = this.get('model.id');
				var me = this;
				this.set('isDeleteProzessing', true);
				setTimeout(function () {
					me.set('isDeleteProzessing', false);
					var i = me.get('modalDeleteAction');
					me.set('modalDeleteAction', i + 1);
					setTimeout(function () {
						model.destroyRecord().then(function () {
							// delete comment from dom,
							// because it maybe inserted in results skipping the query
							setTimeout(function () {
								var d = $('*[data-comment="' + modelId + '"]');
								if (d && d.length) {
									d.remove();
								}
							}, 500);
						}, function (e) {
							if ((typeof console === 'undefined' ? 'undefined' : _typeof(console)) === 'object') {
								console.log('comment NOT DESTROYED', e);
							}
						});
					}, 250);
				}, 500);
			}
		}
	});
});